import Tabs from 'components/Table/Tabs/Tabs';
import { useEffect, useRef, useState } from 'react';
import Header from './Header';
import EmptyState from './EmptyState';
import Footer from './Footer';
import Card from './Card';
import { NOTIFICATIONS_POLL_INTERVAL, NotificationType } from './utils';
import Switch from 'components/Switch';
import { Notification } from 'api/notifications/schema';
import { useNotifications } from 'hooks/useNotifications';
import { CardSkeleton } from './CardSkeleton';
import { useFeatureFlags } from 'feature-flags';
import { useAuthUser } from 'hooks/useAuthUser';

interface NotificationsProps {
  isNotificationsOpen: boolean;
  setNotificationsOpen: (value: boolean) => void;
}

const Notifications = ({
  isNotificationsOpen,
  setNotificationsOpen,
}: NotificationsProps) => {
  const { flags } = useFeatureFlags();
  const notificationsRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef(null);

  const [activeNotificationType, setActiveNotificationType] =
    useState<NotificationType>('all');
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);

  const { isQRAdminRole } = useAuthUser();

  const {
    notifications,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    categories,
    markAllAsRead,
    markNotificationAsRead,
    notificationsRead,
  } = useNotifications(
    {
      onlyUnread,
      notificationType: activeNotificationType,
      pillar: isQRAdminRole ? 'access-express' : undefined,
    },
    {
      staleTime: NOTIFICATIONS_POLL_INTERVAL,
      enabled: flags.NOTIFICATIONS_ENABLED,
    },
  );

  useEffect(() => {
    const closeOnClickOutside = (ev: MouseEvent) => {
      if (
        isNotificationsOpen &&
        notificationsRef.current &&
        ev.target instanceof Node &&
        !notificationsRef?.current?.contains(ev.target)
      ) {
        setNotificationsOpen(false);
      }
    };
    if (isNotificationsOpen) {
      document.addEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [isNotificationsOpen, setNotificationsOpen]);

  const tabs = [
    {
      title: 'All',
      active: true,
      onClick: () => setActiveNotificationType('all'),
    },
    {
      title: 'Comments',
      onClick: () => setActiveNotificationType('comment'),
      active: false,
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage(); // Fetch the next page when the end is reached
      }
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  return (
    <>
      <div
        className={`fixed inset-0 w-full ${
          isNotificationsOpen
            ? 'h-full bg-background-base-overlay opacity-100'
            : 'h-0 opacity-0'
        } z-40`}
      />
      <div
        className={`fixed inset-0 z-50 transition-transform duration-200 ease-in-out ${
          isNotificationsOpen ? 'translate-x-0' : 'translate-x-full'
        } `}
      >
        <div
          ref={notificationsRef}
          className={`relative ml-auto flex h-screen max-h-screen w-[504px] max-w-[504px] flex-col items-start gap-4 overflow-auto bg-background-base-surface-2 px-6 py-8 scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued`}
        >
          <Header
            close={() => setNotificationsOpen(false)}
            handleOnlyUnread={setOnlyUnread}
          />
          <Tabs tabs={tabs} />
          {notifications && notifications.length > 0 ? (
            <div className="flex w-full flex-col gap-6">
              {categories.map((category, index) => (
                <CategorizedNotificationsWrapper
                  key={category.name}
                  markAsRead={markNotificationAsRead}
                  category={category.name}
                  items={category.items}
                  showMarkAllAsReadButton={index === 0}
                  areAllNotificationsRead={notificationsRead}
                  onMarkAllAsRead={markAllAsRead}
                />
              ))}
            </div>
          ) : (
            <EmptyState />
          )}
          {hasNextPage && <CardSkeleton ref={loadMoreRef} />}
          {!isQRAdminRole && <Footer />}
        </div>
      </div>
    </>
  );
};

const CategorizedNotificationsWrapper = ({
  category,
  items,
  areAllNotificationsRead,
  showMarkAllAsReadButton,
  onMarkAllAsRead,
  markAsRead,
  handleOnlyUnread,
  showOnlyOnRead = false,
}: {
  category: string;
  showOnlyOnRead?: boolean;
  items: Notification[];
  areAllNotificationsRead: boolean;
  showMarkAllAsReadButton: boolean;
  onMarkAllAsRead?: (item: boolean) => void;
  markAsRead: (item: Notification, read: boolean) => void;
  handleOnlyUnread?: (value: boolean) => void;
}) => {
  const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(true);
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <p className="text-sm font-bold text-content-base-subdued">
          {category}
        </p>
        <div className="flex items-center justify-start gap-[34px]">
          {showMarkAllAsReadButton && onMarkAllAsRead && (
            <p
              onClick={() => onMarkAllAsRead(areAllNotificationsRead)}
              className="cursor-pointer text-sm font-semibold text-content-base-subdued hover:text-content-accent-default"
            >
              {areAllNotificationsRead
                ? 'Mark all as unread'
                : 'Mark all as read'}
            </p>
          )}
          {showOnlyOnRead && showMarkAllAsReadButton && (
            <div className="flex justify-between gap-2">
              <Switch
                isOn={showOnlyUnread}
                label="Only unread"
                onChange={() => {
                  handleOnlyUnread?.(showOnlyUnread);
                  setShowOnlyUnread((prevState) => !prevState);
                }}
                size="small"
                labelClassName="!text-sm !mr-2 !text-content-base-default !font-medium"
              />
            </div>
          )}
        </div>
      </div>
      {showOnlyOnRead && showMarkAllAsReadButton && (
        <hr className="my-6 h-[1px] border-stroke-base-default" />
      )}
      <div className="flex flex-col gap-4">
        {items.map((notification) => (
          <Card
            key={notification.id}
            markAsRead={markAsRead}
            notification={notification}
          />
        ))}
      </div>
    </div>
  );
};

export default Notifications;
