import { TokenUserRoles } from 'api/users/schemas/Users';
import { PillarOption, pillarByCategory } from './constants';

const hasAccessToPillar = (
  userRoles: TokenUserRoles[],
  roles: TokenUserRoles[],
) =>
  userRoles.includes(TokenUserRoles.SUPER_ADMIN) ||
  userRoles.includes(TokenUserRoles.COMPANY_ADMIN) ||
  roles.some((role) => userRoles.includes(role));

export const getAllowedPillarByCategory = (userRoles: TokenUserRoles[]) =>
  Object.entries(pillarByCategory).reduce<
    Record<PillarOption['category'], PillarOption[]>
  >(
    (acc, [category, pillars]) => {
      const allowedPillars = pillars.filter((pillar) =>
        pillar.roles ? hasAccessToPillar(userRoles, pillar.roles) : pillar,
      );
      if (allowedPillars.length > 0)
        acc[category as PillarOption['category']] = allowedPillars;
      return acc;
    },
    {} as Record<PillarOption['category'], PillarOption[]>,
  );
