import { Control, useController } from 'react-hook-form';
import { GraniteSelect } from '../Select/Select';
import { splitRoleKey } from '../HeaderNav/utils';
import { TokenUserRoles } from 'api/users/schemas/Users';

export type FormWithPermissions = {
  roles: { value: string; label: string }[];
};

export interface UserPermissionFeatureSwitchesProps<
  FormType extends FormWithPermissions,
> {
  control: FormType extends FormWithPermissions ? Control<FormType> : never;
  isProfile?: boolean;
  isSuperAdmin?: boolean;
}

interface RoleError {
  roles?: {
    message?: string;
    type?: string;
  };
}

export const UserRolePermissionFeatureSwitches = <
  FormType extends FormWithPermissions,
>({
  control,
  isProfile,
  isSuperAdmin = false,
}: UserPermissionFeatureSwitchesProps<FormType>) => {
  const {
    field: { ref, ...role },
    fieldState: { error: roleErrors },
  } = useController({ control, name: 'roles' });

  const roles = userRolesEnumToSelectOptions(isSuperAdmin);

  return (
    <div className="flex w-full flex-col">
      <h2 className="mb-4 text-2xl font-semibold text-content-base-default">
        Role & permissions
      </h2>
      <GraniteSelect
        {...role}
        onChange={(newValue) => {
          role.onChange(isSuperAdmin ? newValue : [newValue]);
        }}
        label="Role"
        options={roles}
        error={(roleErrors as RoleError)?.roles?.message ?? roleErrors?.message}
        isDisabled={isProfile}
        isMulti={isSuperAdmin}
      />
      <p className="mb-8 mt-2 text-sm text-content-base-subdued">
        {role.value?.[0]?.value === 'Viewer' &&
          'This user will be limited to read only permissions'}
        {role.value?.[0]?.value === 'User' &&
          'This user can have read & write or read only permissions'}
        {role.value?.[0]?.value === 'Company Admin' &&
          'This user will have full read & write permissions and the ability to manage users'}
      </p>
    </div>
  );
};

const userRolesEnumToSelectOptions = (isSuperAdmin: boolean) => {
  const roles = isSuperAdmin
    ? [
        'VIEWER',
        'USER',
        'COMPANY_ADMIN',
        'WELCOME_USER',
        'GRANITE_BETA_TESTER',
        'EARLY_ADOPTER',
        'QR_ADMIN',
        'PROSPECT',
      ]
    : ['VIEWER', 'USER', 'COMPANY_ADMIN', 'WELCOME_USER'];
  return roles.map((role) => ({
    value: TokenUserRoles[role as keyof typeof TokenUserRoles],
    label: splitRoleKey(role),
  }));
};
