import React, { Fragment } from 'react';
import { LetUsHelpLayout } from '../BaseComponents/LetUsHelpLayout';
import { LetUsHelpBaseForm } from '../BaseComponents/LetUsHelpBaseForm';
import { GraniteButton } from '../../../components/V2/Button/GraniteButton';
import { useNavigate } from 'react-router-dom';
import FormSection from 'components/FormSection';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import RadioButton from 'components/RadioButton';
import Divider from 'components/Divider';
import { StandardTools } from 'components/StandardTools/StandardTools';
import { ExtensionType } from './RunExtension/schemas';

export const TicketDetailsBaseSkeleton = () => {
  const navigate = useNavigate();

  return (
    <LetUsHelpLayout>
      <LetUsHelpBaseForm className="gap-12" id="ticket-detail-form">
        <Fragment>
          <FormSection title="Ticket details" gridClassName="grid-cols-2">
            <GraniteInput
              label="Where is the MPOE (minimum point of entry) for the telephone number? (optional)"
              className="col-span-full"
            />
            <GraniteInput
              name="telephoneNumber"
              className="col-span-full"
              type="number"
              label="What is the telephone number?"
            />

            <Fragment>
              <GraniteLabel
                label="Where will this line be extended to?"
                element="div"
              >
                <RadioButton
                  options={[
                    {
                      value: ExtensionType.enum.NetworkRack,
                      label: 'Network rack',
                    },
                    {
                      value: ExtensionType.enum.PatchPanelPort,
                      label: 'Patch panel port',
                    },
                    {
                      value: ExtensionType.enum.JackIn,
                      label: 'Jack in a specific place',
                    },
                  ]}
                  className="col-span-2"
                />
              </GraniteLabel>
            </Fragment>

            <GraniteInput
              className="col-span-1 col-start-1"
              label="What is the approximate length of cable needed?"
              suffix="feet"
            />
          </FormSection>
          <Divider />
          <FormSection gridClassName="grid-cols-2">
            <Fragment>
              <GraniteLabel
                label="Does the technician need to work with someone to test?"
                element="div"
              >
                <RadioButton
                  options={[
                    {
                      label: 'Yes, a member of our IT team',
                      value: 'CustomerIT',
                    },
                    {
                      label: 'Yes, an on site member of our team',
                      value: 'Person',
                    },
                    { label: 'No', value: 'No' },
                  ]}
                />
              </GraniteLabel>
            </Fragment>
          </FormSection>
          <Divider />
          <FormSection>
            <StandardTools />
          </FormSection>
        </Fragment>
      </LetUsHelpBaseForm>
      <div className="sticky top-8 flex gap-4 rounded bg-background-base-surface-2 p-6 shadow">
        <GraniteButton
          className="w-full"
          variant="secondary"
          size="large"
          onClick={() => navigate(-1)}
        >
          Back
        </GraniteButton>
        <GraniteButton
          className="w-full"
          size="large"
          type="submit"
          form="ticket-detail-form"
        >
          Next
        </GraniteButton>
      </div>
    </LetUsHelpLayout>
  );
};
