import { ContentLayout } from '../../../layouts/ContentLayout/ContentLayout';
import PageTitle from '../../../components/PageTitle';
import { OpenQuoteWizardProgress } from './OpenQuoteWizardProgress';
import { useState } from 'react';
import { OpenQuoteWizardContext } from './OpenQuoteWizardReducer';
import { Outlet, redirect, RouteObject, useNavigate } from 'react-router-dom';
import { AddLocations } from '../AddLocations/AddLocations';
import { ReviewLocations } from '../ReviewLocations/ReviewLocations';
import { AddProducts } from '../AddProducts/AddProducts';
import { AssociateProductsToLocations } from '../AssociateProductsToLocations/AssociateProductsToLocations';
import { Review } from '../Review/Review';
import { OpenQuoteStore } from './OpenQuoteStore';
import { OpenQuoteWizardStore } from './OpenQuoteWizardStore';
import { Observer } from 'mobx-react-lite';
import { MassApply } from '../MassApply/MassApply';
import { ReviewProducts } from '../ReviewProducts/ReviewProducts';
import { OpenQuoteReview } from '../Review/OpenQuoteReview';
import { SelectProductsContextLoader } from './SelectProductsWizard';
import { LocationDetailsContextLoader } from '../LocationDetails/LocationDetails';
import { ResubmitOpenQuote } from '../ResubmitOpenQuote/ResubmitOpenQuote';
import { ChooseServices } from '../ChooseServices/ChooseServices';
import { QuoteIndexPage } from 'screens/QuoteIndex/QuoteIndexPage';
import { SelectServiceBundle } from '../SelectServiceBundle/SelectServiceBundle';
import SelectServices from 'screens/SelectServices/SelectServices';
import { QuoteRequested } from '../QuoteRequested/QuoteRequested';
import { QuoteDetails } from '../QuoteDetails/QuoteDetails';
// import OrderServices from 'screens/OrderServices/OrderServicesFormContainer';

export const OpenQuoteWizard = () => {
  const [openQuoteStore] = useState(() => new OpenQuoteStore());
  const [wizardStore] = useState(() => new OpenQuoteWizardStore());
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'AccessExpress',
      onClick: () => navigate('/access-express'),
    },
  ];
  return (
    <ContentLayout>
      <div className="flex flex-col gap-6">
        <Observer>
          {() => (
            <>
              <PageTitle
                title={wizardStore.title ?? 'Open quote request'}
                breadcrumbs={breadcrumbs}
              />
              <OpenQuoteWizardProgress step={wizardStore.step} />
            </>
          )}
        </Observer>
      </div>
      <Outlet
        context={
          {
            openQuoteStore,
            wizardStore,
          } satisfies OpenQuoteWizardContext
        }
      />
    </ContentLayout>
  );
};

export const OpenQuoteRoutes: RouteObject[] = [
  {
    path: 'open-quote',
    element: <OpenQuoteWizard />,
    children: [
      { index: true, loader: () => redirect('add-locations') },
      { path: 'add-locations', element: <AddLocations /> },
      { path: 'choose-services', element: <ChooseServices /> },
      { path: 'review-locations', element: <ReviewLocations /> },
      {
        path: 'select-service-bundle/:quoteId',
        element: <SelectServiceBundle />,
      },
      { path: 'select-services/:quoteId', element: <SelectServices /> },
      { path: 'add-products', element: <AddProducts /> },
      {
        path: 'products-to-locations',
        element: <AssociateProductsToLocations />,
      },
      { path: 'review', element: <Review /> },
      { path: 'resubmit-open-quote/:quoteId', element: <ResubmitOpenQuote /> },
    ],
  },
  {
    path: 'index',
    element: <QuoteIndexPage />,
  },
  {
    path: 'open-quote/:quoteId',
    loader: ({ params }) => {
      const quoteId = params['quoteId'];
      if (typeof quoteId !== 'string' || quoteId.length === 0) {
        return redirect('/access-express');
      }
      return null;
    },
    element: <SelectProductsContextLoader />,
    children: [
      { index: true, loader: () => redirect('mass-apply') },
      {
        path: 'mass-apply',
        element: <MassApply />,
      },
      { path: 'review-products', element: <ReviewProducts /> },
      {
        path: 'location-detail/:locationId',
        loader: ({ params }) => {
          const locationId = params['locationId'];
          if (typeof locationId !== 'string' || locationId.length === 0) {
            return redirect('../review-products');
          }
          return null;
        },
        element: <LocationDetailsContextLoader />,
      },
      { path: 'review', element: <OpenQuoteReview /> },
    ],
  },
  { path: 'quote-details/:id', element: <QuoteRequested /> },
  { path: 'order-submitted/:id', element: <QuoteDetails /> },
];
