import { TicketDetailForm } from '../DefinedFormTypes';
import React, { Fragment } from 'react';
import Divider from '../../../../components/Divider';
import FormSection from '../../../../components/FormSection';
import { StandardTools } from '../../../../components/StandardTools/StandardTools';
import { Controller } from 'react-hook-form';
import { GraniteInput } from '../../../../components/V2/Input/GraniteInput';
import { AuditForm } from './schemas';
import { FileUpload } from '../../../../components/V2/FileUpload/FileUpload';
import PhoneNumberInput from '../../../../components/V2/Input/PhoneNumber';
import { NonStandardToolsFieldTextField } from '../../BaseComponents/NonStandardToolsField';

export type AuditProps = TicketDetailForm<AuditForm>;

export const Audit = ({ register, control, errors }: AuditProps) => {
  return (
    <Fragment>
      <FormSection
        title="Ticket details"
        subtitle="An on site escort with knowledge of IT rooms and Demarc location is required. Please provide their contact details below."
        gridClassName="grid-cols-2"
        subtitleClassName="!text-content-base-default"
      >
        <GraniteInput
          label="Contact name"
          className="col-span-1 col-start-1"
          error={errors.contactName?.message}
          {...register('contactName')}
        />
        <PhoneNumberInput
          label="Contact phone"
          className="col-span-1"
          error={errors.contactNumber?.message}
          control={control}
          name="contactNumber"
        />
        <GraniteInput
          label="Contact email (optional)"
          className="col-span-1"
          error={errors.contactEmail?.message}
          {...register('contactEmail')}
        />
      </FormSection>
      <Divider />
      <FormSection gridClassName="grid-cols-2">
        <Controller
          name="attachments"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FileUpload
              label="Upload audit sheet (optional)"
              className="col-span-2"
              accept=".pdf,.docx,.doc,.xlsx,.csv,image/*"
              error={errors.attachments?.message}
              subtitle={
                <Fragment>
                  This document will be the deliverable of the site audit. The
                  technician will use this document to record any requested
                  information about your network or equipment.{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/Audit Form Template.xlsx`}
                    target="_blank"
                    rel="noreferrer"
                    download
                    className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
                  >
                    View an example audit sheet
                  </a>
                </Fragment>
              }
              multiple
              value={value}
              onChange={onChange}
            />
          )}
        />
      </FormSection>
      <Divider />
      <FormSection>
        <StandardTools />
        <NonStandardToolsFieldTextField control={control} />
      </FormSection>
    </Fragment>
  );
};
