import { Modal } from 'components/Modal/Modal';
import ModalFormHeader from 'components/Modal/ModalFormHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useQuery } from 'react-query';
import Loader from 'components/Loader';
import { copyText, getKeyById } from './utils';
import { Copy, Eye, EyeOff } from 'react-ionicons';
import { useState } from 'react';

const DetailsKey = () => {
  const { key } = useParams<{ key: string }>();
  const [showSecret, setShowSecret] = useState(false);
  const navigate = useNavigate();

  const onClose = () => navigate('..', { replace: true });

  const { data, isLoading } = useQuery(
    ['user-settings-api-key', key],
    () => getKeyById(key as string),
    {
      refetchOnMount: true,
    },
  );

  const onCopyClick = () => {
    onClose();
    copyText(data!.client_key, data!.name);
  };

  const maskString = (str: string) => {
    if (str.length <= 4) {
      return str; // If the string is 4 characters or fewer, no masking needed
    }
    const maskedPart = '*'.repeat(str.length - 4); // Replace all but last 4 chars with *
    const visiblePart = str.slice(-4); // Get the last 4 characters
    return maskedPart + visiblePart;
  };

  const onClickEye = () => setShowSecret((prev) => !prev);

  return (
    <Modal isVisible={true} close={onClose} className="w-full max-w-[624px]">
      <ModalFormHeader
        title="Key created!"
        subtitle="Your new API key has been generated and is ready to use. Here's what you need to know:"
      />
      <div className="min-h-[552px] p-8">
        {isLoading ? (
          <Loader className="h-full w-full" />
        ) : (
          <>
            <GraniteInput label="Key name" readOnly value={data?.name} />
            <div className="my-6 flex items-end">
              <GraniteInput
                className="flex-grow basis-[90%]"
                innerInputClassName="!rounded-r-none"
                label="Client key"
                subtitle="This is a unique identifier used to authenticate and authorize access to the Granite360 API."
                readOnly
                value={data?.client_key}
              />
              <span
                onClick={() => copyText(data!.client_key, data!.name)}
                className="h-12 cursor-pointer rounded-r border-y border-r border-neutral-700 bg-blue-900 px-4 py-3.5 text-neutral-500 hover:text-teal-400"
              >
                <Copy width="20px" height="20px" color="currentColor" />
              </span>
            </div>
            <div className="flex items-end">
              <GraniteInput
                className="flex-grow basis-[90%]"
                innerInputClassName="!rounded-r-none"
                label="Secret key"
                subtitle="For security reasons, your API key will only be displayed once. Copy and save this API key in a secure place. If you lose it, you will need to generate a new one."
                readOnly
                value={
                  showSecret ? data!.secret_key : maskString(data!.secret_key)
                }
                suffix={
                  <span
                    onClick={onClickEye}
                    className="cursor-pointer select-none"
                  >
                    {showSecret ? (
                      <Eye width="20px" height="20px" color="currentColor" />
                    ) : (
                      <EyeOff width="20px" height="20px" color="currentColor" />
                    )}
                  </span>
                }
              />
              <span
                onClick={() => copyText(data!.secret_key, data!.name)}
                className="h-12 cursor-pointer rounded-r border-y border-r border-neutral-700 bg-blue-900 px-4 py-3.5 text-neutral-500 hover:text-teal-400"
              >
                <Copy width="20px" height="20px" color="currentColor" />
              </span>
            </div>
            <div className="mt-12 flex gap-5">
              <GraniteButton variant="secondary" size="large" onClick={onClose}>
                Close
              </GraniteButton>
              <GraniteButton size="large" onClick={onCopyClick}>
                Copy keys
              </GraniteButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DetailsKey;
