import { NumericCell } from 'components/Table/Table.styles';
import Searchbar from 'components/Table/SearchBar';
import { useState } from 'react';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { useSortedTable } from 'hooks/useSortedTable';
import { EmptySearchResults } from 'screens/QuoteIndex/QuoteIndexPage';
import { ColumnDef, Row } from '@tanstack/react-table';
import { getRoles } from '../utils';
import { PaginableRequest } from 'api/common-schemas';
import { RoleType } from '../roles-permissions.types';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Outlet, useNavigate } from 'react-router-dom';

const Roles = () => {
  const [search, setSearch] = useState<string>('');

  const navigate = useNavigate();

  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'name', desc: true }],
  });

  const _getRoles = (args: PaginableRequest) =>
    getRoles({ ...args, ...(search && { search }) });

  const { data: tableData, ...paginatedTableProps } = usePaginatedTable(
    _getRoles,
    {
      search,
      sortingQueryParams,
    },
    ['roles-index-table', search, sortingQueryParams],
    {
      refetchOnMount: true,
    },
  );

  const tableHeaders: ColumnDef<RoleType>[] = [
    {
      header: 'Role',
      accessorKey: 'name',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      enableSorting: false,
    },
    {
      header: 'Total # of members',
      accessorKey: 'members',
      cell: (row) => <NumericCell>{row.row.original.members}</NumericCell>,
      meta: {
        align: 'right',
      },
    },
    {
      header: 'Actions',
      cell: (row) => (
        <>
          <GraniteButton
            size="small"
            className="mr-3"
            variant="secondary"
            onClick={(e) => {
              e.stopPropagation();
              navigate(`edit/${row.row.original.id}`);
            }}
          >
            Edit
          </GraniteButton>
          <GraniteButton
            size="small"
            variant="destructive"
            className="mr-1"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `delete/${row.row.original.id}/${row.row.original.name}`,
                { replace: true },
              );
            }}
          >
            Delete
          </GraniteButton>
        </>
      ),
    },
  ];

  const onRowClick = (row: Row<RoleType>) => navigate(`${row.original.id}`);

  return (
    <div className="flex w-full flex-wrap items-start justify-between gap-4">
      <Outlet />
      <Searchbar
        placeholder="Search by role type"
        onSearch={setSearch}
        onQueryClear={() => {
          setSearch('');
        }}
        clearAllValues={search === ''}
      />
      <div className="mt-6 w-full">
        <ServerPaginatedTable
          data={tableData}
          columns={tableHeaders}
          title="Roles"
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          handleRowClick={onRowClick}
          emptyDataElement={<EmptySearchResults />}
          tableContainerClassName="!grid-cols-[max-content_1fr_max-content_max-content]"
          columnGap="48px"
          {...paginatedTableProps}
          isFetchingData={paginatedTableProps.isLoading}
        />
      </div>
    </div>
  );
};

export default Roles;
