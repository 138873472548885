import { IframeHTMLAttributes } from 'react';

export const Excel = ({
  src,
  className,
}: IframeHTMLAttributes<HTMLIFrameElement>) => (
  <iframe
    className={className}
    src={`https://view.officeapps.live.com/op/embed.aspx?src=${src}`}
  />
);
