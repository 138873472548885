import DonutChart, { DonutChartData } from 'components/Charts/DonutChart';
import theme from '../../shared/theme/custom-theme';
import { useMemo } from 'react';
import clsx from 'clsx';
import HorizontalBarChart, {
  BarChartData,
} from 'components/Charts/HorizontalBarChart';

const Metrics = () => {
  const buildDonutChartData = (
    broadbandCount: number | undefined,
    managedFirewallCount: number | undefined,
    analogPOTsCount: number | undefined,
    longDistanceCount: number | undefined,
    wirelessMobilityCount: number | undefined,
  ): DonutChartData[] => {
    return [
      {
        x: 'Broadband',
        y: broadbandCount ?? 0,
        backgroundColor: theme.colors.donut.techCheckedIn,
      },
      {
        x: 'Managed - PremiseFirewall',
        y: managedFirewallCount ?? 0,
        backgroundColor: theme.colors.donut.techAssigned,
      },
      {
        x: 'Analog POTs',
        y: analogPOTsCount ?? 0,
        backgroundColor: theme.colors.donut.pendingTechAssignment,
      },
      {
        x: 'Long Distance',
        y: longDistanceCount ?? 0,
        backgroundColor: theme.colors.donut.pendingCustomer,
      },
      {
        x: 'Wireless Mobility',
        y: wirelessMobilityCount ?? 0,
        backgroundColor: theme.colors.donut.onHold,
      },
    ];
  };

  const buildBarChartData = (
    addedCount: number | undefined,
    endDatedCount: number | undefined,
  ): BarChartData[] => {
    return [
      {
        category: 'End dated',
        value: endDatedCount ?? 0,
        backgroundColor: theme.colors.bar.priority2,
      },
      {
        category: 'Added',
        value: addedCount ?? 0,
        backgroundColor: theme.colors.bar.priority1,
      },
    ];
  };

  const servicesChartData = useMemo(
    () => buildDonutChartData(3, 5, 6, 7, 8),
    [],
  );

  const serviceLifeCycleChartData = useMemo(
    () => buildBarChartData(24, 280),
    [],
  );
  return (
    <div className="grid w-full  grid-cols-2 gap-6 rounded">
      <div className="grid h-full w-full grid-cols-2 gap-6 rounded">
        <NumericDisplayCard title="Total services" value="340" />
        <NumericDisplayCard title="Total vendors" value="12" />
        <div className="col-span-2 w-full rounded bg-background-base-surface-2 p-6 shadow-elevation3">
          <DonutChart
            data={servicesChartData}
            title="My services"
            isLoading={false}
          />
        </div>
      </div>
      <div className="grid h-full w-full grid-cols-1 gap-6 rounded">
        <NumericDisplayCard
          title="Total locations"
          value="128"
          className="!col-span-2"
        />
        <div className="col-span-2 h-full w-full rounded bg-background-base-surface-2 p-6 shadow-elevation3">
          <HorizontalBarChart
            data={serviceLifeCycleChartData}
            title="Service lifecycle"
            isLoading={false}
            showFilter
          />
        </div>
      </div>
    </div>
  );
};

const NumericDisplayCard = ({
  title,
  value,
  className,
}: {
  title: string;
  value: string;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        'col-span-1 h-full rounded bg-background-base-surface-2 p-6 shadow-elevation3',
        className,
      )}
    >
      <h2 className="mb-4 text-base font-bold text-content-base-default">
        {title}
      </h2>
      <h2 className="m-0 text-[44px] font-bold text-content-base-default">
        {value}
      </h2>
    </div>
  );
};

export default Metrics;
