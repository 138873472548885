import { LetUsHelpLayout } from '../BaseComponents/LetUsHelpLayout';
import { LetUsHelpBaseForm } from '../BaseComponents/LetUsHelpBaseForm';
import React, { Dispatch, Fragment, useEffect, useMemo } from 'react';
import {
  Control,
  FieldErrors,
  useForm,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import {
  getFormTypeFromGetStartedQuestionnaire,
  TicketDetailFormSchemas,
  TicketDetailFormUnion,
} from './schemas';
import { ExtensionPOTS } from './RunExtension/ExtensionPOTS';
import { GraniteButton } from '../../../components/V2/Button/GraniteButton';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ExtensionCircuitForm,
  ExtensionPOTSForm,
} from './RunExtension/schemas';
import { TicketDetailForms, TicketDetailFormType } from './DefinedFormTypes';
import {
  useLetUsHelpContext,
  WizardAction,
} from '../WizardProvider/WizardReducer';
import {
  GetStartedQuestionnaireForm,
  GetStartedQuestionnaireFormSchema,
} from '../GetStarted/schemas';
import { ExtensionCircuit } from './RunExtension/ExtensionCircuit';

import { MoveForm } from './Move/schemas';
import { SmartHands } from './SmartHands/SmartHands';
import { SmartHandsForm } from './SmartHands/schemas';
import { Move } from './Move/Move';
import { Audit } from './Audit/Audit';
import { AuditForm } from './Audit/schemas';

import { EquipmentInstall } from './EquipmentInstall/EquipmentInstall';
import { EquipmentInstallForm } from './EquipmentInstall/schemas';
import { BroadbandCircuit } from './BroadbandCircuit/BroadbandCircuit';
import { BroadbandCircuitForm } from './BroadbandCircuit/schemas';
import { POTSCustomerLine } from './POTSCustomerLine/POTSCustomerLine';
import { POTSCustomerLineForm } from './POTSCustomerLine/schemas';
import { POTSGraniteLine } from './POTSGraniteLine/POTSGraniteLine';
import { POTSGraniteLineForm } from './POTSGraniteLine/schemas';
import { WiringCopper } from './WiringCopper/WiringCopper';
import { WiringCopperForm } from './WiringCopper/schemas';
import { WiringFiber } from './WiringFiber/WiringFiber';
import { WiringFiberForm } from './WiringFiber/schemas';
import { preventSubmitOnEnter } from '../../../shared/util/preventSubmitOnEnter';
import { CustomerBroadbandCircuitForm } from './CustomerBroadbandCircuit/schemas';
import { CustomerBroadbandCircuit } from './CustomerBroadbandCircuit/CustomerBroadbandCircuit';

import { CustomerWiringCopper } from './CustomerWiringCopper/CustomerWiringCopper';
import { CustomerWiringCopperForm } from './CustomerWiringCopper/schemas';
import { CustomerWiringFiber } from './CustomerWiringFiber/CustomerWiringFiber';
import { CustomerWiringFiberForm } from './CustomerWiringFiber/schemas';
import { CustomerEquipmentForm } from './CustomerEquipment/schemas';
import { CustomerEquipment } from './CustomerEquipment/CustomerEquipment';
import { CustomerMaterial } from './CustomerMaterial/CustomerMaterial';
import { CustomerMaterialForm } from './CustomerMaterial/schemas';
import { Navigate, useNavigate } from 'react-router-dom';
import { DeepPartial } from '../../../types/common-types';
import { dispatchReviewFormFromTicketDetailUnionForm } from '../utils/dispatchReviewFormFromTicketDetailUnionForm';
import { useProductTourContext } from 'context/ProductTourContext';
import { TicketDetailsBaseSkeleton } from './TicketDetailsSkeleton';
import { makeFieldsOptional } from 'api/schema-utils';

const detailFormForTypeDispatcher = (
  detailFormType: TicketDetailFormType | undefined,
  register: UseFormRegister<TicketDetailFormUnion>,
  control: Control<TicketDetailFormUnion>,
  errors: FieldErrors<TicketDetailFormUnion>,
  watch: UseFormWatch<TicketDetailFormUnion>,
) => {
  switch (detailFormType) {
    case TicketDetailForms.enum.ExtensionPOTS:
      return (
        <ExtensionPOTS
          key={TicketDetailForms.enum.ExtensionPOTS}
          register={register as UseFormRegister<ExtensionPOTSForm>}
          control={control as Control<ExtensionPOTSForm>}
          errors={errors as FieldErrors<ExtensionPOTSForm>}
        />
      );
    case TicketDetailForms.enum.ExtensionCircuit: {
      return (
        <ExtensionCircuit
          key={TicketDetailForms.enum.ExtensionCircuit}
          register={register as UseFormRegister<ExtensionCircuitForm>}
          control={control as Control<ExtensionCircuitForm>}
          errors={errors as FieldErrors<ExtensionCircuitForm>}
        />
      );
    }
    case TicketDetailForms.enum.Move: {
      return (
        <Move
          key={TicketDetailForms.enum.Move}
          register={register as UseFormRegister<MoveForm>}
          control={control as Control<MoveForm>}
          errors={errors as FieldErrors<MoveForm>}
        />
      );
    }
    case TicketDetailForms.enum.SmartHands: {
      return (
        <SmartHands
          key={TicketDetailForms.enum.SmartHands}
          register={register as UseFormRegister<SmartHandsForm>}
          control={control as Control<SmartHandsForm>}
          errors={errors as FieldErrors<SmartHandsForm>}
        />
      );
    }
    case TicketDetailForms.enum.Audit: {
      return (
        <Audit
          key={TicketDetailForms.enum.Audit}
          register={register as UseFormRegister<AuditForm>}
          control={control as Control<AuditForm>}
          errors={errors as FieldErrors<AuditForm>}
        />
      );
    }
    case TicketDetailForms.enum.EquipmentInstall: {
      return (
        <EquipmentInstall
          key={TicketDetailForms.enum.EquipmentInstall}
          register={register as UseFormRegister<EquipmentInstallForm>}
          control={control as Control<EquipmentInstallForm>}
          errors={errors as FieldErrors<EquipmentInstallForm>}
        />
      );
    }
    case TicketDetailForms.enum.BroadbandCircuit: {
      return (
        <BroadbandCircuit
          key={TicketDetailForms.enum.BroadbandCircuit}
          register={register as UseFormRegister<BroadbandCircuitForm>}
          control={control as Control<BroadbandCircuitForm>}
          errors={errors as FieldErrors<BroadbandCircuitForm>}
        />
      );
    }
    case TicketDetailForms.enum.POTSGraniteLine: {
      return (
        <POTSGraniteLine
          key={TicketDetailForms.enum.POTSGraniteLine}
          register={register as UseFormRegister<POTSGraniteLineForm>}
          control={control as Control<POTSGraniteLineForm>}
          errors={errors as FieldErrors<POTSGraniteLineForm>}
        />
      );
    }
    case TicketDetailForms.enum.WiringCopper: {
      return (
        <WiringCopper
          key={TicketDetailForms.enum.WiringCopper}
          register={register as UseFormRegister<WiringCopperForm>}
          control={control as Control<WiringCopperForm>}
          errors={errors as FieldErrors<WiringCopperForm>}
        />
      );
    }
    case TicketDetailForms.enum.WiringFiber: {
      return (
        <WiringFiber
          key={TicketDetailForms.enum.WiringFiber}
          register={register as UseFormRegister<WiringFiberForm>}
          control={control as Control<WiringFiberForm>}
          errors={errors as FieldErrors<WiringFiberForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerBroadbandCircuit: {
      return (
        <CustomerBroadbandCircuit
          key={TicketDetailForms.enum.CustomerBroadbandCircuit}
          register={register as UseFormRegister<CustomerBroadbandCircuitForm>}
          control={control as Control<CustomerBroadbandCircuitForm>}
          errors={errors as FieldErrors<CustomerBroadbandCircuitForm>}
        />
      );
    }
    case TicketDetailForms.enum.POTSCustomerLine: {
      return (
        <POTSCustomerLine
          key={TicketDetailForms.enum.POTSCustomerLine}
          register={register as UseFormRegister<POTSCustomerLineForm>}
          control={control as Control<POTSCustomerLineForm>}
          errors={errors as FieldErrors<POTSCustomerLineForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerWiringCopper: {
      return (
        <CustomerWiringCopper
          key={TicketDetailForms.enum.CustomerWiringCopper}
          register={register as UseFormRegister<CustomerWiringCopperForm>}
          control={control as Control<CustomerWiringCopperForm>}
          errors={errors as FieldErrors<CustomerWiringCopperForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerWiringFiber: {
      return (
        <CustomerWiringFiber
          key={TicketDetailForms.enum.CustomerWiringFiber}
          register={register as UseFormRegister<CustomerWiringFiberForm>}
          control={control as Control<CustomerWiringFiberForm>}
          errors={errors as FieldErrors<CustomerWiringFiberForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerEquipment: {
      return (
        <CustomerEquipment
          key={TicketDetailForms.enum.CustomerEquipment}
          register={register as UseFormRegister<CustomerEquipmentForm>}
          control={control as Control<CustomerEquipmentForm>}
          errors={errors as FieldErrors<CustomerEquipmentForm>}
          watch={watch as UseFormWatch<CustomerEquipmentForm>}
        />
      );
    }
    case TicketDetailForms.enum.CustomerMaterial: {
      return (
        <CustomerMaterial
          key={TicketDetailForms.enum.CustomerMaterial}
          register={register as UseFormRegister<CustomerMaterialForm>}
          control={control as Control<CustomerMaterialForm>}
          errors={errors as FieldErrors<CustomerMaterialForm>}
        />
      );
    }
    default: {
      return <div>Form not registered?</div>;
    }
  }
};

interface TicketDetailsBaseProps {
  dispatch: Dispatch<WizardAction>;
  questionnaire: GetStartedQuestionnaireForm;
  ticketDetail?: DeepPartial<TicketDetailFormUnion>;
}

const TicketDetailsBase = ({
  dispatch,
  questionnaire,
  ticketDetail,
}: TicketDetailsBaseProps) => {
  // The TicketDetailFormUnion is discriminated by `detailFormType` which is pretty equivalent to
  // the ValidTicketTypeProductPair, the issue being that the latter is two different strings
  // and the discriminator needs to be a single one.
  const detailFormType = useMemo(
    () => getFormTypeFromGetStartedQuestionnaire(questionnaire),
    [questionnaire],
  );

  const navigate = useNavigate();
  const formType = detailFormType ?? ticketDetail?.formType;
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<TicketDetailFormUnion>({
    resolver: zodResolver(TicketDetailFormSchemas),
    defaultValues: {
      nonStandardTools: { isNeeded: false },
      ...ticketDetail,
    },
  });
  // RHF's `defaultValues` collapses our union tag and throws an error.
  // Use setValue instead to set the formType from the existing partial form
  // or a calculated new one from the GetStarted page instead of setting it as
  // a default value
  useEffect(() => {
    setValue('formType', formType);
  }, [formType, setValue]);

  const onSubmit = (formData: TicketDetailFormUnion) => {
    dispatchReviewFormFromTicketDetailUnionForm(
      formData,
      dispatch,
      questionnaire,
    );

    navigate('../review');
  };

  const onError = (error: unknown) => {
    console.log(error);
  };

  watch((formData) => {
    dispatch({
      type: 'UpdateTicketDetails',
      ticketDetailForm: formData,
    });
  });

  watch((formData) => {
    const isTechToTestWith = 'techToTestWith' in formData;
    if (
      !isTechToTestWith &&
      formData.formType === 'CustomerEquipment' &&
      formData.itTestingContacts?.length === 0
    ) {
      setValue('itTestingContacts', [{ contactName: '', contactNumber: '' }]);
    }
    if (isTechToTestWith) {
      if (formData.techToTestWith?.techToTestWith === 'No') {
        if (formData.itTestingContacts?.length !== 0) {
          setValue('itTestingContacts', []);
        }
      } else {
        if (formData.itTestingContacts?.length === 0) {
          setValue('itTestingContacts', [
            { contactName: '', contactNumber: '' },
          ]);
        }
      }
    }
  });

  return (
    <LetUsHelpLayout>
      <LetUsHelpBaseForm
        className="gap-12"
        id="ticket-detail-form"
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={preventSubmitOnEnter}
      >
        {detailFormForTypeDispatcher(
          detailFormType,
          register,
          control,
          errors,
          watch,
        )}
      </LetUsHelpBaseForm>
      <div className="sticky top-8 flex gap-4 rounded bg-background-base-surface-2 p-6 shadow">
        <GraniteButton
          className="w-full"
          variant="secondary"
          size="large"
          onClick={() => navigate(-1)}
        >
          Back
        </GraniteButton>
        <GraniteButton
          className="w-full"
          size="large"
          type="submit"
          form="ticket-detail-form"
        >
          Next
        </GraniteButton>
      </div>
    </LetUsHelpLayout>
  );
};

export const TicketDetailsFormWrapper = () => {
  const { dispatch, state } = useLetUsHelpContext();
  const { running } = useProductTourContext();
  if (running) {
    return <TicketDetailsBaseSkeleton />;
  }

  if (state.state !== 'TicketDetails') {
    if (state.state === 'Review') {
      // Coming from review, user clicked back.

      if (state.ticketDetailForm?.formType === 'CustomerEquipment') {
        const guideAttachments = state.ticketDetailForm.guideAttachments || [];
        const reviewAttachments = state.reviewForm?.attachments || [];

        const updatedGuideAttachments = guideAttachments.filter(
          (guideAttachment) =>
            reviewAttachments.some(
              (reviewAttachment) => reviewAttachment === guideAttachment,
            ),
        );
        dispatch({
          type: 'GoToTicketDetails',
          ticketDetailForm: {
            ...state.ticketDetailForm,
            guideAttachments: updatedGuideAttachments,
          },
          getStartedQuestionnaire: state.getStartedQuestionnaire,
        });
      } else {
        dispatch({
          type: 'GoToTicketDetails',
          ticketDetailForm: state.ticketDetailForm,
          getStartedQuestionnaire: state.getStartedQuestionnaire,
        });
      }
      // we need to check if state.getStartedQuestionnaire is defined to not let the user access the route directly
    } else if (state.state === 'GetStarted' && state.getStartedQuestionnaire) {
      // Coming from GetStarted using the browser forward button
      let schema = GetStartedQuestionnaireFormSchema;
      if (state.getStartedQuestionnaire.type === 'multi') {
        schema = makeFieldsOptional(schema, [
          'dispatchDate',
          'accessTime.start_date',
        ]);
      }
      const questionnaire = schema.safeParse(state.getStartedQuestionnaire);

      if (questionnaire.success) {
        dispatch({
          type: 'GoToTicketDetails',
          ticketDetailForm: state.ticketDetailForm,
          getStartedQuestionnaire: questionnaire.data,
        });
      }
    } else {
      // User is trying to access the route directly
      return <Navigate to="/tech-express/let-us-help" />;
    }

    // Return Fragment for first-render, once dispatch triggers re-render we should get the proper form
    return <Fragment />;
  }

  return (
    <TicketDetailsBase
      dispatch={dispatch}
      questionnaire={state.getStartedQuestionnaire}
      ticketDetail={state.ticketDetailForm}
    />
  );
};
