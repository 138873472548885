import { BasePagination, PaginableRequest } from 'api/common-schemas';
import {
  EditRoleFormType,
  PermissionFormType,
  PermissionType,
  RoleDetailsType,
  RoleFormType,
  RoleType,
} from './roles-permissions.types';
import { apiClient } from 'api/apiClient';

export const getRoles = async (
  args: PaginableRequest & { search?: string },
): Promise<BasePagination<RoleType>> => {
  const response = await apiClient.get('/api/v1/roles', {
    params: args,
  });
  return response.data;
};

export const getPermissions = async (
  args: PaginableRequest & { search?: string },
): Promise<BasePagination<PermissionType>> => {
  const response = await apiClient.get('/api/v1/roles/permissions', {
    params: args,
  });
  return response.data;
};

export const getRoleById = async (
  id: string,
): Promise<RoleDetailsType | undefined> => {
  const response = await apiClient.get(`/api/v1/roles/${id}`);
  return response.data;
};

export const postPermission = async (
  body: PermissionFormType,
): Promise<PermissionType | undefined> => {
  const response = await apiClient.post('/api/v1/roles/permissions', body);
  return response.data;
};

export const getPermissionById = async (
  id: string,
): Promise<PermissionType | undefined> => {
  const response = await apiClient.get(`/api/v1/roles/permissions/${id}`);
  return response.data;
};

export const putPermission = async ({
  id,
  ...body
}: PermissionFormType & { id: string }): Promise<
  PermissionType | undefined
> => {
  const response = await apiClient.patch(
    `/api/v1/roles/permissions/${id}`,
    body,
  );
  return response.data;
};

export const deletePermission = async (
  id: string,
): Promise<PermissionType | undefined> => {
  const response = await apiClient.delete(`/api/v1/roles/permissions/${id}`);
  return response.data;
};

export const postRole = async (
  body: RoleFormType,
): Promise<RoleType | undefined> => {
  const response = await apiClient.post('/api/v1/roles', body);
  return response.data;
};

export const deleteRole = async (id: string): Promise<RoleType | undefined> => {
  const response = await apiClient.delete(`/api/v1/roles/${id}`);
  return response.data;
};

export const putRole = async ({
  id,
  ...body
}: EditRoleFormType & { id: string }): Promise<RoleType | undefined> => {
  const response = await apiClient.patch(`/api/v1/roles/${id}`, body);
  return response.data;
};
