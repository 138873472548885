import React, { ComponentProps } from 'react';
import { clsx } from 'clsx';

export type LetUsHelpLayoutProps = ComponentProps<'div'>;

export const LetUsHelpLayout = ({
  className,
  children,
  ...props
}: LetUsHelpLayoutProps) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 items-start gap-8 lg:grid-cols-[2fr_minmax(0,_508px)]',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
