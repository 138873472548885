import React, { Fragment } from 'react';
import IconButton from 'components/IconButton/IconButton';
import BadgeCell from 'components/Badge/Badge';
import { Link } from 'react-router-dom';
import { useSimplePagination } from '../../hooks/useSimplePagination';
import { EmptyState } from 'components/EmptyState/EmptyState';
import Skeleton from 'components/Skeleton/Skeleton';
import {
  EndDatedBadge,
  Priority1Badge,
} from 'screens/TechExpress/TechExpress.styles';
import CustomSelectControl from './CustomSelectControl';

export interface IRecentActivity {
  id: number;
  // top left
  address1: string;
  // bottom right
  type: string;
  // bottom left
  description: string;
  //
  tag: 'Added' | 'End dated';
  // top right
  service_id: string;
}

interface RecentActivityProps {
  tickets: IRecentActivity[];
  pageSize?: number;
  isLoading?: boolean;
}

const RecentActivity: React.FC<RecentActivityProps> = ({
  tickets,
  pageSize = 5,
  isLoading,
}) => {
  const {
    maxPages,
    pageNumber,
    previousPage,
    nextPage,
    page: pagedActivity,
  } = useSimplePagination({ items: tickets, pageSize });

  return (
    <div className="relative h-full">
      <div className="flex w-full items-start justify-between">
        <div className="flex w-1/2 flex-col gap-0">
          <h2 className="text-xl font-bold text-content-base-default md:text-2xl">
            Recent activity
          </h2>
          <div className="-mt-2">
            <CustomSelectControl />
          </div>
        </div>
        <div className="hidden items-center justify-center gap-2 sm:flex ">
          <IconButton
            size="small"
            buttonType="secondary"
            icon="chevronBack"
            onClick={previousPage}
            disabled={pageNumber === 1}
            padding={'3px'}
          />
          <IconButton
            size="small"
            buttonType="secondary"
            icon="chevronForward"
            onClick={nextPage}
            padding={'3px'}
            disabled={pageNumber === maxPages}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="flex h-full w-[456px] flex-col gap-3">
          {Array.from({ length: 5 }).map((_, index) => (
            <Skeleton key={index} className="h-16" />
          ))}
        </div>
      ) : pagedActivity.length > 0 ? (
        <div className="max-h-400 flex flex-col gap-3 overflow-y-auto">
          {pagedActivity.map((ticket) => (
            <Link
              key={ticket.id}
              className="flex w-full flex-col gap-2 rounded bg-background-base-surface-3 p-2 hover:border-content-accent-default focus:border-content-accent-default active:bg-background-base-surface-2"
              to={`/inventory`}
            >
              <div className="flex w-full justify-between">
                <div className="flex w-full flex-row">
                  <p className="w-full text-sm font-bold text-content-base-default">
                    {ticket.address1}
                  </p>
                </div>
                <div className="flex w-full justify-end gap-2">
                  <BadgeCell value={ticket.tag} width="!w-max" />
                  <BadgeForTag value={ticket.service_id} tag={ticket.tag} />
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="flex flex-row">
                  <p className="text-text-content-base-subdued text-xs font-semibold">
                    {ticket.description}
                  </p>
                </div>
                <div className="flex flex-row">
                  <p className="text-xs font-semibold text-content-base-subdued">
                    {ticket.type}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <EmptyState className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
      )}
    </div>
  );
};

export const BadgeForTag = ({
  tag,
  value,
}: {
  tag: 'Added' | 'End dated';
  value: string;
}) => {
  switch (tag) {
    case 'Added':
      return <Priority1Badge>{value}</Priority1Badge>;
    case 'End dated':
      return <EndDatedBadge>{value}</EndDatedBadge>;
    default:
      return <Fragment />;
  }
};

export default RecentActivity;
