import styled from 'styled-components';

export const StatusCell = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const StackedCellMainContent = styled.span`
  color: ${({ theme }) => theme.colors.text.white};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export const StatusIndicator = styled.span<{ $status: string }>`
  background: ${({ $status }) => {
    switch ($status.toLocaleLowerCase()) {
      case 'active':
        return '#82FF91';
      case 'deactivated':
        return '#94A3B8';
    }
  }};

  width: 12px;
  height: 12px;
  border-radius: 100%;
`;

export const StackedCellSubtitle = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
`;

export const StackedCell = styled.div`
  display: flex;
  flex-direction: column;
`;
