import { ReactComponent as AccessActiveSvg } from '../../assets/images/accessexpress.svg';
import { ReactComponent as TechActiveSvg } from '../../assets/images/techexpress.svg';
import { ReactComponent as NocActiveSvg } from '../../assets/images/nocexpress.svg';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { FunctionComponent, SVGProps } from 'react';

export type PillarOption = {
  name:
    | 'all'
    | 'access-express'
    | 'tech-express'
    | 'noc-express'
    | 'user-management';
  label: string | null;
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  > | null;
  category: 'Workspace' | 'Admin';
  active: boolean;
  roles?: TokenUserRoles[];
};

const pillarOptions: PillarOption[] = [
  {
    name: 'all',
    label: 'All workspaces',
    Icon: null,
    category: 'Workspace',
    active: true,
  },
  {
    name: 'access-express',
    label: null,
    Icon: AccessActiveSvg,
    category: 'Workspace',
    active: false,
  },
  {
    name: 'tech-express',
    label: null,
    Icon: TechActiveSvg,
    category: 'Workspace',
    active: true,
  },
  {
    name: 'noc-express',
    label: null,
    Icon: NocActiveSvg,
    category: 'Workspace',
    active: true,
  },
  {
    name: 'user-management',
    label: 'User management',
    Icon: null,
    category: 'Admin',
    active: false,
    roles: [TokenUserRoles.COMPANY_ADMIN],
  },
];

export type Category = (typeof pillarOptions)[number]['category'];

export const pillarByCategory = pillarOptions.reduce<
  Record<Category, (typeof pillarOptions)[number][]>
>(
  (acc, option) => {
    if (option.active)
      acc[option.category] = [...(acc[option.category] ?? []), option];
    return acc;
  },
  {} as Record<Category, (typeof pillarOptions)[number][]>,
);
