import { GraniteButton } from '../../../components/V2/Button/GraniteButton';
import FormSection from '../../../components/FormSection';
import Divider from '../../../components/Divider';
import { SearchAddressBar } from '../../../components/SearchAddressBar/SearchAddressBar';
import { GraniteLabel } from '../../../components/V2/Label/GraniteLabel';
import { Controller, DeepPartial, FieldErrors, useForm } from 'react-hook-form';
import RadioButton from '../../../components/RadioButton';
import {
  RangeTimePicker,
  TimePicker,
} from '../../../components/TimePicker/TimePicker';
import React, { Dispatch, Fragment, useEffect, useMemo, useState } from 'react';
import {
  FixMyServiceGraniteServices,
  GetStartedQuestionnaireForm,
  GetStartedQuestionnaireFormSchema,
  GraniteNotOwnedServiceSubForm,
  GraniteOwnedServiceSubForm,
  LocationsArraySchema,
  LocationsSchema,
  MyServiceIssues,
  WhyTechnicianEnum,
} from './schemas';
import './GetStarted.css';
import { ErrorSubtext } from '../../../components/ErrorSubtext/ErrorSubtext';
import { LetUsHelpLayout } from '../BaseComponents/LetUsHelpLayout';
import { LetUsHelpBaseForm } from '../BaseComponents/LetUsHelpBaseForm';
import {
  useLetUsHelpContext,
  WizardAction,
} from '../WizardProvider/WizardReducer';
import { preventSubmitOnEnter } from '../../../shared/util/preventSubmitOnEnter';
import { getTimeZoneAbbreviationFromZipCode } from 'shared/util/util';
import { useNavigate } from 'react-router-dom';
import { DispatchDateSelectorField } from '../BaseComponents/DispatchDateSelectorField';
import { getDuplicateTickets } from '../../../api/techexpress/getDuplicateTickets';
import { useModal } from '../../../hooks/useModal';
import { DuplicateTicketWarningDialog } from '../../../components/DuplicateTicketWarningDialog/DuplicateTicketWarningDialog';
import { Template, Ticket } from '../../../api/techexpress/schema';
import MultiOptionButton from 'components/MultiOptionButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import clsx from 'clsx';
import { BulkModalLocations } from './BulkModalLocations';
import { LocationList } from './LocationsList';
import { accessTimeDummy, dummySiteData } from '../Review/Review';
import { Add, CheckmarkCircle, CloseCircle } from 'react-ionicons';
import useDownloadConfigurations from 'shared/util/useDownloadConfigurations';
import { getLetUsHelpMockData } from 'mocks/tour';
import { useProductTourContext } from 'context/ProductTourContext';
import { getSites } from 'api/companies/api';
import { ApplyTemplateModal } from './ApplyTemplateModal';
import { ReactComponent as Sparkles } from 'assets/icon/sparkles.svg';
import { ClearTemplateConfirmationModal } from './ClearTemplateConfirmationModal';
import CreateChildAccountModal from './CreateChildAccount/CreateChildAccountModal';
import { SiteAddress } from 'api/addresssearch/schema';
import { makeFieldsOptional } from 'api/schema-utils';
import createGetStartedReviewResolver from '../utils/createGetStartedReviewResolver';

interface GetStartedProps {
  dispatch: Dispatch<WizardAction>;
  questionnaire?: DeepPartial<GetStartedQuestionnaireForm>;
  template?: Template;
  onCancel: () => void;
}

const GetStarted = ({
  dispatch,
  questionnaire,
  template,
  onCancel,
}: GetStartedProps) => {
  const navigate = useNavigate();
  const { running } = useProductTourContext();
  const [isBulkModalOpen, setIsBulkModalOpen] = useState<boolean>(false);
  const mockData = useMemo(() => getLetUsHelpMockData(), []);
  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { isDirty, errors, isSubmitting },
    setValue,
    reset,
  } = useForm<GetStartedQuestionnaireForm>({
    resolver: createGetStartedReviewResolver(GetStartedQuestionnaireFormSchema),
    defaultValues: {
      type: 'single',
      content: {
        type: 'single',
      },
      dispatchDate: undefined,
      accessTime: {
        scheduling_type: 'Hard Start',
        start_date: undefined,
      },
      ...questionnaire,
    },
  });

  useEffect(() => {
    if (running) {
      dispatch({
        type: 'GoToTicketDetails',
        //@ts-expect-error schema validation
        getStartedQuestionnaire: mockData,
      });
    }
  }, [running, dispatch, mockData]);

  useEffect(() => {
    const { unsubscribe } = watch((formData) => {
      dispatch({
        type: 'UpdateGetStartedQuestionnaire',
        getStartedQuestionnaire: formData,
      });
    });
    return () => unsubscribe();
  }, [dispatch, watch]);

  useEffect(() => {
    const handleReloading = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleReloading, { capture: true });

    return () => {
      window.removeEventListener('beforeunload', handleReloading, {
        capture: true,
      });
    };
  }, [isDirty]);

  const { openWithProps, ...duplicateModalProps } = useModal<Ticket[]>();

  const { open, ...bulkModalProps } = useModal<LocationsArraySchema>();
  const { open: applyTemplateModalOpen, ...applyTemplateModalProps } =
    useModal();
  const {
    open: clearTemplateConfirmationModalOpen,
    ...clearTemplateConfirmationModalProps
  } = useModal();

  const onSubmit = async (formData: GetStartedQuestionnaireForm) => {
    if (formData.type === 'single') {
      const duplicates = await getDuplicateTickets({
        site: formData.site.id,
        dispatchDate: formData.dispatchDate,
      });
      if (duplicates.length > 0) {
        openWithProps(duplicates);
      } else {
        dispatch({
          type: 'GoToTicketDetails',
          getStartedQuestionnaire: formData,
        });
        navigate('details');
      }
    } else if (formData.type === 'multi') {
      dispatch({
        type: 'GoToTicketDetails',
        getStartedQuestionnaire: formData,
      });
      navigate('details');
    }
  };

  const onError = (err: unknown) => console.log({ err });

  const [type, site, whyTechnician, locations] = watch([
    'type',
    'site',
    'whyTechnician',
    'content.locations',
  ]);

  const timeZoneAbbreviation = getTimeZoneAbbreviationFromZipCode(
    site?.zip || '',
  );

  useEffect(() => {
    if (
      whyTechnician &&
      whyTechnician !== questionnaire?.whyTechnician &&
      !running
    ) {
      dispatch({ type: 'ResetForms', keepDefaults: true });
    }
  }, [dispatch, questionnaire?.whyTechnician, whyTechnician, running]);

  const uploadLocations = (data: LocationsSchema) => {
    setValue('type', 'multi');
    setValue('content.locations', data.locations);
    setValue('content.type', 'multi');
  };

  useEffect(() => {
    if (type === 'multi') {
      setValue('site', dummySiteData);
      //@ts-expect-error add dummy data
      setValue('accessTime', accessTimeDummy);
    }
  }, [setValue, type]);

  const { isLoading: isDownloading, onDownloadHandler } =
    useDownloadConfigurations({ fetchFn: getSites });

  const onApplyTemplate = (template: Template) => {
    dispatch({
      type: 'ApplyTemplate',
      template,
    });
    reset({
      type: 'single',
      content: {
        type: 'single',
      },
      accessTime: { scheduling_type: 'Hard Start' },
      ...template.data.getStartedQuestionnaire,
    });
    applyTemplateModalProps.close();
  };

  const onClearTemplateConfirm = () => {
    dispatch({ type: 'ResetForms' });
    reset({
      type: 'single',
      content: {
        type: 'single',
      },
      accessTime: { scheduling_type: 'Hard Start' },
    });
    clearTemplateConfirmationModalProps.close();
  };

  const { open: createChildAccountModalOpen, ...createChildAccountModalProps } =
    useModal();

  return (
    <LetUsHelpLayout>
      <LetUsHelpBaseForm
        className="gap-16"
        id="get-started-form"
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={preventSubmitOnEnter}
      >
        <FormSection title="How can we help?">
          <Controller
            name="whyTechnician"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <div className="gap grid grid-cols-2 gap-4">
                <MultiOptionButton
                  label="Fix my service"
                  option={WhyTechnicianEnum.enum.FixMyService}
                  value={value}
                  onClick={onChange}
                  ref={ref}
                  subText="Technician will be dispatched to site to investigate, troubleshoot, or repair the service."
                />
                <MultiOptionButton
                  label="Move equipment or wiring"
                  option={WhyTechnicianEnum.enum.MoveEquipmentOrWiring}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to move specific equipment or wiring from one location to another."
                />
                <MultiOptionButton
                  label="Onsite tech support"
                  option={WhyTechnicianEnum.enum.OnsiteTechSupport}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to provide remote smart hands support."
                />
                <MultiOptionButton
                  label="Site audit"
                  option={WhyTechnicianEnum.enum.SiteAudit}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to survey and record location & network specifications for future installs."
                />
                <MultiOptionButton
                  label="Equipment install"
                  option={WhyTechnicianEnum.enum.EquipmentInstall}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to install or decommission equipment."
                />
                <MultiOptionButton
                  label="Run extension"
                  option={WhyTechnicianEnum.enum.RunExtension}
                  value={value}
                  onClick={onChange}
                  subText="Technician will be dispatched to site to run an extension for an existing POTS line or circuit."
                />
                {errors.whyTechnician && (
                  <ErrorSubtext
                    className="-mt-2"
                    error="Selecting a reason is required"
                    withTopMargin={false}
                  />
                )}
              </div>
            )}
          />
          <div>
            <Fragment>
              {template ? (
                <div className="space-y-4">
                  <GraniteButton
                    type="button"
                    variant="secondary"
                    onClick={applyTemplateModalOpen}
                  >
                    <span>Template applied</span>
                    <CheckmarkCircle
                      width="16px"
                      height="16px"
                      color={'inherit'}
                    />
                  </GraniteButton>
                  <div className="flex w-max items-center gap-1 rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-3 text-base font-light text-content-base-default">
                    {template.name}
                    <button
                      type="button"
                      className="cursor-pointer fill-content-base-subdued"
                      title="Remove search query"
                      role="button"
                      onClick={clearTemplateConfirmationModalOpen}
                    >
                      <CloseCircle color="inherit" width="16px" height="16px" />
                    </button>
                  </div>
                </div>
              ) : (
                <GraniteButton
                  variant="secondary"
                  onClick={applyTemplateModalOpen}
                >
                  <span>Templates</span>
                  <Sparkles className="h-4 w-4" />
                </GraniteButton>
              )}
            </Fragment>
          </div>
          <div
            className={clsx(
              'col-span-full mt-6 hidden',
              whyTechnician === WhyTechnicianEnum.enum.FixMyService &&
                '!flex flex-col gap-6',
            )}
          >
            <Controller
              name="graniteOwned"
              shouldUnregister={false}
              control={control}
              render={({ field: { value, onChange, ref }, fieldState }) => (
                <Fragment>
                  <GraniteLabel
                    label="Is this a Granite-provided service?"
                    subtitle="Services that are provided, managed, or rebilled through Granite are Granite-provided"
                    element="div"
                  >
                    <RadioButton
                      options={[
                        { value: 'true', label: 'Yes' },
                        { value: 'false', label: 'No' },
                      ]}
                      selectedValue={`${value?.isGraniteOwned}`}
                      onChange={(newValue) =>
                        onChange({
                          isGraniteOwned: newValue === 'true',
                        })
                      }
                      className="text-base"
                      error={fieldState.error?.message}
                      ref={ref}
                    />
                  </GraniteLabel>
                  {value?.isGraniteOwned ? (
                    <Fragment>
                      <GraniteLabel
                        label="Which Granite service is experiencing an issue?"
                        element="div"
                      >
                        <RadioButton
                          options={[
                            {
                              value:
                                FixMyServiceGraniteServices.enum
                                  .BroadbandCircuit,
                              label: 'Broadband circuit',
                            },
                            {
                              value: FixMyServiceGraniteServices.enum.POTSLine,
                              label: 'POTS line',
                            },
                            {
                              value: FixMyServiceGraniteServices.enum.Wiring,
                              label: 'Wiring',
                            },
                          ]}
                          selectedValue={value.service?.which}
                          onChange={(newValue) =>
                            onChange({
                              ...value,
                              service: {
                                which: newValue,
                              },
                            })
                          }
                          ref={ref}
                          className="text-base"
                          error={
                            (
                              fieldState.error as FieldErrors<GraniteOwnedServiceSubForm>
                            )?.service?.message
                          }
                        />
                      </GraniteLabel>
                      {value.service?.which ===
                        FixMyServiceGraniteServices.enum.Wiring && (
                        <GraniteLabel
                          label="Is this a fiber or wire extension?"
                          element="div"
                        >
                          <RadioButton
                            options={[
                              {
                                value: 'Copper',
                                label: 'Copper',
                              },
                              { value: 'Fiber', label: 'Fiber' },
                            ]}
                            error={
                              (
                                fieldState.error as FieldErrors<GraniteOwnedServiceSubForm>
                              )?.service && 'Required'
                            }
                            selectedValue={value.service.copperOrFiber}
                            onChange={(newValue) =>
                              onChange({
                                ...value,
                                service: {
                                  ...value.service,
                                  copperOrFiber: newValue,
                                },
                              })
                            }
                            ref={ref}
                          />
                        </GraniteLabel>
                      )}
                    </Fragment>
                  ) : value?.isGraniteOwned === false ? (
                    <Fragment>
                      {/*  Add a key to GraniteLabel to avoid react reconciliation algo reusing the RadioButton with a previous state*/}
                      <GraniteLabel
                        label="Please select the best category for the issue you're experiencing"
                        element="div"
                        key={`granite-owned-${value?.isGraniteOwned}`}
                      >
                        <RadioButton
                          options={[
                            {
                              value: MyServiceIssues.enum.Circuit,
                              label: 'Circuit',
                            },
                            {
                              value: MyServiceIssues.enum.POTSLine,
                              label: 'POTS line',
                            },
                            {
                              value: MyServiceIssues.enum.Wiring,
                              label: 'Wiring',
                            },
                            {
                              value:
                                MyServiceIssues.enum.CustomerProvidedEquipment,
                              label: 'Customer provided equipment',
                            },
                            {
                              value: MyServiceIssues.enum.MaterialReplacement,
                              label:
                                'Material replacement (broken jack, faceplate, patch cord, etc.)',
                            },
                          ]}
                          selectedValue={value.service?.which}
                          onChange={(newValue) =>
                            onChange({
                              ...value,
                              service: {
                                which: newValue,
                              },
                            })
                          }
                          ref={ref}
                          className="text-base"
                          error={
                            (
                              fieldState.error as FieldErrors<GraniteNotOwnedServiceSubForm>
                            )?.service?.message
                          }
                        />
                      </GraniteLabel>
                      {value.service?.which === MyServiceIssues.enum.Wiring && (
                        <GraniteLabel
                          label="Is this a fiber or copper extension?"
                          element="div"
                          key={`${value.isGraniteOwned}-${value.service?.extensionType}`}
                        >
                          <RadioButton
                            options={[
                              {
                                value: 'Copper',
                                label: 'Copper',
                              },
                              { value: 'Fiber', label: 'Fiber' },
                            ]}
                            error={
                              (
                                fieldState.error as FieldErrors<GraniteNotOwnedServiceSubForm>
                              )?.service && 'Required'
                            }
                            selectedValue={value.service.extensionType}
                            onChange={(newValue) =>
                              onChange({
                                ...value,
                                service: {
                                  ...value.service,
                                  extensionType: newValue,
                                },
                              })
                            }
                            ref={ref}
                          />
                        </GraniteLabel>
                      )}
                    </Fragment>
                  ) : null}
                </Fragment>
              )}
            />
          </div>
          <div
            className={clsx(
              // We use the 'hidden' class instead
              // of conditionally rendering these components because we want to ensure
              // that they are present in the DOM. This is important for the auto-scrolling
              // functionality of react-hook-form to work correctly.
              'col-span-full mt-6 hidden',
              whyTechnician === WhyTechnicianEnum.enum.RunExtension &&
                '!flex flex-col gap-6',
            )}
          >
            <Controller
              name="potsOrCircuit"
              control={control}
              render={({ field: { value, onChange, ref }, fieldState }) => (
                <GraniteLabel
                  label="Is this for POTS or a circuit?"
                  element="div"
                >
                  <RadioButton
                    options={[
                      {
                        value: 'POTS',
                        label: 'POTS (plain old telephone service)',
                      },
                      { value: 'Circuit', label: 'Circuit' },
                    ]}
                    error={fieldState.error?.message}
                    selectedValue={value}
                    onChange={onChange}
                    ref={ref}
                  />
                </GraniteLabel>
              )}
            />
          </div>
        </FormSection>
        {type === 'single' && (
          <>
            <Divider />
            <FormSection
              title="Which address will we be servicing?"
              gridClassName="grid-cols-2"
            >
              <Controller
                name="site"
                control={control}
                render={({ field }) => (
                  <SearchAddressBar
                    {...field}
                    value={site}
                    onAddressSelected={(address) => {
                      // RHF bug workaround
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setValue('site', address);
                    }}
                    name={'search address'}
                    error={
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      errors?.site?.message && 'An address is required'
                    }
                    className="col-span-2"
                    createChildAccountModalOpen={createChildAccountModalOpen}
                  />
                )}
              />
              <Controller
                name="content.locationName"
                control={control}
                render={({ field }) => (
                  <GraniteInput
                    label="Location name"
                    subtitle="Name of the business that the technician should look for upon arrival"
                    className="col-start-1"
                    {...field}
                    ref={field.ref}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    error={errors.content?.locationName?.message}
                  />
                )}
              />
              <Controller
                name="content.locationNumber"
                control={control}
                render={({ field }) => (
                  <GraniteInput
                    label="Location number (optional)"
                    className="col-start-2 mt-10"
                    {...field}
                    ref={field.ref}
                    //@ts-expect-error this exists
                    error={errors.content?.locationNumber?.message}
                  />
                )}
              />
              <div className="col-span-2 flex flex-col items-start gap-2">
                <p className="text-sm text-content-base-subdued">
                  By adding multiple sites, you can create up to ten separate
                  dispatch requests for different locations, following the same
                  scope of work.{' '}
                  <a
                    rel="noreferrer"
                    download
                    onClick={
                      !isDownloading
                        ? onDownloadHandler
                        : (e) => e.preventDefault()
                    }
                    className={clsx(
                      !isDownloading &&
                        'cursor-pointer text-content-accent-default underline visited:text-content-accent-default',
                      isDownloading &&
                        'text-button-background-primary-disabled underline',
                    )}
                  >
                    Download site list
                  </a>
                </p>
                <GraniteButton
                  variant="secondary"
                  className="!box-border !max-w-[180px]"
                  onClick={() => {
                    setIsBulkModalOpen(true);
                    open();
                  }}
                >
                  Add multiple
                  <Add width="20px" height="20px" color="inherit" />
                </GraniteButton>
              </div>
            </FormSection>
          </>
        )}

        {type === 'multi' && locations.length && (
          <>
            <Divider />
            <FormSection title="Addresses">
              <LocationList locations={locations} />
              <div className="flex items-center justify-start gap-4">
                <GraniteButton
                  variant="secondary"
                  onClick={() => {
                    setIsBulkModalOpen(true);
                    open();
                  }}
                >
                  Edit
                </GraniteButton>
                <GraniteButton
                  variant="secondary"
                  onClick={async () => {
                    setValue('content.locations', []);
                    await trigger('content.locations');
                    setValue('type', 'single');
                    setValue('content.type', 'single');
                    //@ts-expect-error set value to undefined to remove the dummy data.
                    setValue('site', undefined);
                    //@ts-expect-error set value to undefined to remove the dummy data.
                    setValue('dispatchDate', null);
                    setValue('accessTime', {
                      scheduling_type: 'Hard Start',
                      //@ts-expect-error set value to undefined to remove the dummy data.
                      start_date: undefined,
                    });
                  }}
                >
                  Clear all
                </GraniteButton>
              </div>
            </FormSection>
          </>
        )}
        {type === 'single' && (
          <>
            <Divider />
            <FormSection
              title="Dispatch date & time"
              gridClassName="grid-cols-2"
            >
              <Fragment>
                <div className="col-start-1">
                  <GraniteLabel
                    className="col-span-1"
                    label="Which day works best for you?"
                  >
                    <DispatchDateSelectorField
                      name={`dispatchDate`}
                      control={control}
                    />
                  </GraniteLabel>
                  <div className="mt-2 text-sm text-content-base-subdued">
                    Same day and next day dispatches will incur additional fees
                  </div>
                </div>
                <Controller
                  name="accessTime"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <GraniteLabel label="Access time" className="col-start-1">
                      <RadioButton
                        options={[
                          {
                            value: 'Hard Start',
                            label: 'Arrival at specified time',
                          },
                          {
                            value: 'Requested Window',
                            label: 'Arrival during dispatch window',
                          },
                        ]}
                        selectedValue={value?.scheduling_type}
                        onChange={(type) =>
                          onChange({ ...value, scheduling_type: type })
                        }
                        className="text-base"
                      />
                    </GraniteLabel>
                  )}
                />
                <div className="col-start-1">
                  <Controller
                    name="accessTime"
                    control={control}
                    render={({ field: { onChange, value, ref } }) =>
                      value.scheduling_type === 'Hard Start' ? (
                        <GraniteLabel
                          label="Specified time"
                          subtitle="Time will be automatically converted to the local timezone of the location"
                          className="col-start-1 col-end-2"
                        >
                          <TimePicker
                            onChange={(d) =>
                              onChange({ ...value, start_date: d })
                            }
                            value={value.start_date}
                            error={errors?.accessTime?.start_date?.message}
                            timeZoneAbbreviation={timeZoneAbbreviation}
                            ref={ref}
                          />
                        </GraniteLabel>
                      ) : (
                        <GraniteLabel
                          label="Dispatch window"
                          subtitle="Time will be automatically converted to the local timezone of the location"
                          className="col-start-1 col-end-2"
                        >
                          <RangeTimePicker
                            onStartChange={(d) =>
                              onChange({ ...value, start_date: d })
                            }
                            startValue={value?.start_date}
                            onEndChange={(d) =>
                              onChange({ ...value, end_date: d })
                            }
                            endValue={value?.end_date}
                            error={
                              (errors.accessTime &&
                                errors.accessTime.start_date?.message) ||
                              errors.accessTime?.message ||
                              undefined
                            }
                            timeZoneAbbreviation={timeZoneAbbreviation}
                            ref={ref}
                          />
                        </GraniteLabel>
                      )
                    }
                  />
                  <div className="mt-2 text-sm text-content-base-subdued">
                    Dispatches that fall outside of 8:00 AM - 5:00 PM on
                    Mon-Fri, as well as holidays and weekends, are considered
                    same-day dispatches.
                  </div>
                </div>
              </Fragment>
            </FormSection>
          </>
        )}
      </LetUsHelpBaseForm>
      <div className="sticky top-8 flex gap-4 rounded bg-background-base-surface-2 p-6 shadow">
        <GraniteButton
          variant="secondary"
          size="large"
          className="w-full"
          onClick={onCancel}
        >
          Cancel
        </GraniteButton>
        <GraniteButton
          className="w-full"
          size="large"
          type="submit"
          form="get-started-form"
          disabled={isSubmitting}
        >
          Next
        </GraniteButton>
      </div>
      <DuplicateTicketWarningDialog
        duplicates={duplicateModalProps.dynamicProps}
        {...duplicateModalProps}
      />
      {isBulkModalOpen && (
        <BulkModalLocations
          uploadLocations={uploadLocations}
          locations={locations}
          {...bulkModalProps}
          close={() => setIsBulkModalOpen(false)}
        />
      )}
      <ApplyTemplateModal
        {...applyTemplateModalProps}
        onApplyTemplate={onApplyTemplate}
      />
      <ClearTemplateConfirmationModal
        {...clearTemplateConfirmationModalProps}
        onConfirmation={onClearTemplateConfirm}
      />
      <CreateChildAccountModal
        {...createChildAccountModalProps}
        onAccountSubmit={(data: SiteAddress) => {
          setValue('site', data);
        }}
      />
    </LetUsHelpLayout>
  );
};

export const GetStartedFormWrapper = () => {
  const { dispatch, state, onCancel } = useLetUsHelpContext();
  const { running } = useProductTourContext();
  const questionnaireDefaults = {
    ...state.getStartedQuestionnaire,
    ...state.reviewForm,
  };

  if (state.state !== 'GetStarted' && !running) {
    let schema = GetStartedQuestionnaireFormSchema;
    if (questionnaireDefaults.type === 'multi') {
      const fieldsToModify = ['dispatchDate', 'accessTime.start_date'];
      schema = makeFieldsOptional(schema, fieldsToModify);
    }
    const questionnaire = schema.safeParse(questionnaireDefaults);

    if (questionnaire.success) {
      dispatch({
        type: 'GoToGetStarted',
        getStartedQuestionnaire: questionnaire.data,
      });
    } else {
      dispatch({ type: 'ResetForms' });
    }

    // Return Fragment for first-render, once dispatch triggers re-render we should get the proper form
    return <Fragment />;
  }

  return (
    <GetStarted
      dispatch={dispatch}
      questionnaire={state.getStartedQuestionnaire}
      template={state.template}
      onCancel={onCancel}
    />
  );
};
