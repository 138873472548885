import { createPaginatedResponseSchema } from 'api/common-schemas';
import { TokenUserPermissions, TokenUserRoles } from 'api/users/schemas/Users';
import { z } from 'zod';

export const CUSTOMER_DESCRIPTION_TYPES = z.enum([
  'Existing Customer',
  'Agent/TEM/Vendor',
  'Wholesale',
]);

export const SignUpSchema = z.discriminatedUnion('has_granite_service', [
  z.object({
    has_granite_service: z.literal(false),
    org_name: z.string().min(1, 'Company is required'),
  }),
  z.object({
    has_granite_service: z.literal(true),
    org_name: z.string().min(1, 'Company is required'),
    type: z.object({
      label: CUSTOMER_DESCRIPTION_TYPES,
      value: CUSTOMER_DESCRIPTION_TYPES,
    }),
  }),
]);

export type SignUpForm = z.infer<typeof SignUpSchema>;

export const BailOutSchema = z.object({
  macnum: z.string().min(1, 'Account # is required'),
});

export type BailOutForm = z.infer<typeof BailOutSchema>;

export const SignupResponseSchema = z.object({
  message: z.string(),
  status: z.string(),
  info: z.object({
    match: z.string(),
    org_created: z.boolean(),
    company_id: z.string(),
    ticket_id: z.number(),
    no_company: z.boolean(),
    data: z.object({
      email: z.string(),
      first_name: z.string(),
      last_name: z.string(),
      name: z.string(),
      org_name: z.string(),
      phone_number: z.string(),
      type: z.string(),
    }),
  }),
});

export type SignupResponse = z.infer<typeof SignupResponseSchema>;

const OktaGroupInfo = z.object({
  cw_links: z.array(
    z.object({
      id: z.preprocess((val) => Number(val), z.number()),
      macnum: z.string().nullish(),
      type: z.string(),
    }),
  ),
});

const OktaGroupSchema = z.object({
  id: z.number(),
  group_id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  display_name: z.string(),
  profile: z.object({
    name: z.string(),
    description: z.string().nullish(),
    display_name: z.string(),
    info: OktaGroupInfo.nullish(),
  }),
  cw_company_id: z
    .array(z.preprocess((val) => Number(val), z.number()))
    .nullish(),
});

export type OktaGroup = z.infer<typeof OktaGroupSchema>;

export const GetCompaniesResponseSchema =
  createPaginatedResponseSchema(OktaGroupSchema);

export type GetCompaniesResponse = z.infer<typeof GetCompaniesResponseSchema>;

export const GetCompaniesParamsSchema = z
  .object({
    search: z.string(),
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type GetCompaniesParams = z.infer<typeof GetCompaniesParamsSchema>;

export const LoginResponseSchema = z.object({
  user_id: z.string(),
  group_id: z.string(),
  roles: z.array(z.nativeEnum(TokenUserRoles)),
  permissions: z.array(z.nativeEnum(TokenUserPermissions)),
  super_admin: z.boolean().optional(),
});

export type LoginResponse = z.infer<typeof LoginResponseSchema>;

export type G360Token = LoginResponse & {
  group: OktaGroup;
};
