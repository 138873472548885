import { SiteAddress } from 'api/addresssearch/schema';
import { apiClient } from 'api/apiClient';
import { useAuthUser } from 'hooks/useAuthUser';
import { LocationType } from './schemas';
import { DeepPartial } from 'react-hook-form';
import { CreateQuoteResponse } from 'api/accessexpress/schema';

export const simpleFlowLocation = (
  location: SiteAddress,
): DeepPartial<LocationType[]> => {
  return [
    {
      address_line_1: location.address_line_1,
      address_line_2: location.address_line_2,
      state: location.state,
      city: location.city,
      zip: location.zip,
      products: [
        {
          product_type: 'Broadband',
          term: '3',
          min_download: 5,
          max_download: 1500,
          ip_type: 'DYNAMIC',
        },
      ],
    },
  ];
};

export const shapeQuoteRequest = (
  userInfo: ReturnType<typeof useAuthUser>,
  locations: DeepPartial<LocationType[]>,
  quoteName?: string,
) => {
  return {
    quote_name: quoteName,
    customer_name: userInfo.companyName ?? '',
    requester: userInfo.name,
    business_unit: 'standard',
    email: userInfo.email || '',
    locations: locations.map((location) => ({
      address1: location?.address_line_1,
      address2: location?.address_line_2,
      state: location?.state,
      city: location?.city,
      zip_code: location?.zip,
      products: location?.products?.map((product) => ({
        ...product,
        ...(product?.product_type === 'Broadband'
          ? { connection_type: product.connection_type || 'Cable' }
          : {}),
        min_download: product?.min_download?.toString(),
        max_download: product?.max_download?.toString(),
      })),
    })),
  };
};

export const createQuoteRequest = async (
  userInfo: ReturnType<typeof useAuthUser>,
  locations: DeepPartial<LocationType[]>,
  quoteName?: string,
) => {
  const payload = shapeQuoteRequest(userInfo, locations, quoteName);

  const response = await apiClient.post<CreateQuoteResponse>(
    '/api/v1/quotes/',
    payload,
  );
  return response.data;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
};
