import styled from 'styled-components';
// TABLE STYLES

export const DefaultTableContainer = styled.div<{
  $columnCount: number;
  $columnGap?: string;
  variant?: string;
}>`
  display: grid;
  grid-template-columns: ${({ $columnCount }) =>
    `repeat(${$columnCount}, minmax(max-content, 1fr))`};
  grid-template-rows: minmax(${({ variant }) => variant || '56px'}, auto);
  grid-auto-rows: minmax(${({ variant }) => variant || '56px'}, 1fr);
  column-gap: ${({ $columnGap }) => ($columnGap ? $columnGap : '16px')};
  justify-content: space-between;
  width: 100%;
  ${({ className }) => className};

  @media (max-width: 860px) {
    overflow-x: auto;
    width: 1100px;
  }
`;

export const TableRow = styled.div<{
  isFirstRow?: boolean;
  isLastRow?: boolean;
}>`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
  font-size: ${({ isFirstRow, theme }) =>
    isFirstRow ? theme.text.size.lg : theme.text.size.sm};

  &:not(:last-child) {
    border-bottom: 1px solid rgba(71, 85, 105, 0.5);
  }

  &:not(:first-child):hover {
    background-color: #222231;
    cursor: pointer;
  }
`;

export const TableTitle = styled.h2`
  margin-bottom: 32px;
  margin-top: 0;
  color: ${({ theme }) => theme.colors.text.white};
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 128.571% */
`;

export const TableHeaderCell = styled.div<{ $minWidth?: number }>`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.white};

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`;

export const TableCell = styled.div<{ $minWidth?: number }>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

// DISPLAY RANGE
export const DisplayRangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const DisplayLabel = styled.div`
  margin-right: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const StackedCellMainContent = styled.span`
  color: ${({ theme }) => theme.colors.text.white};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;
export const StackedCellSubtitle = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;
export const StackedCell = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NumericCell = styled.span<{ color?: 'primary' | 'white' }>`
  color: ${({ theme, color = 'white' }) => theme.colors.text[color]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  font-family:
    Fire Code,
    monospace;
  letter-spacing: 0.3199999928474426px;
  text-align: right;
`;
