import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { getQuoteBucketsRequest } from '../SelectServiceBundle/utils';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { PageTitleGranite } from 'components';
import Main from '../BaseComponents/MetaInfo/Main';
import Support from '../BaseComponents/MetaInfo/Support';
import LocationSection from '../BaseComponents/LocationSection';
import { SelectedTierInfo } from '../SelectServiceBundle/SelectServiceBundle';
import { useEffect, useState } from 'react';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import { format } from 'date-fns';
import BasicPagination, {
  useBasicPagination,
} from 'components/BasicPagination';
import clsx from 'clsx';
import ResultsLoading from '../BaseComponents/ResultsLoading';
import { QuoteStatusEnum } from 'api/accessexpress/schemas';
import Loader from 'components/Loader';
import { refreshQuote } from 'api/accessexpress/api';

const LOCATIONS_PER_PAGE = 5;

export const QuoteRequested = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedTiers, setSelectedTiers] = useState<{
    [key: string]: SelectedTierInfo;
  }>({});

  const { data, isLoading } = useQuery(
    ['access-express-quote-buckets', id],
    () => getQuoteBucketsRequest(id ?? ''),
    {
      enabled: !!id,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'AccessExpress',
      onClick: () => navigate('/access-express/index'),
    },
    {
      label: `#${data?.qe_quote_request_id}`,
      onClick: () => navigate(`/access-express/quote-details/${id}`),
    },
  ];

  const locationPricingTiers = data?.pricingTiers;
  const hasTwoProductTypes = data?.hasTwoProductTypes;

  const productTypesCount = hasTwoProductTypes ? 2 : 1;

  const { from, to, ...paginationProps } = useBasicPagination(
    locationPricingTiers?.length ?? 0,
    LOCATIONS_PER_PAGE * productTypesCount,
  );

  useEffect(() => {
    if (locationPricingTiers) {
      const initialSelectedTiers = locationPricingTiers.reduce(
        (acc, loc) => {
          const advancedTier = loc.pricing_tiers.find((tier) => tier.selected);
          acc[loc.id] = {
            tier: advancedTier ? { ...advancedTier } : null,
            sendMePricing: loc.sendMePricing,
          };
          return acc;
        },
        {} as { [key: string]: SelectedTierInfo },
      );
      setSelectedTiers(initialSelectedTiers);
    }
  }, [locationPricingTiers]);

  const mainProps = {
    includedProducts: [
      ...new Set(data?.pricingTiers.map((tier) => tier.product_type)),
    ].join(', '),
    locations: data?.locations.length.toString() ?? '0',
    dateSubmitted: data?.qe_quote_created_at
      ? format(new Date(data?.qe_quote_created_at), 'MM/dd/yyyy')
      : '',
    submittedBy: data?.requester ?? '',
    title: data?.status,
    statusColor:
      data?.status === QuoteStatusEnum.enum['Closed']
        ? 'bg-neutral-400'
        : data?.status === QuoteStatusEnum.enum['Resubmit required']
          ? 'bg-blue-500'
          : 'bg-status-info-default',
  };

  const description =
    data?.status === QuoteStatusEnum.enum['Closed']
      ? data?.errorMessage
        ? data?.errorMessage === 'No service options'
          ? 'This quote has been auto-closed because no services were available for the specified location and service requirements.'
          : 'This quote has been auto-closed due to an internal error. Please reach out to your Granite account team to for assistance with this quote request.'
        : 'A Granite representative will be contact you with more information about the available options at your locations'
      : data?.status === QuoteStatusEnum.enum['Resubmit required']
        ? 'An internal error occurred. Please resubmit your request to receive a formal quote.'
        : "A formal quote is being prepared. Once it's ready, you will be notified to review pricing and select services.";

  const refreshQuoteMutation = useMutation(refreshQuote, {
    onSuccess: () => {
      navigate('/access-express/index');
    },
  });

  const onRefreshQuote = () => {
    if (refreshQuoteMutation.isLoading) return;
    id && refreshQuoteMutation.mutate(id);
  };

  return (
    <ContentLayout>
      <PageTitleGranite
        breadcrumbs={breadcrumbs}
        title={
          <div className="flex items-center justify-center gap-6">
            <h1 className="text-4xl font-bold text-content-base-default">
              {data?.quote_name}
            </h1>
            <div className="rounded-lg bg-blue-600 px-4 py-[6px]">
              <span className="h-auto text-center text-base font-bold text-content-base-default">
                QR #{data?.qe_quote_request_id}
              </span>
            </div>
          </div>
        }
      />
      <div className="mt-6 grid grid-cols-5 items-end gap-1">
        <WizardStateBlock
          label="Add locations"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Choose services"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Select service bundle"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Request quote"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Submit order"
          isActive={false}
          isCompleted={false}
        />
      </div>
      <div className="mt-12">
        <Main {...mainProps} />
        <Support description={description} />
      </div>
      <div
        className={clsx(
          'mt-8 grid grid-cols-1 items-start gap-8 lg:grid-cols-[2fr_minmax(0,_502px)]',
          hasTwoProductTypes && '!grid-cols-none',
        )}
      >
        <div className="flex flex-col gap-8 rounded bg-background-base-surface-2 p-6 shadow">
          <div className="flex w-full flex-col items-start justify-start">
            <h2 className="mb-2 text-2xl font-bold text-content-base-default">
              You may review the details of this quote request but you cannot
              make adjustments at this time.{' '}
            </h2>
          </div>
          {isLoading && (
            <div
              className={clsx('gap-4 rounded bg-background-base-surface-1 p-4')}
            >
              <ResultsLoading />
            </div>
          )}
          {/* <div className="pointer-events-none flex gap-6"> */}
          <div
            className={clsx(
              hasTwoProductTypes
                ? '2xl:-mx-4 2xl:-my-4 2xl:flex 2xl:flex-wrap'
                : '',
            )}
          >
            {locationPricingTiers?.slice(from, to).map((location, index) => {
              return (
                <div
                  key={location.id}
                  className={clsx(
                    'py-4',
                    hasTwoProductTypes && '2xl:w-1/2 2xl:px-4',
                  )}
                >
                  <LocationSection
                    mode="view"
                    key={location.id}
                    location={location}
                    onSelectedTier={console.log}
                    selectedTier={selectedTiers[location.id]?.tier}
                    pricingAvailable={true}
                    isMoreOptionsSelected={
                      selectedTiers[location.id]?.sendMePricing
                    }
                    defaultCollapsed={
                      paginationProps.currentPage > 1 ||
                      index >= productTypesCount
                    }
                    warningMsg="This service has been excluded from the quote request because a package was not selected."
                  />
                </div>
              );
            })}
          </div>
          <BasicPagination {...paginationProps} />
        </div>
        <div
          className={clsx(
            '',
            !hasTwoProductTypes &&
              'sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow',
          )}
        >
          {data?.status === QuoteStatusEnum.enum['Resubmit required'] ? (
            <GraniteButton size="large" onClick={onRefreshQuote}>
              Resubmit quote request
              {refreshQuoteMutation.isLoading && (
                <Loader animationClassname="!w-3 !h-3" />
              )}
            </GraniteButton>
          ) : (
            <GraniteButton size="large" onClick={console.log} disabled={true}>
              {data?.status === QuoteStatusEnum.enum['Closed']
                ? 'Closed'
                : 'Quote requested'}
            </GraniteButton>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};
