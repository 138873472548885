import { OptionType } from 'components/Select/Select';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CaretDown } from 'react-ionicons';
import checkMark from 'assets/images/checkMark.svg';
import { ErrorSubtext } from 'components/ErrorSubtext/ErrorSubtext';

interface CustomSelectProps {
  options: OptionType[];
  error?: string | boolean;
  selectedValue: OptionType;
  onChange: (option: OptionType) => void;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  error,
  selectedValue,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const selectRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    if (!isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const container = document.querySelector('.bulk-modal-locations');
      if (container) {
        const containerRect = container.getBoundingClientRect();
        setMenuPosition({
          top: rect.bottom - containerRect.top,
          left: rect.left - containerRect.left,
          width: rect.width,
        });
      }
    }
    setIsOpen(!isOpen);
  };

  const portalContainer = document.querySelector(
    '.bulk-modal-locations',
  ) as Element;

  const handleOptionClick = (option: OptionType) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div ref={selectRef} className="relative">
      <div
        ref={buttonRef}
        className="ml-[128px] flex h-[48px] w-full !max-w-[246px] cursor-pointer items-center justify-between rounded border border-input-stroke-filled bg-input-background-filled px-4 py-3 text-input-content-filled hover:border-input-stroke-hover"
        onClick={handleToggleDropdown}
      >
        {selectedValue.label}
        <span
          className={`fill-content-base-default ${isOpen ? 'rotate-180' : ''}`}
        >
          <CaretDown color={'inherit'} width={'12px'} height={'12px'} />
        </span>
      </div>
      {isOpen &&
        portalContainer &&
        createPortal(
          <ul
            style={{
              position: 'absolute',
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              width: `${menuPosition.width}px`,
              zIndex: 1000,
            }}
            className="z-[9999] max-h-60 overflow-auto overflow-x-hidden rounded-bl-md rounded-br-md border border-b border-l border-r border-input-stroke-filled bg-input-background-filled"
          >
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className="flex w-full cursor-pointer items-center justify-between p-2.5 text-left text-[16px] font-[300] text-input-content-filled duration-200 hover:scale-[1.01] hover:bg-background-base-surface-2  hover:transition-transform"
              >
                {option.label}
                {option.value === selectedValue.value && (
                  <img src={checkMark} alt="Checkmark" />
                )}
              </li>
            ))}
          </ul>,
          portalContainer,
        )}
      {error && <ErrorSubtext error={error} />}
    </div>
  );
};
