import {
  Control,
  FieldErrors,
  UseFormRegister,
  useFieldArray,
} from 'react-hook-form';
import { GraniteInput } from '../../../components/V2/Input/GraniteInput';
import React, { Fragment } from 'react';
import { ContactSubForm } from '../utils/schemas';
import PhoneNumberInput from '../../../components/V2/Input/PhoneNumber';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { Add } from 'react-ionicons';

export interface FormWithContact {
  itTestingContacts: ContactSubForm[];
}

export interface ContactSubFormProps {
  errors: FieldErrors<FormWithContact>;
  register: UseFormRegister<FormWithContact>;
  control: Control<FormWithContact>;
}

export const TicketDetailContactForm = ({
  control,
  errors,
  register,
}: ContactSubFormProps) => {
  const {
    fields: contactsFields,
    append: contactsAppend,
    remove: contactsRemove,
  } = useFieldArray({
    control,
    name: 'itTestingContacts',
  });

  const MAX_CONTACTS_LENGTH = 5;

  return (
    <Fragment>
      {contactsFields.map((field, index) => (
        <div key={index} className="col-span-2 grid grid-cols-2 gap-4">
          <h1 className="text-xl text-content-base-subdued">
            Testing contact {index + 1}
          </h1>
          <GraniteInput
            label="Contact name"
            className="col-span-1 col-start-1"
            error={errors.itTestingContacts?.[index]?.contactName?.message}
            {...register(`itTestingContacts.${index}.contactName`)}
          />
          <PhoneNumberInput
            name={`itTestingContacts.${index}.contactNumber`}
            control={control}
            label="Contact phone"
            error={errors.itTestingContacts?.[index]?.contactNumber?.message}
          />
          <GraniteInput
            label="Contact email (optional)"
            className="col-span-1"
            error={errors.itTestingContacts?.[index]?.contactEmail?.message}
            {...register(`itTestingContacts.${index}.contactEmail`)}
          />
          {index > 0 && index < MAX_CONTACTS_LENGTH ? (
            <div className="col-span-2">
              <GraniteButton
                variant="destructive"
                onClick={() => contactsRemove(index)}
              >
                Delete
              </GraniteButton>
            </div>
          ) : null}
        </div>
      ))}
      {contactsFields.length < MAX_CONTACTS_LENGTH ? (
        <div className="col-span-2">
          <GraniteButton
            variant="secondary"
            className="col-span-full mt-2"
            onClick={() =>
              contactsAppend({ contactName: '', contactNumber: '' })
            }
          >
            Add another testing contact
            <Add color="inherit" width="16px" height="16px" cssClasses="ml-2" />
          </GraniteButton>
        </div>
      ) : null}
    </Fragment>
  );
};
