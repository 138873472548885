import { TokenUserRoles } from 'api/users/schemas/Users';
import { DisplayForRole } from 'components/DisplayForRole/DisplayForRole';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import DevicesForm from './DevicesForm';
import { FormProvider, useForm } from 'react-hook-form';
import { ContactGroupType, DeviceFormType, DeviceSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect } from 'react';
import { patchDevicesContactGroup } from '../../utils';
import { useMutation } from 'react-query';
import showToast from 'components/Toast/Toast';

interface DeviceEditFormProps {
  defaultValues?: Pick<ContactGroupType, 'devices' | 'id'>;
  close: () => void;
  refetchOnSuccess: () => void;
}

const DeviceEditForm: FC<DeviceEditFormProps> = ({
  defaultValues,
  close,
  refetchOnSuccess,
}) => {
  const { reset, ...methods } = useForm<DeviceFormType>({
    resolver: zodResolver(DeviceSchema),
    defaultValues: {
      devices: defaultValues?.devices.map((e) => ({ id: e.id })) || [],
    },
  });

  useEffect(() => {
    reset({
      devices: defaultValues?.devices.map((e) => ({ id: e.id })) || [],
    });
  }, [defaultValues, reset]);

  const { mutate: editDevicesContactGroupMutate } = useMutation(
    patchDevicesContactGroup,
    {
      onSuccess: () => {
        refetchOnSuccess();
        close();
        showToast.confirmation({ message: 'Edited' });
      },
      onError: () => {
        close();
        showToast.error({ message: 'Failed to edited' });
      },
    },
  );

  const onSubmit = (data: DeviceFormType) => {
    if (defaultValues)
      editDevicesContactGroupMutate({
        id: defaultValues?.id,
        devices: data.devices?.map((e) => Number(e.id)) || [],
      });
  };

  return (
    <FormProvider {...methods} reset={reset}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DevicesForm />
        <div className="mt-12 flex gap-5">
          <GraniteButton variant="secondary" size="large" onClick={close}>
            Cancel
          </GraniteButton>
          <DisplayForRole roles={[TokenUserRoles.COMPANY_ADMIN]}>
            <GraniteButton size="large" type="submit">
              Save Changes
            </GraniteButton>
          </DisplayForRole>
        </div>
      </form>
    </FormProvider>
  );
};

export default DeviceEditForm;
