import { ColumnDef } from '@tanstack/react-table';
import Divider from 'components/Divider';
import Loader from 'components/Loader';
import PageTitle from 'components/PageTitle';
import Tag from 'components/Tag';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  EditRoleFormType,
  PermissionType,
} from 'screens/RolesPermissions/roles-permissions.types';
import { getRoleById, putRole } from 'screens/RolesPermissions/utils';
import Form from './Form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditRoleSchema } from './schema';
import { useEffect, useState } from 'react';
import showToast from 'components/Toast/Toast';
import AddPermissionsToRole from './AddPermissionsToRole';
import PermissionTableDetails from '../details/PermissionTableDetails';
import UnsavedChangeModal from './UnsavedChangeModal';

const EditRole = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<PermissionType[]>([]);

  const { data, isLoading } = useQuery(
    [`role-edit`, id],
    () => getRoleById(id as string),
    { refetchOnMount: true },
  );

  const { reset, control, setValue, ...methods } = useForm<EditRoleFormType>({
    resolver: zodResolver(EditRoleSchema),
  });

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'User management',
      onClick: () => navigate('/users'),
    },
    {
      label: 'Role management',
      onClick: () => navigate('/users/roles'),
    },
    ...(data?.name ? [{ label: data.name }] : []),
  ];

  const onRemovePermission = (permission: PermissionType) => {
    setPermissions((prev) => {
      const filteredPermissions = prev.filter(
        (item) => item.id !== permission.id,
      );
      setValue(
        'permissions',
        filteredPermissions.map((item) => item.id),
        { shouldDirty: true },
      );
      return filteredPermissions;
    });
  };

  const extraColumns: ColumnDef<PermissionType>[] = [
    {
      header: 'Actions',
      cell: (row) => (
        <GraniteButton
          size="small"
          variant="destructive"
          className="mr-1"
          onClick={() => onRemovePermission(row.row.original)}
        >
          Remove
        </GraniteButton>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        description: data.description,
        permissions: data.permissions?.map((p) => p.id),
      });
      setPermissions(data.permissions);
    }
  }, [data, reset]);

  const queryClient = useQueryClient();

  const { mutate, isLoading: putLoading } = useMutation(putRole, {
    onSuccess: (data) => {
      showToast.confirmation({
        title: 'Role updated!',
        message: `“${data?.name}”`,
      });
      queryClient.refetchQueries('roles-index-table');
      queryClient.refetchQueries([`role-edit`, id]);
      queryClient.refetchQueries([`role-details`, id]);
      navigate('..', { replace: true });
    },
  });

  const onSubmit = (data: EditRoleFormType) => id && mutate({ id, ...data });

  if (isLoading) return <Loader />;
  if (data)
    return (
      <ContentLayout>
        <FormProvider
          {...methods}
          reset={reset}
          control={control}
          setValue={setValue}
        >
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mb-2 flex items-center justify-between gap-2">
              <PageTitle title={data?.name} breadcrumbs={breadcrumbs} />
              <div className="flex gap-4">
                <UnsavedChangeModal isDirty={methods.formState.isDirty} />
                <GraniteButton
                  size="large"
                  variant="primary"
                  type="submit"
                  disabled={putLoading}
                >
                  Save changes
                </GraniteButton>
              </div>
            </div>
            <div className="flex items-center gap-2.5">
              <p className="text-xl font-bold text-neutral-500">Role ID:</p>
              <Tag
                content={{ type: 'label', label: `${data.id}` }}
                status="neutral"
                style="default"
              />
            </div>

            <div className="flex h-full flex-col flex-wrap justify-between overflow-hidden pt-12 xl:flex-row">
              <div className="w-full rounded-tl rounded-tr bg-blue-700 px-8 py-6 xl:max-w-[564px] xl:rounded-bl xl:rounded-tr-none">
                <p className="mb-8 text-2xl font-bold text-white">
                  Role details
                </p>
                <Form />
              </div>
              <div className="flex-1 rounded-bl rounded-br bg-blue-800 px-8 py-6 xl:rounded-bl-none  xl:rounded-tr">
                <p className="text-2xl font-bold text-white">Permissions</p>
                <div className="mt-8 w-full">
                  <PermissionTableDetails
                    data={permissions}
                    extraColumns={extraColumns}
                  />
                  <Controller
                    name="permissions"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <AddPermissionsToRole
                        defaultValue={value ?? []}
                        onSave={(data) => {
                          onChange([
                            ...(value ?? []),
                            ...data.map((p) => p.id),
                          ]);
                          setPermissions((prev) => [...prev, ...data]);
                        }}
                      />
                    )}
                  />
                </div>
                <Divider className="my-16" />
                <div className="mb-16">
                  <p className="text-2xl font-bold text-white ">Delete role</p>
                  <p className="mb-2 mt-4 font-bold">
                    This action cannot be undone. Once deleted, this role will
                    be permanently removed.
                  </p>
                  <GraniteButton
                    variant="destructive"
                    onClick={() =>
                      navigate(`delete/${data.name}`, { replace: true })
                    }
                  >
                    Delete role
                  </GraniteButton>
                </div>
              </div>
            </div>
          </form>
          <Outlet />
        </FormProvider>
      </ContentLayout>
    );
};

export default EditRole;
