import { useEffect } from 'react';
import { SortEnd, arrayMove } from 'react-sortable-hoc';
import Divider from 'components/Divider';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ChevronForward, Cog } from 'react-ionicons';
import { CustomizeTHeadReturn } from './customize.types';
import Loader from 'components/Loader';
import SortableList from './Sortable';

interface CustomizeProps<T extends object>
  extends Pick<CustomizeTHeadReturn<T>, 'customizeProps'> {}

// CustomizeCustomizeCustomizeCustomize

const Customize = <T extends object>({
  onClick,
  open,
  preferences,
  setPreferences,
  isLoading,
  onToggle,
  restore,
}: CustomizeProps<T>['customizeProps']) => {
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    setPreferences((prevItems) => arrayMove(prevItems, oldIndex, newIndex));
  };

  useEffect(() => {
    if (open) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open]);

  return (
    <>
      <GraniteButton
        className="!box-border md:max-w-[175px]"
        variant="secondary"
        size="large"
        onClick={onClick}
      >
        Customize
        <Cog />
      </GraniteButton>
      <div
        className={`fixed inset-0 w-full ${
          open
            ? 'h-full bg-background-base-overlay opacity-100'
            : 'h-0 opacity-0'
        } z-40`}
        onClick={onClick}
      />
      <div
        className={`fixed right-0 top-0 z-50 transition-transform duration-200 ease-in-out ${
          open ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex h-screen max-h-screen w-[375px] max-w-[375px] flex-col gap-8 bg-background-base-surface-2 px-4 py-8 shadow-side-modal">
          <div className="flex w-full justify-between px-2">
            <h4 className="text-2xl font-bold text-white">
              Custom table settings
            </h4>
            <span className="cursor-pointer text-white" onClick={onClick}>
              <ChevronForward width="24px" height="24px" color="currentColor" />
            </span>
          </div>
          <p className="px-2 font-semibold  text-neutral-500">
            Personalize your table view by enabling, disabling, or rearranging
            columns to suit your needs.
          </p>
          <GraniteButton
            variant="secondary"
            size="small"
            className="mx-2 w-max"
            onClick={restore.onRestore}
            disabled={restore.isLoading || isLoading}
          >
            Restore default
          </GraniteButton>
          <Divider className="mx-2 -mt-4 " />
          {isLoading && <Loader />}
          {preferences.length ? (
            <SortableList
              onToggle={onToggle}
              preferences={preferences}
              onSortEnd={onSortEnd}
              helperClass="z-50 !pointer-events-auto"
              distance={0}
              axis="y"
              lockAxis="y"
              useDragHandle
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Customize;
