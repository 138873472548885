import { FC, PropsWithChildren, useEffect } from 'react';
import { apiClient } from '../../api/apiClient';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getErrorResponse } from './util';
import { AccessToken } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

export const AxiosInterceptor: FC<PropsWithChildren> = ({ children }) => {
  const { oktaAuth } = useOktaAuth();
  useEffect(() => {
    const interceptorRequest = apiClient.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        const controller = new AbortController();
        try {
          const token = (await oktaAuth.tokenManager.get(
            'accessToken',
          )) as AccessToken;
          config.headers['Authorization'] = `Bearer ${token.accessToken}`;
          const g360Token = JSON.parse(
            localStorage.getItem('g360-token-storage') ?? '{}',
          );
          config.headers['group-id'] = g360Token.group_id;
        } catch (error) {
          controller.abort();
          await oktaAuth.signOut();
        }
        return { ...config, signal: controller.signal };
      },
    );

    const interceptorResponse = apiClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.reason === 'token is expired'
        ) {
          return oktaAuth.signOut();
        }
        return getErrorResponse(error);
      },
    );

    return () => {
      apiClient.interceptors.request.eject(interceptorRequest);
      apiClient.interceptors.response.eject(interceptorResponse);
    };
  }, [oktaAuth]);

  return <>{children}</>;
};
