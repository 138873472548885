import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { BackgroundTypeBox } from './BackgroundTypeBox';
import { FileTrayFull, ChatbubbleEllipses, ArrowUp } from 'react-ionicons';
import EmptyState from 'components/Notifications/EmptyState';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { SettingsSvgIcon } from './SettingsSvgIcon';
import Card from 'components/Notifications/Card';
import Switch from 'components/Switch';
import { useNotifications } from 'hooks/useNotifications';
import { NotificationType, Pillar } from 'components/Notifications/utils';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { CardSkeleton } from 'components/Notifications/CardSkeleton';
import { useFeatureFlags } from 'feature-flags';
import clsx from 'clsx';
import { getAllowedPillarByCategory } from './utils';
import { useAuthUser } from 'hooks/useAuthUser';

const BACK_TO_TOP_DISPLAY_THRESHOLD = 7;

const notificationTypeOptions = [
  { name: 'all', Icon: FileTrayFull, label: 'All messages' },
  { name: 'comment', Icon: ChatbubbleEllipses, label: 'Comments' },
] as const;

export const NotificationsPage = () => {
  const { flags } = useFeatureFlags();
  const { roles: userRoles } = useAuthUser();
  const navigate = useNavigate();
  const loadMoreRef = useRef(null);
  const [activePillar, setActivePillar] = useState<Pillar>('all');
  const [activeNotificationType, setActiveNotificationType] =
    useState<NotificationType>('all');
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);
  const [displayScrollToTop, setDisplayScrollToTop] = useState(false);
  const allowedPillarByCategory = useMemo(
    () => getAllowedPillarByCategory(userRoles),
    [userRoles],
  );

  const {
    notifications,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    categories,
    markAllAsRead,
    markNotificationAsRead,
    notificationsRead,
  } = useNotifications(
    {
      onlyUnread,
      notificationType: activeNotificationType,
      pillar: activePillar,
    },
    {
      enabled: flags.NOTIFICATIONS_ENABLED,
    },
  );

  const handleNotificationTypeChange = (name: NotificationType) =>
    setActiveNotificationType(name);
  const handlePillarChange = (name: Pillar) => setActivePillar(name);

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'Home',
      onClick: () => navigate('/'),
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage(); // Fetch the next page when the end is reached
      }
    });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (!notifications || notifications.length < BACK_TO_TOP_DISPLAY_THRESHOLD)
      return;
    const observer = new IntersectionObserver((entries) => {
      const scrolledElementsCount = entries.reduce((max, entry) => {
        if (entry.target instanceof HTMLElement && entry.intersectionRatio > 0)
          return Math.max(
            max,
            parseInt(entry.target.dataset.index as string, 10) + 1,
          );
        return max;
      }, 0);
      if (scrolledElementsCount)
        setDisplayScrollToTop(
          scrolledElementsCount >= BACK_TO_TOP_DISPLAY_THRESHOLD,
        );
    });

    document
      .querySelectorAll<HTMLElement>('.notification-page-card-item')
      .forEach((element, index) => {
        element.dataset.index = index.toString(); // Set a data attribute with the index
        observer.observe(element);
      });

    return () => {
      observer.disconnect();
    };
  }, [notifications]);

  return (
    <ContentLayout className="relative flex gap-x-20 gap-y-12 sm:!pb-0 sm:!pt-0">
      <div className="left-0 top-0 flex max-h-screen flex-col overflow-hidden sm:sticky">
        <div className="mb-12 pt-8">
          <Breadcrumb breadcrumbs={breadcrumbs} />
          <h1 className="text-4xl font-bold text-content-base-default">
            Notifications
          </h1>
        </div>
        <div className="col-span-2 flex w-full flex-col gap-6 overflow-y-auto">
          <div className="flex flex-col gap-2">
            <GraniteLabel className="font-bold">Message type</GraniteLabel>
            {notificationTypeOptions.map(({ name, Icon, label }) => (
              <BackgroundTypeBox
                key={name}
                name={name}
                isActive={activeNotificationType === name}
                handleClick={() => handleNotificationTypeChange(name)}
              >
                <div
                  className={clsx(
                    'hover:filter-content-accent-default flex items-center gap-2',
                    activeNotificationType === name
                      ? 'fill-content-accent-default'
                      : 'fill-content-base-default',
                  )}
                >
                  {Icon && (
                    <Icon color={'inherit'} height={'18px'} width={'21px'} />
                  )}
                  <p
                    className={`font-bold ${
                      activeNotificationType === name
                        ? 'text-content-accent-default'
                        : 'text-content-base-default'
                    }`}
                  >
                    {label}
                  </p>
                </div>
              </BackgroundTypeBox>
            ))}
          </div>
          {Object.entries(allowedPillarByCategory).map(
            ([category, options]) => (
              <div key={category} className="flex flex-col gap-2">
                <GraniteLabel className="font-bold">{category}</GraniteLabel>
                {options.map(({ name, Icon, label }) => (
                  <BackgroundTypeBox
                    key={name}
                    name={name}
                    isActive={activePillar === name}
                    handleClick={() => handlePillarChange(name)}
                  >
                    <div
                      className={clsx(
                        'hover:filter-content-accent-default flex items-center justify-start gap-2',
                        activePillar === name
                          ? 'fill-content-accent-default'
                          : 'fill-content-base-default',
                      )}
                    >
                      {Icon && <Icon />}
                      {!Icon && (
                        <p
                          className={`font-bold ${
                            activePillar === name
                              ? 'text-content-accent-default'
                              : 'text-content-base-default'
                          }`}
                        >
                          {label}
                        </p>
                      )}
                    </div>
                  </BackgroundTypeBox>
                ))}
              </div>
            ),
          )}
        </div>
        <div className="py-4">
          <Link
            to="/notifications/preferences"
            className="button secondary medium flex items-center justify-start gap-2 fill-content-base-default hover:fill-button-content-secondary-hover focus:!fill-content-base-default"
          >
            Notification preferences
            <SettingsSvgIcon />
          </Link>
        </div>
      </div>
      <div className="flex max-w-3xl grow flex-col">
        <div className="top-0 bg-background-base-surface-1 pt-8 sm:sticky">
          <div className="flex justify-between">
            <p className="text-sm font-bold text-content-base-subdued">Today</p>
            <div className="flex items-center justify-start gap-[34px]">
              <p
                onClick={() => markAllAsRead(notificationsRead, activePillar)}
                className="cursor-pointer text-sm font-semibold text-content-base-default hover:text-content-accent-default"
              >
                {notificationsRead ? 'Mark all as unread' : 'Mark all as read'}
              </p>
              <div className="flex justify-between gap-2">
                <Switch
                  isOn={onlyUnread}
                  label="Only show unread"
                  onChange={() => {
                    setOnlyUnread((prevState) => !prevState);
                  }}
                  size="small"
                  labelClassName="!text-sm !mr-2 !text-content-base-default !font-medium"
                />
              </div>
            </div>
          </div>
          <hr className="my-6 h-[1px] border-stroke-base-default" />
        </div>
        {isLoading ? (
          <div className="flex grow flex-col gap-6">
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </div>
        ) : notifications && notifications.length > 0 ? (
          <div className="flex grow flex-col gap-6">
            {categories.map((category, index) => (
              <div className="flex flex-col gap-2" key={index}>
                {index !== 0 && (
                  <div className="flex justify-between">
                    <p className="text-sm font-bold text-content-base-subdued">
                      {category.name}
                    </p>
                  </div>
                )}
                <div className="flex flex-col gap-4">
                  {category.items.map((notification) => (
                    <Card
                      className="notification-page-card-item"
                      key={notification.id}
                      markAsRead={markNotificationAsRead}
                      notification={notification}
                    />
                  ))}
                </div>
              </div>
            ))}
            {hasNextPage && <CardSkeleton ref={loadMoreRef} />}
          </div>
        ) : (
          <div className="grow">
            <EmptyState />
          </div>
        )}
        <div
          className={clsx(
            'bottom-0 flex transform items-center justify-start gap-6 bg-background-base-surface-1 transition-transform duration-300 sm:sticky',
            (isLoading ||
              (notifications &&
                notifications?.length < BACK_TO_TOP_DISPLAY_THRESHOLD)) &&
              'hidden',
            !displayScrollToTop
              ? 'translate-y-[120%] pb-4'
              : 'translate-y-0 py-4',
          )}
        >
          <GraniteButton
            variant="secondary"
            className={clsx(
              'flex items-center justify-start gap-2 fill-content-base-default',
            )}
            size="medium"
            onClick={scrollToTop}
          >
            Back to top
            <ArrowUp color="inherit" width="18px" height="18px" />
          </GraniteButton>
        </div>
      </div>
    </ContentLayout>
  );
};
