import { PageTitleGranite } from 'components';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import Metrics from './Metrics';
import RecentActivity from './RecentActivity';
import {
  filterByCompletionDate,
  filterByEndDate,
  recentActivities,
  serviceTypeOptions,
  statusOptions,
  vendorOptions,
} from './utils';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableTitle,
} from 'components/Table/Table.styles';
import { ReactNode, useCallback, useState } from 'react';
import Tabs from 'components/Table/Tabs/Tabs';
import { ServerPaginatedTable } from 'components/Table/ServerPaginatedTable';
import Searchbar from 'components/Table/SearchBar';
import { InventoryItem } from 'api/inventory/schema';
import { useCustomizeTHead } from 'hooks/useCustomizeTHead';
import { ColumnDef } from '@tanstack/react-table';
import { useSortedTable } from 'hooks/useSortedTable';
import { setCookie } from 'shared/util/util';
import {
  getInventoryIndex,
  getServicesPreferenceMock,
} from 'api/inventory/api';
import { usePaginatedTable } from 'hooks/usePaginatedTable';
import { EllipsisHorizontal, FileTray } from 'react-ionicons';
import Customize from 'components/Table/Customize/Customize';
import { TicketIdBadge } from 'screens/TechExpress/TechExpress.styles';
import clsx from 'clsx';
import { useFilterForTable } from 'hooks/useFilterForTable';
import Filters from 'components/Filters/Filters';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { Chip } from 'components/Chip/Chip';
import { SingleValue } from 'react-select';

const EmptyInventory = () => {
  return (
    <div className="col-span-full flex flex-col items-center gap-6 bg-background-base-surface-2 p-8">
      <FileTray
        color="rgb(var(--content-base-subdued))"
        width="48px"
        height="48px"
      />
      <p className="rounded font-bold leading-[22px] text-content-base-default">
        You don&apos;t have any inventory yet.
      </p>
    </div>
  );
};

const Inventory = () => {
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState<'all' | 'active' | 'complete'>(
    'all',
  );

  const tableHeaders: ColumnDef<InventoryItem>[] = [
    {
      id: 'parent_name',
      header: 'Parent Name',
      accessorKey: 'parent_name',
      enableSorting: true,
    },
    {
      id: 'parent_number',
      header: 'Parent #',
      accessorKey: 'parent_number',
      enableSorting: true,
    },
    {
      id: 'account_number',
      header: 'Account #',
      accessorKey: 'account_number',
      enableSorting: true,
      cell: (row) => (
        <TicketIdBadge>{row.getValue() as ReactNode}</TicketIdBadge>
      ),
    },
    {
      id: 'account_name',
      header: 'Account name',
      accessorKey: 'account_name',
      enableSorting: true,
    },
    {
      id: 'address',
      header: 'Address',
      accessorKey: 'address',
      enableSorting: false,
      cell: (cellCtx) => {
        const address = [
          cellCtx.row.original.city,
          cellCtx.row.original.state,
          cellCtx.row.original.zip,
        ].filter(Boolean);
        const singleSiteAddress =
          address.length > 0 ? address.join(', ') : '-- -- --';

        return (
          <StackedCell className="w-full">
            <StackedCellMainContent>
              {cellCtx.row.original.address_1}
            </StackedCellMainContent>
            <StackedCellSubtitle>{singleSiteAddress}</StackedCellSubtitle>
          </StackedCell>
        );
      },
    },
    {
      id: 'service_type',
      header: 'Service type',
      accessorKey: 'service_type',
      enableSorting: true,
    },
    {
      id: 'vendor',
      header: 'Vendor',
      accessorKey: 'vendor',
      enableSorting: true,
    },
    {
      id: 'service_id',
      header: 'Service ID',
      accessorKey: 'service_id',
      enableSorting: true,
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      enableSorting: true,
      cell: (row) => (
        <p>
          <span
            className={clsx(
              'mr-2 inline-flex h-2.5 w-2.5 rounded-full',
              row.row.original.status === 'Active' && 'bg-green-400',
              row.row.original.status === 'Inactive' &&
                'bg-status-error-default',
            )}
          />
          {row.row.original.status}
        </p>
      ),
    },
    {
      id: 'completion_date',
      header: 'Completion date',
      accessorKey: 'completion_date',
      enableSorting: true,
    },
    {
      id: 'end_date',
      header: 'End date',
      accessorKey: 'end_date',
      enableSorting: true,
    },
    {
      id: 'btn',
      header: 'BTN',
      accessorKey: 'btn',
      enableSorting: true,
      cell: (row) =>
        row.row.original.btn ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'serial_number',
      header: 'Serial number',
      accessorKey: 'serial_number',
      enableSorting: true,
    },
    {
      id: 'mac_address',
      header: 'MAC address',
      accessorKey: 'mac_address',
      enableSorting: true,
    },
    {
      id: 'ip_address',
      header: 'IP address',
      accessorKey: 'ip_address',
      enableSorting: true,
    },
    {
      id: 'model_number',
      header: 'Model #',
      accessorKey: 'model_number',
      enableSorting: true,
    },
    {
      id: 'tag_number',
      header: 'Tag #',
      accessorKey: 'tag_number',
      enableSorting: true,
      cell: (row) =>
        row.row.original.tag_number ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'product_offering',
      header: 'Product offering',
      accessorKey: 'product_offering',
      enableSorting: true,
    },
    {
      id: 'host_status',
      header: 'Host status',
      accessorKey: 'host_status',
      enableSorting: true,
      cell: (row) =>
        row.row.original.host_status ?? (
          <span className="text-sm text-content-base-subdued">N/A</span>
        ),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: () => (
        <EllipsisHorizontal width={'14px'} height={'14px'} color={'#F8FAFC'} />
      ),
      meta: {
        align: 'center',
      },
    },
  ];

  const [tableData, setTableData] = useState<InventoryItem[]>([]);
  const [pageSize, setPageSize] = useState<number>();

  const [completionDateFilter, setCompletionDateFilter] =
    useState<SingleValue<OptionType>>(null);
  const [endDateFilter, setEndDateFilter] =
    useState<SingleValue<OptionType>>(null);

  const { sortingState, onSortingChange } = useSortedTable({
    initialSorting: [],
  });

  const {
    queryParamFilter: serviceTypeFilter,
    clearFilter: clearServiceTypeFilter,
    ...serviceTypeFilterProps
  } = useFilterForTable({ queryParamKey: 'service_type' });
  const {
    queryParamFilter: vendorFilter,
    clearFilter: clearVendorFilter,
    ...vendorFilterProps
  } = useFilterForTable({ queryParamKey: 'vendor' });
  const {
    queryParamFilter: statusFilter,
    clearFilter: clearStatusFilter,
    ...statusFilterProps
  } = useFilterForTable({ queryParamKey: 'status' });

  const paginationChanged = (page: number) => {
    setPageSize(page);
    setCookie('paginationSizeNocExpress', page.toString(), 365);
  };

  const getInventoryIndexFn = () => {
    return getInventoryIndex();
  };

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable(
    getInventoryIndexFn,
    {
      search,
    },
    ['inventory-index-table', search],
    {
      onSuccess: ({ data }: { data: InventoryItem[] }) => {
        console.log('Fetched data:', data);
        setTableData(data);
      },
      refetchOnMount: true,
    },
  );

  const tabs = [
    {
      title: activeTab === 'all' ? `All (${tableData.length})` : 'All',
      onClick: () => {
        setActiveTab('all');
      },
    },
    {
      title: activeTab === 'active' ? `Active (${tableData.length})` : 'Active',
      onClick: () => {
        setActiveTab('active');
      },
    },
    {
      title:
        activeTab === 'complete'
          ? `Complete (${tableData.length})`
          : 'Complete',
      onClick: () => {
        setActiveTab('complete');
      },
    },
  ];

  const clearAllFilters = useCallback(() => {
    clearServiceTypeFilter();
    clearVendorFilter();
    clearStatusFilter();
    setSearch('');
    setCompletionDateFilter(null);
    setEndDateFilter(null);
  }, [clearServiceTypeFilter, clearStatusFilter, clearVendorFilter]);

  const preferenceFilterClearFunctions: Record<
    string,
    (() => void) | undefined
  > = {
    service_type: serviceTypeFilterProps.value.length
      ? clearServiceTypeFilter
      : undefined,
    vendor: vendorFilterProps.value.length ? clearVendorFilter : undefined,
    status: statusFilterProps.value.length ? clearStatusFilter : undefined,
    completion_date: completionDateFilter
      ? () => setCompletionDateFilter(null)
      : undefined,
    end_date: endDateFilter ? () => setEndDateFilter(null) : undefined,
  };

  const { columns, customizeProps, hasFilterByPreference } = useCustomizeTHead({
    columns: tableHeaders,
    tableName: 'inventory-services',
    mockPreferencesFn: getServicesPreferenceMock,
    onPreferenceRemoved: (field) => {
      preferenceFilterClearFunctions[field]?.();
    },
  });

  return (
    <ContentLayout className="w-full 2xl:max-w-[1440px]">
      <div className="mb-6">
        <PageTitleGranite title="Inventory" />
      </div>
      <div className="mb-12 flex flex-col gap-6 xl:flex-row">
        <Metrics />
        <div className="w-full rounded bg-background-base-surface-2 p-4 shadow-elevation3 md:px-6 md:py-6 xl:max-w-[504px]">
          <RecentActivity tickets={recentActivities} isLoading={false} />
        </div>
      </div>
      <div className="flex w-full flex-col items-start justify-start">
        <TableTitle>Service tickets</TableTitle>
        <div className="relative mb-5 w-full">
          <Tabs tabs={tabs} />
        </div>
        <div className="mb-4 flex w-full flex-wrap items-start justify-between gap-4">
          <div className="w-full sm:flex-1">
            <Searchbar
              placeholder="Search by ticket #, address, or location name"
              clearAllValues={search === ''}
              onQueryClear={() => setSearch('')}
              onSearch={(query: string) => {
                setSearch(query);
              }}
            />
          </div>
          <Customize {...customizeProps} />
          <Filters
            clearFilters={clearAllFilters}
            clearFilterClassName="col-span-full md:col-span-1"
          >
            <GraniteSelect
              options={serviceTypeOptions}
              isMulti
              className={clsx(
                'col-span-2 md:col-span-2 xl:col-span-1',
                !hasFilterByPreference('service_type') && '!hidden',
              )}
              placeholder="Filter service type"
              controlShouldRenderValue={false}
              isSearchable={false}
              {...serviceTypeFilterProps}
            />
            <GraniteSelect
              options={vendorOptions}
              isMulti
              className={clsx(
                'col-span-2 md:col-span-2 xl:col-span-1',
                !hasFilterByPreference('vendor') && '!hidden',
              )}
              placeholder="Filter vendor"
              controlShouldRenderValue={false}
              isSearchable={false}
              {...vendorFilterProps}
            />
            <GraniteSelect
              options={statusOptions}
              isMulti
              className={clsx(
                'col-span-2 md:col-span-2 xl:col-span-1',
                !hasFilterByPreference('status') && '!hidden',
              )}
              placeholder="Filter status"
              controlShouldRenderValue={false}
              isSearchable={false}
              {...statusFilterProps}
            />
            <GraniteSelect
              options={filterByCompletionDate}
              className={clsx(
                'col-span-2 md:col-span-2 xl:col-span-1',
                !hasFilterByPreference('completion_date') && '!hidden',
              )}
              placeholder="Filter by completion date"
              isClearable={false}
              isSearchable={false}
              controlShouldRenderValue={false}
              onChange={setCompletionDateFilter}
              value={completionDateFilter}
            />
            <GraniteSelect
              options={filterByEndDate}
              className={clsx(
                'col-span-2 md:col-span-2 xl:col-span-1',
                !hasFilterByPreference('end_date') && '!hidden',
              )}
              placeholder="Filter by end date"
              isClearable={false}
              isSearchable={false}
              controlShouldRenderValue={false}
              onChange={setEndDateFilter}
              value={endDateFilter}
            />
          </Filters>
        </div>
        <div className="mb-12 flex flex-wrap gap-4">
          {serviceTypeFilterProps.value.map((sf) => (
            <Chip
              key={sf.value}
              label={sf.label}
              onDelete={() =>
                serviceTypeFilterProps.onChange(
                  serviceTypeFilterProps.value.filter(
                    (option) => option.value !== sf.value,
                  ),
                )
              }
            />
          ))}
          {vendorFilterProps.value.map((sf) => (
            <Chip
              key={sf.value}
              label={sf.label}
              onDelete={() =>
                vendorFilterProps.onChange(
                  vendorFilterProps.value.filter(
                    (option) => option.value !== sf.value,
                  ),
                )
              }
            />
          ))}
          {statusFilterProps.value.map((sf) => (
            <Chip
              key={sf.value}
              label={sf.label}
              onDelete={() =>
                statusFilterProps.onChange(
                  statusFilterProps.value.filter(
                    (option) => option.value !== sf.value,
                  ),
                )
              }
            />
          ))}
          {completionDateFilter?.value && (
            <Chip
              label={completionDateFilter.label}
              onDelete={() => {
                setCompletionDateFilter(null);
              }}
            />
          )}
          {endDateFilter?.value && (
            <Chip
              label={endDateFilter.label}
              onDelete={() => {
                setEndDateFilter(null);
              }}
            />
          )}
        </div>
        <ServerPaginatedTable
          // TODO: Remove `key` once we get data from BE
          // Added to force re-renders with mock data.
          key={`${tableData.length}-${Date.now()}`}
          data={tableData}
          columns={columns}
          title="Service tickets"
          sortingState={sortingState}
          onSortingChange={onSortingChange}
          paginationChanged={paginationChanged}
          paginationSizeStored={pageSize}
          emptyDataElement={<EmptyInventory />}
          {...paginatedTableProps}
          isFetchingData={false}
        />
      </div>
    </ContentLayout>
  );
};

export default Inventory;
