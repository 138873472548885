import { Resource as ResourceType } from 'api/resources/schema';

import pdf from 'assets/icon/resources/pdf.svg';
import word from 'assets/icon/resources/word.svg';
import excel from 'assets/icon/resources/excel.svg';
import image from 'assets/icon/resources/image.svg';
import unknown from 'assets/icon/resources/unknown.svg';
import video from 'assets/icon/resources/video.svg';
import { getFileType } from './utils';

const FileIcon = ({ fileType }: { fileType: ResourceType['file_type'] }) => {
  const type = getFileType(fileType);
  let src;
  switch (type) {
    case 'word':
      src = word;
      break;
    case 'pdf':
      src = pdf;
      break;
    case 'excel':
      src = excel;
      break;
    case 'image':
      src = image;
      break;
    case 'video':
      src = video;
      break;
    case 'unknown':
      src = unknown;
      break;
  }

  return <img className="h-9 w-9" src={src} alt="File type logo" />;
};

export const Resource = ({
  resource,
  onClick,
}: {
  resource: ResourceType;
  onClick: (resource: ResourceType) => void;
}) => {
  return (
    <div
      onClick={() => onClick(resource)}
      className="flex w-full cursor-pointer flex-col items-start justify-start gap-6 rounded bg-background-base-surface-2  px-6 py-8 hover:bg-background-base-surface-3"
    >
      <FileIcon fileType={resource.file_type} />
      <div className="mt-[10px] flex flex-col items-start justify-start gap-0.5">
        <h1 className="text-sm font-bold text-content-base-default">
          {resource.title}
        </h1>
        <p className="text-slate-400 text-xs font-semibold">
          {resource.description}
        </p>
      </div>
    </div>
  );
};
