import { getSignedUrl } from 'api/resources/api';
import { Resource } from 'api/resources/schema';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { useQuery } from 'react-query';
import { getFileType } from '../utils';
import { Image } from './Image';
import { Pdf } from './Pdf';
import { Excel } from './Excel';
import { Word } from './Word';
import { Unknown } from './Unknown';
import { Video } from './Video';

export interface ResourcePreviewProps {
  dynamicProps?: Resource;
  downloadFile: (resource: Resource) => void;
}

export interface CombinedProps extends ResourcePreviewProps, ModalParams {}

const getResourceElement = (type?: ReturnType<typeof getFileType>) => {
  switch (type) {
    case 'word':
      return Word;
    case 'pdf':
      return Pdf;
    case 'excel':
      return Excel;
    case 'image':
      return Image;
    case 'video':
      return Video;
    default:
      return Unknown;
  }
};

export const ResourcePreviewDialog = ({
  isOpen,
  close,
  dynamicProps: resource,
  downloadFile,
}: CombinedProps) => {
  const { data, isLoading } = useQuery(
    ['resources', resource?.id],
    () => getSignedUrl(resource!.id),
    { enabled: !!resource?.id },
  );
  const Element = getResourceElement(
    resource?.file_type ? getFileType(resource.file_type) : undefined,
  );
  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="flex h-[85vh] w-[85vw] flex-col overflow-hidden rounded-t-lg bg-background-base-surface-2">
        <div className="space-y-2 bg-background-base-surface-3 px-8 py-6">
          <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
            {resource?.title}
          </h1>
          <span className="font-semibold">{resource?.description}</span>
        </div>
        <div className="flex min-h-0 flex-1 flex-col justify-between gap-12 p-8">
          <div className="min-h-0 w-full flex-1 self-center">
            {!isLoading && data?.url && (
              <Element className="h-full w-full" src={data.url} />
            )}
          </div>
          <div className="flex items-center justify-start gap-5">
            <GraniteButton size="large" variant="secondary" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="primary"
              onClick={() => resource && downloadFile(resource)}
            >
              Download
            </GraniteButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
