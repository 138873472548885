import { InventoryIndexAPIResponse } from './schema';
import {
  inventoryItems,
  mockServicePreferences,
} from 'screens/Inventory/utils';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getInventoryIndex =
  async (): Promise<InventoryIndexAPIResponse> => {
    await delay(1000);
    return new Promise<InventoryIndexAPIResponse>((resolve) => {
      resolve({
        data: inventoryItems,
        metadata: {
          page: 1,
          page_size: 5,
          total_items: 5,
          total_pages: 1,
        },
      });
    });
  };

export const getServicesPreferenceMock = async () => {
  return Promise.resolve(mockServicePreferences);
};
