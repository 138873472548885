import FormSection from 'components/FormSection';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import DualRangeSlider from './DualRangeSlider';
import { GraniteSelect } from 'components/Select/Select';
import RadioButton from 'components/RadioButton';
import { useEffect, useState } from 'react';
import { DIAProductType } from './schemas';
import { capitalizeFirstLetter } from './utils';

interface DIAServicesProps {
  staticData: unknown;
  onChange: (data: DIAProductType) => void;
  dia: DIAProductType | null;
}

export const DIAServices = ({
  staticData,
  onChange,
  dia,
}: DIAServicesProps) => {
  const {
    carrier_options,
    dia_ip_block_options,
    dia_speed_options,
    dia_term_options,
  } = staticData as {
    carrier_options: string[];
    dia_ip_block_options: string[];
    dia_speed_options: string[];
    dia_term_options: string[];
  };
  const carierOptions = [...new Set(carrier_options)].sort();
  const speedOptions = dia_speed_options.map(Number);
  const [state, setState] = useState<DIAProductType>({
    ip_type: dia?.ip_type || 'STATIC',
    min_download: dia?.min_download || speedOptions[0],
    max_download: dia?.max_download || 200,
    term: dia?.term || dia_term_options[dia_term_options.length - 1],
    product_type: 'DIA',
    ip_block:
      dia?.ip_block || dia_ip_block_options[dia_ip_block_options.length - 1],
    include_carriers: dia?.include_carriers || '',
    exclude_carriers: dia?.exclude_carriers || '',
    preferred_carriers: dia?.preferred_carriers || '',
    diversity_needed: dia?.diversity_needed || false,
  });
  const [diversity, setDiversity] = useState(
    dia?.diversity_needed ? 'Yes' : 'No',
  );
  const [carriers, setCarriers] = useState<{
    include_carriers: string[];
    exclude_carriers: string[];
    preferred_carriers: string[];
  }>({
    include_carriers: dia?.include_carriers
      ? dia.include_carriers.split(',')
      : [],
    exclude_carriers: dia?.exclude_carriers
      ? dia.exclude_carriers.split(',')
      : [],
    preferred_carriers: dia?.preferred_carriers
      ? dia.preferred_carriers.split(',')
      : [],
  });

  useEffect(() => {
    onChange(state);
  }, [onChange, state]);

  useEffect(() => {
    if (diversity === 'No') {
      setState((prev) => ({
        ...prev,
        diversity_needed: false,
        include_carriers: '',
        exclude_carriers: '',
        preferred_carriers: '',
      }));
    } else {
      setState((prev) => ({
        ...prev,
        diversity_needed: true,
        include_carriers: carriers.include_carriers.join(','),
        exclude_carriers: carriers.exclude_carriers.join(','),
        preferred_carriers: carriers.preferred_carriers.join(','),
      }));
    }
  }, [
    carriers.exclude_carriers,
    carriers.include_carriers,
    carriers.preferred_carriers,
    diversity,
  ]);

  const handleDiversityChange = (value: string) => setDiversity(value);

  return (
    <FormSection
      title="DIA requirements"
      subtitle="Customize the service needs for locations that require DIA circuits."
      className="gap-4"
    >
      <GraniteLabel
        label="Bandwidth range"
        subtitle="Adjust slider to select your desired internet download speed, starting from 5 Mb/s up to 10 Gb/s."
        className="col-start-1 pt-2"
      >
        <DualRangeSlider
          color="#1A4BAB"
          background="#6C9DFF"
          allowedValues={speedOptions}
          value={[state.min_download, state.max_download]}
          onChange={([min, max]) =>
            setState({ ...state, min_download: min, max_download: max })
          }
        />
      </GraniteLabel>

      <div className="flex gap-4">
        <div className="w-1/3">
          <GraniteSelect
            options={dia_term_options.map((o) => ({
              label: o + (+o === 1 ? ' year' : ' years'),
              value: o,
            }))}
            label="Contract term"
            value={{
              value: state.term,
              label: state.term + (+state.term === 1 ? ' year' : ' years'),
            }}
            onChange={(e) => setState({ ...state, term: e!.value })}
          />
        </div>
        <div className="w-1/3">
          <GraniteSelect
            options={[{ value: 'STATIC', label: 'Static' }]}
            label="IP type"
            value={{
              value: state.ip_type.toUpperCase(),
              label: capitalizeFirstLetter(state.ip_type),
            }}
            onChange={(e) => setState({ ...state, ip_type: e!.value })}
          />
        </div>
        <div className="w-1/3">
          <GraniteSelect
            options={dia_ip_block_options.map((block) => ({
              label: block,
              value: block,
            }))}
            label="IP block"
            value={{ value: state.ip_block, label: state.ip_block }}
            onChange={(e) => setState({ ...state, ip_block: e!.value })}
          />
        </div>
      </div>

      <GraniteLabel label="Diversity needed (Y/N)" className="col-start-1">
        <RadioButton
          options={[
            {
              value: 'Yes',
              label: 'Yes',
            },
            {
              value: 'No',
              label: 'No',
            },
          ]}
          horizontal
          selectedValue={diversity}
          onChange={handleDiversityChange}
          className="flex gap-4 text-base"
          // error={errors.accessType?.message}
        />
      </GraniteLabel>

      {diversity === 'Yes' && (
        <>
          <div className="flex gap-4">
            <div className="w-1/2">
              <GraniteLabel
                label="Include carriers (optional)"
                className="col-start-1"
              >
                <GraniteSelect
                  value={carriers.include_carriers.map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  onChange={(value) =>
                    setCarriers({
                      ...carriers,
                      include_carriers: value.map((v) => v.value),
                    })
                  }
                  options={carierOptions.map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  isMulti
                />
              </GraniteLabel>
            </div>
            <div className="w-1/2">
              <GraniteLabel
                label="Exclude carriers (optional)"
                className="col-start-1"
              >
                <GraniteSelect
                  value={carriers.exclude_carriers.map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  onChange={(value) =>
                    setCarriers({
                      ...carriers,
                      exclude_carriers: value.map((v) => v.value),
                    })
                  }
                  options={carierOptions.map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  isMulti
                />
              </GraniteLabel>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-1/2">
              <GraniteLabel label="Preferred carrier" className="col-start-1">
                <GraniteSelect
                  value={carriers.preferred_carriers.map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  onChange={(value) =>
                    setCarriers({
                      ...carriers,
                      preferred_carriers: value.map((v) => v.value),
                    })
                  }
                  options={carierOptions.map((v) => ({
                    label: v,
                    value: v,
                  }))}
                  isMulti
                />
              </GraniteLabel>
            </div>
          </div>
        </>
      )}
    </FormSection>
  );
};
