import Logo from 'components/Logo';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PageLoader } from 'components/PageLoader';
import { getCompanies, login } from 'api/auth/auth';
import { Business } from 'react-ionicons';
import { useCallback } from 'react';
import { AuthProvider } from 'authContext';
import { OktaGroup } from 'api/auth/schema';
import { toRelativeUrl } from '@okta/okta-auth-js';

const SelectCompanyInner = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(['auth', 'companies'], () =>
    getCompanies(),
  );
  const selectCompany = useCallback(
    async (group: OktaGroup) => {
      const originalUri = sessionStorage.getItem('originalUri') ?? '/';
      sessionStorage.removeItem('originalUri');
      const data = await login(group.group_id);
      localStorage.setItem(
        'g360-token-storage',
        JSON.stringify({ ...data, group }),
      );
      navigate(toRelativeUrl(originalUri, window.location.origin), {
        replace: true,
      });
    },
    [navigate],
  );
  if (isLoading) return <PageLoader />;
  if (data?.data.length === 1) {
    selectCompany(data?.data[0]);
    return null;
  }
  return (
    <div className="flex h-screen flex-wrap items-center justify-center md:py-6">
      <div className="flex min-h-full w-full flex-col justify-center gap-8 bg-background-base-surface-2 px-6 py-12 md:min-h-0 md:w-[433px] md:px-12">
        <div className="flex flex-col items-center gap-6">
          <Logo className="!h-auto !w-[126px]" />
          <h1 className="font-semibold text-content-base-default">
            Choose a company
          </h1>
        </div>
        <div className="flex flex-col items-center gap-6">
          {data?.data?.map((company) => (
            <button
              className="flex w-full items-center gap-x-4 rounded border border-button-stroke-secondary-default fill-content-base-subdued p-4"
              key={company.id}
              onClick={() => selectCompany(company)}
            >
              <Business width="24px" height="24px" color="inherit" />
              <span className="truncate font-semibold text-content-base-default">
                {company.display_name}
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export const SelectCompany = () => (
  <AuthProvider>
    <SelectCompanyInner />
  </AuthProvider>
);
