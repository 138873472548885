import { SiteAddress } from 'api/addresssearch/schema';
import { apiClient } from '../apiClient';
import { Configurations } from './schemas/ConfigurationsSchema';
import { IDENTIFIERS } from 'screens/OpenNetOpsTicket/FindLocationOrService/schemas';
import { StaticData } from './schemas/StaticDataSchema';
import { TicketNoteResponse } from 'api/techexpress/schemas/TicketNoteSchemas';
import { z } from 'zod';
import {
  TicketAttachmentMetadataResponseSchema,
  TicketAttachmentsMetadataResponse,
  TicketUpdatesResponse,
} from 'api/techexpress/schema';
import {
  CloseAndResolveTicketRequestType,
  EditNocTicketForm,
  EscalateTicketRequestType,
  FollowTicketRequestType,
  NetOpsResponseForm,
} from 'screens/NOCExpress/TicketDetails/form-schema';
import {
  CreateTicketResponse,
  NetOpsFilterSearchParams,
  NetOpsFilterSearchParamsSchema,
  NetOpsIndexAPIResponse,
} from './schema';

export const getEmbeddedDashboard = async (): Promise<string> => {
  const htmlResponse = await apiClient.get('/api/v1/noc/dashboard');
  return htmlResponse.data;
};

export const getTicketDetails = async (
  ticket_id?: string,
): Promise<NetOpsResponseForm> => {
  const response = await apiClient.get(
    `/api/v1/noc/tickets/${ticket_id}?internal=true`,
  );
  return response.data;
};

export const getTicketNotesNoc = async (ticketId?: string) => {
  const response = await apiClient.get<TicketNoteResponse>(
    `/api/v1/noc/tickets/notes/${ticketId}`,
  );
  return response.data;
};

export const createTicketNoteNoc = async (
  request: { text: string },
  ticketId?: string,
): Promise<TicketNoteResponse> => {
  const response = await apiClient.post(
    `/api/v1/noc/tickets/notes/${ticketId}`,
    request,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
  return response.data;
};

export const getTicketUpdatesNoc = async (
  ticketId?: string,
): Promise<TicketUpdatesResponse[]> => {
  const response = await apiClient.get<TicketUpdatesResponse[]>(
    `/api/v1/noc/tickets/statusUpdates/${ticketId}`,
  );

  return response.data;
};

export const postTicketAttachmentsNoc = async (
  ticketId: number,
  request: FormData,
) => {
  await apiClient.post(`/api/v1/noc/tickets/attachments/${ticketId}`, request, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
};

export const editTicketNoc = async (
  ticketId: number,
  request: EditNocTicketForm,
): Promise<NetOpsResponseForm> => {
  if (!ticketId) {
    throw new Error('companyId and ticketId are required');
  }
  const response = await apiClient.patch(
    `/api/v1/noc/tickets/${ticketId}`,
    request,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );

  return response.data;
};

export const escalateTicket = async (
  ticketId: number,
  request: EscalateTicketRequestType,
) => {
  if (!ticketId) {
    throw new Error('companyId and ticketId are required');
  }
  const response = await apiClient.post(
    `/api/v1/noc/tickets/${ticketId}/escalate`,
    request,
  );

  return response.data;
};

export const closeNocTicket = async (
  ticketId: number,
  request: CloseAndResolveTicketRequestType,
) => {
  if (!ticketId) {
    throw new Error('companyId and ticketId are required');
  }
  const response = await apiClient.patch(
    `/api/v1/noc/tickets/${ticketId}/close/`,
    request,
  );

  return response.data;
};

export const reopenNocTicket = async (
  ticketId: number,
  request: EscalateTicketRequestType,
) => {
  if (!ticketId) {
    throw new Error('companyId and ticketId are required');
  }
  const response = await apiClient.patch(
    `/api/v1/noc/tickets/${ticketId}/reopen`,
    request,
  );

  return response.data;
};

export const cancelNocTicket = async (ticketId: number) => {
  if (!ticketId) {
    throw new Error('companyId and ticketId are required');
  }
  const response = await apiClient.delete(`/api/v1/noc/tickets/${ticketId}`);

  return response.data;
};

export const getNocTicketAttachmentsMetadata = async (
  ticketId?: string,
): Promise<TicketAttachmentsMetadataResponse[]> => {
  const response = await apiClient.get(
    `/api/v1/noc/tickets/attachments/${ticketId}`,
  );
  return z.array(TicketAttachmentMetadataResponseSchema).parse(response.data);
};

export const downloadNocTicketAttachment = async (
  attachmentId?: number,
  ticketId?: string,
): Promise<string> => {
  const response = await apiClient.get(
    `/api/v1/noc/tickets/attachments/${ticketId}/download/${attachmentId}`,
    {
      responseType: 'arraybuffer',
    },
  );

  return response.data;
};

export const getConfigurations = async () => {
  const response = await apiClient.get('/api/v1/noc/configurations/download/');
  return response.data;
};

export const fetchNOCStaticData = async (
  ticket_type?: string,
  ticket_subtype?: string,
): Promise<StaticData | undefined> => {
  const params = new URLSearchParams();

  if (ticket_type) {
    params.append('ticket_type', ticket_type);
  }

  if (ticket_subtype) {
    params.append('ticket_subtype', ticket_subtype);
  }
  const response = await apiClient.get(
    `/api/v1/noc/tickets/static?${params.toString()}`,
  );
  return response.data;
};

export const fetchBasedOnIdentifier = async (
  identifier: string,
  searchQuery: string,
): Promise<SiteAddress[] | Configurations[]> => {
  const identifierParam = getParamByIdentifier(identifier);
  const isConfigurationsEndpoint = [
    'search_serial_number',
    'search_service_id',
  ].includes(identifierParam);

  const endpoint = isConfigurationsEndpoint
    ? 'configurations'
    : 'companies/sites';

  const queryParams = `${identifierParam}=${encodeURIComponent(
    searchQuery,
  )}&page_size=${10}`;
  const response = await apiClient.get(
    `/api/v1/noc/${endpoint}?${queryParams}`,
  );

  return response.data;
};

export const fetchConfigurationServices = async ({
  services,
  site_ids,
}: {
  services?: string;
  site_ids?: string;
}): Promise<Configurations[]> => {
  let params = '';
  if (services) {
    params += `services=${services}`;
  }
  if (site_ids) {
    params += (params ? '&' : '') + `site_ids=${site_ids}`;
  }
  const response = await apiClient.get(`/api/v1/noc/configurations?${params}`);

  return response.data;
};

const getParamByIdentifier = (identifier: string) => {
  switch (identifier) {
    case IDENTIFIERS.Enum['Serial number']:
      return 'search_serial_number';
    case IDENTIFIERS.Enum['Service identifier']:
      return 'search_service_id';
    case IDENTIFIERS.Enum['Address']:
      return 'search';
    case IDENTIFIERS.Enum['Site name/store number']:
    case IDENTIFIERS.Enum['Account']:
      return 'account';
    default:
      return '';
  }
};

export const createTicket = async (
  request: FormData,
): Promise<CreateTicketResponse> => {
  const response = await apiClient.post(`/api/v1/noc/tickets`, request, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const getNetOpsTicketsIndex = async (
  params: NetOpsFilterSearchParams = {},
): Promise<NetOpsIndexAPIResponse> => {
  const queryParams = NetOpsFilterSearchParamsSchema.parse(params);

  const newQueryParams = {
    ...queryParams,
    ...(params.date_order || params.last_updated_order
      ? {
          date_order: null,
          last_updated_order: null,
          sort_order: params.date_order || params.last_updated_order,
          sort_column: params.date_order
            ? 'dateEntered'
            : params.last_updated_order
              ? 'lastUpdated'
              : null,
        }
      : {}),
  };

  const response = await apiClient.get('/api/v1/noc/tickets', {
    params: newQueryParams,
  });

  return response.data;
};

export const followTicket = async (
  ticketId: number,
  request: FollowTicketRequestType,
) => {
  const response = await apiClient.post(
    `/api/v1/noc/tickets/${ticketId}/follow/`,
    request,
  );

  return response.data;
};
