import { useAuthUser } from './useAuthUser';
import { TokenUserRoles } from '../api/users/schemas/Users';

export interface UseWithUserPermissionsReturn {
  isVisible: boolean;
}

export const useWithUserPermissions = (
  roles: TokenUserRoles[],
): UseWithUserPermissionsReturn => {
  const { roles: userRoles } = useAuthUser();

  // Super Admin and Company Admin have access to everything
  if (
    userRoles.includes(TokenUserRoles.SUPER_ADMIN) ||
    userRoles.includes(TokenUserRoles.COMPANY_ADMIN)
  ) {
    return {
      isVisible: true,
    };
  }

  // For a User and a Viewer
  if (roles.some((role) => userRoles.includes(role))) {
    return { isVisible: true };
  }

  return {
    isVisible: false,
  };
};
