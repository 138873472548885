import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitleGranite } from 'components';
import {
  PremiumBadge,
  Priority1Badge,
  Priority2Badge,
  Priority3Badge,
  StatusCell,
  StatusIndicator,
  TicketIdBadge,
} from './TechExpress.styles';
import { deleteDraft, getDrafts, getTickets } from '../../api/techexpress/api';
import {
  add,
  endOfDay,
  isToday,
  parseISO,
  startOfDay,
  subMonths,
} from 'date-fns';
import {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import format from 'date-fns/format';
import { ColumnDef } from '@tanstack/react-table';
import Filters from 'components/Filters';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import {
  StackedCell,
  StackedCellMainContent,
  StackedCellSubtitle,
  TableTitle,
} from 'components/Table/Table.styles';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { TechExpressMetrics } from './TechExpressMetrics';
import TechExpressRecentTickets from './TechExpressRecentTickets';
import {
  Draft,
  GetDraftFilterSearchParams,
  GetTicketFilterSearchParams,
  Ticket,
} from '../../api/techexpress/schema';
import { useFilterForTable } from '../../hooks/useFilterForTable';
import { usePaginatedTable } from '../../hooks/usePaginatedTable';
import { ServerPaginatedTable } from '../../components/Table/ServerPaginatedTable';
import { SingleValue } from 'react-select';
import { dateToUTCWithoutMS } from '../../shared/util/dateToUtcStrWithoutMS';
import Searchbar from '../../components/Table/SearchBar';
import { useSortedTable } from '../../hooks/useSortedTable';
import Tabs from 'components/Table/Tabs';
import { useDownloadAsExcel } from '../../hooks/useDownloadAsExcel';
import { Chip } from '../../components/Chip/Chip';
import { toFrontendBoardName, toFrontendTicketType } from './utils';

import { TokenUserRoles } from '../../api/users/schemas/Users';
import { Row } from '@tanstack/react-table';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import showToast from 'components/Toast/Toast';
import { FileTray } from 'react-ionicons';
import { getQueryClient } from 'query-client';
import { useModal, useModalReturn } from 'hooks/useModal';
import { DeleteDraftConfirmationDialog } from './DeleteDraftConfirmationDialog';
import { DisplayForRole } from 'components/DisplayForRole/DisplayForRole';
import { setCookie, getCookie } from 'shared/util/util';
import { useCustomizeTHead } from 'hooks/useCustomizeTHead';
import Customize from 'components/Table/Customize/Customize';

export const formatDate = (date?: string) => {
  if (date) {
    const parsedDate = parseISO(date);
    if (isToday(parsedDate)) return 'Today';
    return format(parsedDate, 'MM/dd/yy');
  } else {
    return '';
  }
};

const ticketStatusOptions = [
  { value: 'Pending Tech Assignment', label: 'Pending tech assignment' },
  { value: 'Complete', label: 'Complete' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Tech Checked In', label: 'Tech checked in' },
  { value: 'Tech Assigned', label: 'Tech assigned' },
  { value: 'Pending Customer', label: 'Pending customer' },
  { value: 'On Hold', label: 'On hold' },
];

const ticketPriorityOptions = [
  { value: 'P1', label: 'Priority 1' },
  { value: 'P2', label: 'Priority 2' },
  { value: 'P3', label: 'Priority 3' },
];

const ticketTypeOptions = [
  { value: 'Equipment Install', label: 'Equipment install' },
  { value: 'Move', label: 'Move' },
  { value: 'POTS Extension', label: 'POTS extension' },
  { value: 'Smart Hands', label: 'Smart hands' },
  { value: 'Site Audit', label: 'Site audit' },
  { value: 'Customer Equipment', label: 'Customer equipment' },
  { value: 'Broadband Circuit', label: 'Broadband circuit' },
  { value: 'Circuit Extension', label: 'Circuit extension' },
  { value: 'Fiber Extension', label: 'Fiber extension' },
  { value: 'POTS', label: 'POTS' },
  { value: 'Survey and Mount', label: 'Survey and Mount' },
  { value: 'SMCT', label: 'SMCT' },
  { value: 'Cutover and Test', label: 'Cutover and Test' },
  { value: 'Repair', label: 'Repair' },
  { value: 'Activation Repairs', label: 'Activation Repairs' },
  { value: 'Decomm', label: 'Decomm' },
  { value: 'Replacement', label: 'Replacement' },
  { value: 'MAC', label: 'MAC' },
  { value: 'Survey', label: 'Survey' },
  { value: 'Hosted Voice(ePOTS)', label: 'Hosted Voice(ePOTS)' },
  { value: 'Extension Survey', label: 'Extension Survey' },
  { value: 'Cradlepoint', label: 'Cradlepoint' },
  { value: 'HPBX', label: 'HPBX' },
  { value: 'LEC Prep Install', label: 'LEC Prep Install' },
  { value: 'LEC Prep Survey', label: 'LEC Prep Survey' },
  { value: 'Switch Install', label: 'Switch Install' },
  { value: 'Turn Up', label: 'Turn Up' },
  { value: 'GRID', label: 'GRID' },
  { value: 'GRID Tail - Expansion', label: 'GRID Tail - Expansion' },
  { value: 'GRID Tail - Proper', label: 'GRID Tail - Proper' },
  { value: 'Facilities Port', label: 'Facilities Port' },
  { value: 'Facilities Audit', label: 'Facilities Audit' },
  { value: 'DIA Circuit', label: 'DIA Circuit' },
  { value: 'Dispatch', label: 'Dispatch' },
];

const filterByDate = [
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 1)),
    label: 'Past month',
  },
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 3)),
    label: 'Past 3 months',
  },
];

const queryClient = getQueryClient();

const DraftActionsContext = createContext<
  useModalReturn & {
    selectedDraft?: Draft;
    setSelectedDraft: Dispatch<SetStateAction<Draft | undefined>>;
  }
>(
  {} as useModalReturn & {
    selectedDraft?: Draft;
    setSelectedDraft: Dispatch<SetStateAction<Draft | undefined>>;
  },
);

const TechExpressTableHeaders: ColumnDef<Ticket>[] = [
  {
    id: 'parent_macnum',
    accessorKey: 'parent_macnum',
    enableSorting: false,
    header: 'Parent #',
  },
  {
    id: 'macnum',
    accessorKey: 'macnum',
    enableSorting: false,
    header: 'Account #',
  },
  {
    id: 'full_site_name',
    accessorKey: 'full_site_name',
    enableSorting: false,
    header: 'Account name',
  },
  {
    id: 'submitted_by',
    accessorKey: 'submitted_by',
    enableSorting: false,
    header: 'Submitted by',
  },
  {
    id: 'id',
    header: 'Ticket #',
    accessorKey: 'id',
    enableSorting: false,
    cell: (row) => <TicketIdBadge>{row.getValue() as ReactNode}</TicketIdBadge>,
  },
  {
    id: 'address',
    header: 'Service address',
    accessorFn: (r) => r,
    accessorKey: 'city',
    enableSorting: false,
    cell: (row) => (
      <StackedCell>
        <StackedCellMainContent>
          {row.row.original.address_1}
        </StackedCellMainContent>
        <StackedCellSubtitle>
          {row.row.original.city}, {row.row.original.state_identifier},
          {row.row.original.zip}
        </StackedCellSubtitle>
      </StackedCell>
    ),
  },
  {
    id: 'location',
    header: 'Location name',
    accessorKey: 'company_name',
    enableSorting: false,
    cell: (row) => (
      <StackedCell>
        <StackedCellMainContent>
          {row.row.original.location_name}
        </StackedCellMainContent>
        <StackedCellSubtitle>
          {row.row.original.location_number}
        </StackedCellSubtitle>
      </StackedCell>
    ),
  },
  {
    id: 'ticket_type',
    header: 'Ticket type',
    accessorKey: 'type_name',
    enableSorting: false,
    cell: (row) => (
      <StackedCell>
        <StackedCellMainContent>
          {toFrontendTicketType(row.row.original.type_name)}
        </StackedCellMainContent>
        <StackedCellSubtitle>
          {toFrontendBoardName(row.row.original.board_name)}
        </StackedCellSubtitle>
      </StackedCell>
    ),
  },
  {
    id: 'priority',
    header: 'Priority',
    accessorKey: 'sub_type',
    enableSorting: false,
    cell: (row) => {
      switch (row.getValue() as string) {
        case 'P1':
          return <Priority1Badge>Priority 1</Priority1Badge>;
        case 'P2':
          return <Priority2Badge>Priority 2</Priority2Badge>;
        case 'P3':
          return <Priority3Badge>Priority 3</Priority3Badge>;
        case 'PD':
        case 'pd':
          return <PremiumBadge>Premium</PremiumBadge>;
        default:
          return <Fragment />;
      }
    },
  },
  {
    id: 'status_name',
    header: 'Status',
    accessorKey: 'status_name',
    enableSorting: false,
    cell: (row) => (
      <StatusCell>
        <StatusIndicator
          $status={row.row.original.status_name
            .replaceAll(new RegExp(/\s|-|_/g), '')
            .toLowerCase()}
        />
        <StackedCellMainContent>
          {row.row.original.status_name}
        </StackedCellMainContent>
      </StatusCell>
    ),
  },
  {
    id: 'scheduled',
    header: 'Scheduled',
    accessorKey: 'schedule',
    enableSorting: false,
    cell: (r) => (
      <StackedCell>
        <StackedCellMainContent>
          {r.row.original.schedule?.scheduling_type === 'Hard Start' ? (
            `${formatDate(r.row.original.schedule?.start_date)} - ${format(
              new Date(r.row.original.schedule?.start_date),
              'hh:mm a',
            )}`
          ) : r.row.original.schedule?.scheduling_type ===
            'Requested Window' ? (
            `${formatDate(r.row.original.schedule?.start_date)} - ${format(
              new Date(r.row.original.schedule?.start_date),
              'hh:mm a',
            )}-${format(
              new Date(r.row.original.schedule?.end_date),
              'hh:mm a',
            )}`
          ) : (
            <span className="text-content-base-subdued">-- -- --</span>
          )}
        </StackedCellMainContent>
      </StackedCell>
    ),
  },
  {
    id: 'date_entered',
    accessorKey: 'date_entered',
    header: 'Opened',
    accessorFn: (r) => formatDate(r.date_entered),
  },
  {
    id: 'last_updated',
    header: 'Last updated',
    accessorKey: 'last_updated',
    enableSorting: false,
    accessorFn: (r) => formatDate(r.last_updated),
  },
];

const emptyCellContent = '-- -- --';

const DraftTableHeaders: ColumnDef<Draft>[] = [
  {
    id: 'created_at',
    header: 'Date saved',
    enableSorting: false,
    accessorFn: (r) => formatDate(r.created_at),
  },
  {
    id: 'service_address',
    header: 'Service address',
    enableSorting: false,
    cell: (row) => {
      const site = row.row.original.data?.getStartedQuestionnaire?.site;
      return (
        <StackedCell>
          <StackedCellMainContent>
            {site?.address_line_1 ?? emptyCellContent}
          </StackedCellMainContent>
          <StackedCellSubtitle>
            {site?.city && site?.zip
              ? `${site.city}, ${site.zip}`
              : emptyCellContent}
          </StackedCellSubtitle>
        </StackedCell>
      );
    },
  },
  {
    id: 'location_name',
    header: 'Location name',
    enableSorting: false,
    cell: (row) => {
      const content = row.row.original.data?.getStartedQuestionnaire?.content;
      return (
        <StackedCell>
          <StackedCellMainContent>
            {!content?.type
              ? emptyCellContent
              : content.type === 'single'
                ? content?.locationName ?? emptyCellContent
                : ''}
          </StackedCellMainContent>
          <StackedCellSubtitle>
            {!content?.type
              ? emptyCellContent
              : content.type === 'single'
                ? content?.locationNumber ?? emptyCellContent
                : ''}
          </StackedCellSubtitle>
        </StackedCell>
      );
    },
  },
  {
    id: 'updated_at',
    header: 'Last updated',
    enableSorting: false,
    accessorFn: (r) => formatDate(r.updated_at),
  },
  {
    id: 'date',
    header: 'Expires',
    accessorFn: (r) =>
      formatDate(
        add(new Date(r.created_at + 'Z'), { months: 2 }).toISOString(),
      ),
  },
  {
    id: 'actions',
    header: 'Actions',
    enableSorting: false,
    cell: (props) => <DraftActions draft={props.row.original} />,
  },
];

const DraftActions = ({ draft }: { draft: Draft }) => {
  const { open, setSelectedDraft } = useContext(DraftActionsContext);
  return (
    <div className="flex gap-x-3">
      <Link
        to="/tech-express/let-us-help"
        state={draft}
        className="button secondary small"
      >
        View
      </Link>
      <GraniteButton
        type="button"
        variant="unstyled"
        size="small"
        onClick={() => {
          setSelectedDraft(draft);
          open();
        }}
        className="border border-input-stroke-error text-input-stroke-error hover:bg-button-background-destructive-hover hover:text-button-content-destructive-hover"
      >
        Delete
      </GraniteButton>
    </div>
  );
};

const EmptyDrafts = () => {
  return (
    <div className="col-span-full flex flex-col items-center gap-6 bg-background-base-surface-2 p-8">
      <FileTray
        color="rgb(var(--content-base-subdued))"
        width="48px"
        height="48px"
      />
      <p className="rounded font-bold leading-[22px] text-content-base-default">
        You haven&apos;t created any drafts yet.
      </p>
    </div>
  );
};

const TechExpress: React.FC = () => {
  const navigate = useNavigate();
  const modalProps = useModal();
  const [selectedDraft, setSelectedDraft] = useState<Draft>();

  const onDeleteDraftConfirmed = async () => {
    if (selectedDraft) {
      await deleteDraft(selectedDraft.id);
      queryClient.invalidateQueries({
        queryKey: ['tech-express-table', 'drafts'],
      });
      showToast.confirmation({
        message: 'Draft has been successfully deleted',
      });
      modalProps.close();
      setSelectedDraft(undefined);
    }
  };

  const handleRowClick = (row: Row<Ticket | Draft>) => {
    if (activeTab === 'drafts') return;
    navigate(`/tech-express/${row?.original?.id}`);
  };

  const {
    queryParamFilter: statusFilter,
    clearFilter: clearStatusFilters,
    ...statusFilterProps
  } = useFilterForTable({
    queryParamKey: 'status',
  });
  const {
    queryParamFilter: priorityFilter,
    clearFilter: clearPriorityFilters,
    ...priorityFilterProps
  } = useFilterForTable({
    queryParamKey: 'priority',
  });
  const {
    queryParamFilter: typeFilter,
    clearFilter: clearTypeFilters,
    ...ticketTypeFilterProps
  } = useFilterForTable({
    queryParamKey: 'type',
  });

  const [dateFilter, setDateFilter] = useState<SingleValue<OptionType>>(null);
  const [pageSize, setPageSize] = useState<number>();
  const [search, setSearch] = useState('');
  const { sortingQueryParams, sortingState, onSortingChange } = useSortedTable({
    initialSorting: [{ id: 'date', desc: true }],
  });

  const clearAllFilters = useCallback(() => {
    clearTypeFilters();
    clearStatusFilters();
    clearPriorityFilters();
    setDateFilter(null);
    setSearch('');
  }, [clearPriorityFilters, clearStatusFilters, clearTypeFilters]);

  const [tabFilter, setTabFilter] = useState({});
  const [activeTab, setActiveTab] = useState<
    'all' | 'active' | 'complete' | 'drafts'
  >('all');

  const getTicketsQueryFn = useCallback(
    (args?: GetTicketFilterSearchParams) => {
      return getTickets({
        ...statusFilter,
        ...priorityFilter,
        ...typeFilter,
        ...(dateFilter
          ? {
              date_range_start: dateFilter.value,
              date_range_end: dateToUTCWithoutMS(endOfDay(new Date())),
            }
          : {}),
        ...tabFilter,
        ...(search ? { search } : {}),
        ...sortingQueryParams,
        ...args,
      });
    },
    [
      dateFilter,
      priorityFilter,
      search,
      sortingQueryParams,
      statusFilter,
      tabFilter,
      typeFilter,
    ],
  );

  const getDraftsQueryFn = useCallback(
    (args?: GetDraftFilterSearchParams) => {
      return getDrafts({
        ...typeFilter,
        ...(dateFilter
          ? {
              date_range_start: dateFilter.value,
              date_range_end: dateToUTCWithoutMS(endOfDay(new Date())),
            }
          : {}),
        ...tabFilter,
        ...sortingQueryParams,
        ...args,
      });
    },
    [dateFilter, sortingQueryParams, tabFilter, typeFilter],
  );

  const fetcherFunction = useMemo(
    () => (activeTab !== 'drafts' ? getTicketsQueryFn : getDraftsQueryFn),
    [activeTab, getDraftsQueryFn, getTicketsQueryFn],
  );

  const queryKey = useMemo(
    () =>
      activeTab !== 'drafts'
        ? [
            'tech-express-table',
            dateFilter,
            priorityFilter,
            search,
            sortingQueryParams,
            statusFilter,
            tabFilter,
            typeFilter,
          ]
        : [
            'tech-express-table',
            'drafts',
            dateFilter,
            sortingQueryParams,
            tabFilter,
            typeFilter,
          ],
    [
      activeTab,
      dateFilter,
      priorityFilter,
      search,
      sortingQueryParams,
      statusFilter,
      tabFilter,
      typeFilter,
    ],
  );

  const { data: tablePageData, ...paginatedTableProps } = usePaginatedTable<
    Ticket | Draft
  >(
    fetcherFunction,
    {
      dateFilter,
      priorityFilter,
      search,
      sortingQueryParams,
      statusFilter,
      typeFilter,
    },
    queryKey,
    { refetchOnMount: true },
  );

  const getPrority = (prority: string) => {
    switch (prority) {
      case 'P1':
        return 'Priority 1';
      case 'P2':
        return 'Priority 2';
      case 'P3':
        return 'Priority 3';
      case 'PD':
      case 'pd':
        return 'Premium';
      default:
        return '';
    }
  };

  const tabs = [
    {
      title:
        activeTab === 'all' &&
        !paginatedTableProps.isFetchingData &&
        !paginatedTableProps.isFetchingError
          ? `All (${paginatedTableProps.itemCount})`
          : 'All',
      onClick: () => {
        setTabFilter({});
        setActiveTab('all');
      },
    },
    {
      title:
        activeTab === 'active' &&
        !paginatedTableProps.isFetchingData &&
        !paginatedTableProps.isFetchingError
          ? `Active (${paginatedTableProps.itemCount})`
          : 'Active',
      onClick: () => {
        setTabFilter({
          status: ticketStatusOptions
            .map(({ value }) => value)
            .filter((value) => value !== 'Cancelled' && value !== 'Complete')
            .join(','),
        });
        setActiveTab('active');
      },
    },
    {
      title:
        activeTab === 'complete' &&
        !paginatedTableProps.isFetchingData &&
        !paginatedTableProps.isFetchingError
          ? `Complete (${paginatedTableProps.itemCount})`
          : 'Complete',
      onClick: () => {
        setTabFilter({
          status: 'Cancelled,Complete',
        });
        setActiveTab('complete');
      },
    },
    {
      title:
        activeTab === 'drafts' &&
        !paginatedTableProps.isFetchingData &&
        !paginatedTableProps.isFetchingError
          ? `My drafts (${paginatedTableProps.itemCount})`
          : 'My drafts',
      onClick: () => setActiveTab('drafts'),
    },
  ];

  useEffect(() => {
    const savedSize = getCookie('paginationSizeTechExpress');
    if (savedSize) {
      setPageSize(parseInt(savedSize, 10));
    }
  }, []);

  const paginationChanged = (page: number) => {
    setPageSize(page);
    setCookie('paginationSizeTechExpress', page.toString(), 365);
  };

  const {
    columns,
    isPreferenceLoading,
    customizeProps,
    exportWithPreferences,
  } = useCustomizeTHead({
    columns: TechExpressTableHeaders,
    tableName: 'techexpress-tickets',
  });

  const tableHeaders = useMemo(
    () => (activeTab !== 'drafts' ? columns : DraftTableHeaders),
    [columns, activeTab],
  );

  const createExcelFile = useDownloadAsExcel(async () => {
    const tickets = await getTicketsQueryFn();
    const transformedData = tickets.data.map((ticket) => ({
      parent_macnum: ticket.parent_macnum,
      macnum: ticket.macnum,
      full_site_name: ticket.full_site_name,
      id: `${ticket.id}`,
      address: `${ticket.address_1}, ${ticket.city}, ${ticket.state_identifier}, ${ticket.zip}`,
      location: `${ticket.location_name}, ${ticket.location_number}`,
      ticket_type: `${toFrontendTicketType(
        ticket.type_name,
      )}, ${toFrontendBoardName(ticket.board_name)}`,
      priority: getPrority(ticket.sub_type),
      status_name: ticket.status_name,
      scheduled:
        ticket.schedule?.scheduling_type === 'Hard Start'
          ? `${formatDate(ticket.schedule?.start_date)} - ${format(
              new Date(ticket.schedule?.start_date),
              'hh:mm a',
            )}`
          : ticket.schedule?.scheduling_type === 'Requested Window'
            ? `${formatDate(ticket.schedule?.start_date)} - ${format(
                new Date(ticket.schedule?.start_date),
                'hh:mm a',
              )}-${format(new Date(ticket.schedule?.end_date), 'hh:mm a')}`
            : '-- -- --',
      date_entered: formatDate(ticket.date_entered),
      last_updated: formatDate(ticket.last_updated),
      submitted_by: `${ticket.submitted_by}`,
    }));
    return exportWithPreferences(transformedData);
  }, 'Service tickets');

  return (
    <DraftActionsContext.Provider
      value={{ ...modalProps, selectedDraft, setSelectedDraft }}
    >
      <ContentLayout className="w-full 2xl:max-w-[1440px]">
        <div className="mb-6">
          <PageTitleGranite title="TechExpress" className="items-center">
            <div className="flex gap-x-4">
              <DisplayForRole roles={[TokenUserRoles.COMPANY_ADMIN]}>
                <Link
                  to="/tech-express/templates"
                  className="button large secondary"
                >
                  Templates
                </Link>
              </DisplayForRole>
              <DisplayForRole
                roles={[TokenUserRoles.VIEWER, TokenUserRoles.USER]}
              >
                <Link
                  to="/tech-express/let-us-help"
                  className="button large primary tech-express-button"
                >
                  Open ticket
                </Link>
              </DisplayForRole>
            </div>
          </PageTitleGranite>
        </div>
        <div className="mb-12 flex flex-col gap-8 xl:flex-row">
          <TechExpressMetrics />
          <TechExpressRecentTickets />
        </div>

        <div className="flex w-full flex-col items-start justify-start">
          <TableTitle>Service tickets</TableTitle>
          <div className="relative mb-5 w-full">
            <Tabs tabs={tabs} />
          </div>
          <div className="mb-4 flex w-full flex-wrap items-start justify-between gap-4">
            <div className="w-full sm:flex-1">
              <Searchbar
                placeholder="Search by ticket #, address, or location name"
                clearAllValues={search === ''}
                onQueryClear={() => setSearch('')}
                onSearch={(query: string) => {
                  setSearch(query);
                }}
              />
            </div>
            {activeTab !== 'drafts' && <Customize {...customizeProps} />}
            <Filters
              clearFilters={clearAllFilters}
              clearFilterClassName="col-span-full md:col-span-1"
            >
              <GraniteSelect
                options={ticketStatusOptions}
                isMulti
                className={clsx(
                  'col-span-2 md:col-span-2 xl:col-span-1',
                  activeTab === 'drafts' && '!hidden',
                )}
                placeholder="Filter ticket status"
                controlShouldRenderValue={false}
                isSearchable={false}
                {...statusFilterProps}
              />
              <GraniteSelect
                options={ticketPriorityOptions}
                isMulti
                className={clsx(
                  'col-span-2 md:col-span-2 xl:col-span-1',
                  activeTab === 'drafts' && '!hidden',
                )}
                placeholder="Filter ticket priority"
                controlShouldRenderValue={false}
                isSearchable={false}
                {...priorityFilterProps}
              />
              <GraniteSelect
                options={ticketTypeOptions}
                isMulti
                className="col-span-2 md:col-span-2 xl:col-span-1"
                placeholder="Filter ticket type"
                controlShouldRenderValue={false}
                isSearchable={false}
                {...ticketTypeFilterProps}
              />
              <GraniteSelect
                options={filterByDate}
                className="col-span-2 md:col-span-2 xl:col-span-1"
                placeholder="Filter ticket by date"
                isClearable={false}
                isSearchable={false}
                controlShouldRenderValue={false}
                onChange={setDateFilter}
                value={dateFilter}
              />
            </Filters>
          </div>
          <div className="mb-12 flex flex-wrap gap-4">
            {statusFilterProps.value.map((sf) => (
              <Chip
                key={sf.value}
                label={sf.label}
                onDelete={() =>
                  statusFilterProps.onChange(
                    statusFilterProps.value.filter(
                      (option) => option.value !== sf.value,
                    ),
                  )
                }
              />
            ))}
            {priorityFilterProps.value.map((sf) => (
              <Chip
                key={sf.value}
                label={sf.label}
                onDelete={() =>
                  priorityFilterProps.onChange(
                    priorityFilterProps.value.filter(
                      (option) => option.value !== sf.value,
                    ),
                  )
                }
              />
            ))}
            {ticketTypeFilterProps.value.map((sf) => (
              <Chip
                key={sf.value}
                label={sf.label}
                onDelete={() =>
                  ticketTypeFilterProps.onChange(
                    ticketTypeFilterProps.value.filter(
                      (option) => option.value !== sf.value,
                    ),
                  )
                }
              />
            ))}
            {dateFilter?.value && (
              <Chip
                label={dateFilter.label}
                onDelete={() => {
                  setDateFilter(null);
                }}
              />
            )}
          </div>
          <ServerPaginatedTable
            data={tablePageData}
            columns={tableHeaders as ColumnDef<Ticket | Draft>[]}
            title="Service tickets"
            handleRowClick={handleRowClick}
            sortingState={sortingState}
            onSortingChange={onSortingChange}
            downloadTableFn={createExcelFile}
            paginationChanged={paginationChanged}
            paginationSizeStored={pageSize}
            emptyDataElement={
              activeTab === 'drafts' ? <EmptyDrafts /> : undefined
            }
            {...paginatedTableProps}
            isFetchingData={
              paginatedTableProps.isFetchingData || isPreferenceLoading
            }
          />
        </div>
      </ContentLayout>
      <DeleteDraftConfirmationDialog
        {...modalProps}
        onConfirmation={onDeleteDraftConfirmed}
      />
    </DraftActionsContext.Provider>
  );
};

export default TechExpress;
