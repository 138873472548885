import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ReactNode, useState } from 'react';
import { FilterCircle } from 'react-ionicons';

interface FilterProps {
  children: ReactNode;
  clearFilters: () => void;
  clearFilterClassName?: string;
}

const Filters = ({
  children,
  clearFilters,
  clearFilterClassName,
}: FilterProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  return (
    <>
      <GraniteButton
        variant="secondary"
        size="large"
        className="group !box-border w-full whitespace-nowrap md:max-w-[175px]"
        onClick={() => setShowFilters((prevState) => !prevState)}
      >
        {showFilters ? 'Hide filters' : 'Show filters'}
        <FilterCircle color={'inherit'} width={'22px'} height={'22px'} />
      </GraniteButton>

      {showFilters && (
        <>
          <div className="flex w-full items-start justify-start gap-4">
            {children}
            <GraniteButton
              variant="secondary"
              size="large"
              onClick={clearFilters}
              className={clsx(
                '!box-border whitespace-nowrap md:max-w-[175px]',
                clearFilterClassName,
              )}
            >
              Clear all filters
            </GraniteButton>
          </div>
        </>
      )}
    </>
  );
};

export default Filters;
