import Tabs from 'components/Table/Tabs';
import React, { useState } from 'react';
import { ContactGroupListItemType } from './schema';
import clsx from 'clsx';
import { useAuthUser } from 'hooks/useAuthUser';
import { getContactGroupById, TabsTypeEnum } from '../../utils';
import { useQuery } from 'react-query';
import DeviceEditForm from './DeviceEditForm';
import ContactsEditForm from './ContactsEditForm';

interface ContactGroupEditProps {
  close: () => void;
  defaultValue: ContactGroupListItemType;
  refetchOnSuccess: () => void;
}

const ContactGroupEditForm: React.FC<ContactGroupEditProps> = ({
  close,
  defaultValue,
  refetchOnSuccess,
}) => {
  const { isViewerRole } = useAuthUser();

  const [activeTab, setActiveTab] = useState(TabsTypeEnum.group);

  const { data, refetch } = useQuery(
    ['noc-express-alerting-contact-group-edit', defaultValue.id],
    () => getContactGroupById(defaultValue.id),
    { refetchOnMount: true },
  );

  // if (isLoading) return <h1>Loading</h1>;
  // if (isError) return <h1>error</h1>;
  // if (data)
  return (
    <>
      <div className="flex items-center justify-between rounded-t-lg bg-background-base-surface-3 px-8 py-6 font-bold leading-9">
        <h1 className="text-2.5xl text-content-base-default">
          {isViewerRole ? 'View' : 'Edit'} contact group
        </h1>
        <p className="font-bold text-white">
          <span
            className={clsx(
              'mr-2 inline-flex h-2.5 w-2.5 rounded-full',
              defaultValue?.status === 'active' && 'bg-green-400',
              defaultValue?.status === 'inactive' && 'bg-neutral-500',
            )}
          />
          {defaultValue?.status[0].toUpperCase()}
          {defaultValue?.status.slice(1)}
        </p>
      </div>
      <div className="p-8">
        <Tabs
          className="mb-8"
          variant="medium"
          defaultActiveTab={TabsTypeEnum.group}
          tabs={[
            {
              title: 'Group details',
              onClick: () => setActiveTab(TabsTypeEnum.group),
            },
            {
              title: 'Devices',
              onClick: () => setActiveTab(TabsTypeEnum.devices),
            },
          ]}
        />
        {activeTab === TabsTypeEnum.group ? (
          <ContactsEditForm
            defaultValues={data}
            close={close}
            refetchOnSuccess={refetchOnSuccess}
            refetchDetailsOnSuccess={refetch}
          />
        ) : null}

        {activeTab === TabsTypeEnum.devices ? (
          <DeviceEditForm
            defaultValues={data}
            close={close}
            refetchOnSuccess={refetchOnSuccess}
          />
        ) : null}
      </div>
    </>
  );
};

export default ContactGroupEditForm;
