import styled, { css } from 'styled-components';

const sharedStyles = css`
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  height: 100%;
  width: 100%;
  padding: 4px;
`;

const primaryStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.text.primary};
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.iconButton.primary.backgroundColor};
  color: ${({ theme }) => theme.colors.iconButton.primary.color};
  svg {
    color: ${({ theme }) => theme.colors.iconButton.primary.color} !important;
    fill: ${({ theme }) => theme.colors.iconButton.primary.color} !important;
  }
  &:hover:not(:disabled) {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.iconButton.primary.hover};
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.button.disabled};
    color: ${({ theme }) => theme.colors.text.disabled};
    cursor: default;
  }
  &:active:not(:disabled) {
    background: ${({ theme }) =>
      theme.colors.iconButton.primary.active} !important;
  }
`;

const secondaryStyles = css`
  border: 1px solid
    ${({ theme }) => theme.colors.iconButton.secondary.borderColor};
  border-radius: 5px;
  background-color: transparent;

  &:hover:not(:disabled) {
    background: ${({ theme }) => theme.colors.iconButton.secondary.hover};
    border-color: transparent;
    color: transparent;

    svg {
      color: ${({ theme }) =>
        theme.colors.iconButton.secondary.hoverColor} !important;
      fill: ${({ theme }) =>
        theme.colors.iconButton.secondary.hoverColor} !important;
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.iconButton.secondary};
  }

  &:active:not(:disabled) {
    background: ${({ theme }) =>
      theme.colors.iconButton.secondary.active} !important;
  }

  &:disabled {
    background: transparent;
    color: ${({ theme }) => theme.colors.iconButton.secondary.disabled};
    cursor: default;
    border-color: ${({ theme }) => theme.colors.iconButton.secondary.disabled};
    svg {
      color: ${({ theme }) =>
        theme.colors.iconButton.secondary.disabled} !important;
      fill: ${({ theme }) =>
        theme.colors.iconButton.secondary.disabled} !important;
    }
  }
`;

const destructiveStyles = css`
  border-radius: 5px;
  background-color: ${({ theme }) =>
    theme.colors.iconButton.destructive.backgroundColor};

  &:hover {
    background: ${({ theme }) => theme.colors.iconButton.destructive.hover};
    border-color: ${({ theme }) => theme.colors.iconButton.destructive.active};
  }

  svg {
    color: ${({ theme }) => theme.colors.iconButton.secondary};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.iconButton.destructive.disabled};
    color: ${({ theme }) => theme.colors.text.disabled};
    cursor: default;
  }
  &:active:not(:disabled) {
    background: ${({ theme }) =>
      theme.colors.iconButton.destructive.active} !important;
  }
`;

const ghostStyles = css`
  border: none;
  border-radius: 5px;
  background: transparent;

  &:hover {
    cursor: pointer;

    svg {
      color: ${({ theme }) => theme.colors.iconButton.ghost.hover} !important;
      fill: ${({ theme }) => theme.colors.iconButton.ghost.hover} !important;
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.iconButton.ghost.color};
  }
  &:disabled {
    cursor: default;
  }
  &:active:not(:disabled) {
    svg {
      color: ${({ theme }) => theme.colors.iconButton.ghost.active} !important;
      fill: ${({ theme }) => theme.colors.iconButton.ghost.active} !important;
    }
  }
`;

export const IconButtonWrapper = styled.div<{ size?: string }>`
  ${({ size }) =>
    size === 'large' &&
    css`
      width: 48px;
      height: 48px;
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      width: 32px;
      height: 32px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 24px;
      height: 24px;
    `}
`;

export const Button = styled.button`
  ${sharedStyles}

  &.storybook-button--primary {
    ${primaryStyles}
  }

  &.storybook-button--secondary {
    ${secondaryStyles}
  }

  &.storybook-button--destructive {
    ${destructiveStyles}
  }

  &.storybook-button--ghost {
    ${ghostStyles}
  }
`;
