import { Resource } from 'api/resources/schema';

export const getFileType = (type: Resource['file_type']) => {
  switch (type) {
    case '.docx':
      return 'word';
    case '.pdf':
      return 'pdf';
    case '.xlsx':
    case '.csv':
      return 'excel';
    case '.jpg':
    case '.jpeg':
    case '.png':
      return 'image';
    case '.mov':
    case '.mp4':
    case '.mpeg':
      return 'video';
    default:
      return 'unknown';
  }
};
