import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from 'shared/constants/error-labels';
import {
  LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import { nullToUndefined } from 'shared/util/schema-utils';
import { z } from 'zod';
import { mapBackendRoleToFrontendRole } from 'api/schema-utils';

export const UserProfileRequestSchema = z.object({
  first_name: z
    .string()
    .nonempty('Required')
    .max(100, 'Only 100 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  last_name: z
    .string()
    .nonempty('Required')
    .max(100, 'Only 100 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  phone_number: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  secondary_phone_number: z
    .string()
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL)
    .nullish()
    .optional()
    .or(z.literal('')),
  secondary_email: z.string().email().nullish().optional().or(z.literal('')),
  company_title: z.string().nullish().optional().or(z.literal('')),
  department: z.string().nullish().optional().or(z.literal('')),
});

export type UserProfileRequest = z.infer<typeof UserProfileRequestSchema>;

export const UserProfileFormSchema = UserProfileRequestSchema.merge(
  z.object({
    roles: z.array(
      z.object({
        value: z.string(),
        label: z.string(),
      }),
    ),
  }),
);

export type UserProfileForm = z.infer<typeof UserProfileFormSchema>;

export const UserActionsSchema = z.object({
  id: z.string(),
  user_name: z.string(),
  role_upgrade_requested: z.boolean(),
});

export type UserActionsType = z.infer<typeof UserActionsSchema>;

export const UserProfileResponseSchema = z.object({
  id: z.string().nullish().transform(nullToUndefined),
  company_title: z.string().nullish().transform(nullToUndefined),
  deactivated: z.boolean().nullish().transform(nullToUndefined),
  department: z.string().nullish().transform(nullToUndefined),
  name: z
    .string()
    .nullable()
    .default('')
    .transform((value) => value ?? ''),
  first_name: z
    .string()
    .nullable()
    .default('')
    .transform((value) => value ?? ''),
  last_name: z
    .string()
    .nullable()
    .default('')
    .transform((value) => value ?? ''),
  phone_number: z
    .string()
    .nullable()
    .default('')
    .transform((value) => value ?? ''),
  roles: z.array(z.string()).transform((roles) =>
    roles.map((role) => ({
      value: role,
      label: mapBackendRoleToFrontendRole(role),
    })),
  ),
  secondary_email: z.string().email().nullish().transform(nullToUndefined),
  secondary_phone_number: z.string().nullish().transform(nullToUndefined),
  created_at: z.string().nullish().transform(nullToUndefined),
  invited_at: z.string().nullish().transform(nullToUndefined),
  actions: UserActionsSchema.optional(),
});

export type UserProfileResponse = z.infer<typeof UserProfileResponseSchema>;

export const UpdateUserProfileRequestSchema = UserProfileRequestSchema.merge(
  z.object({
    roles: z.array(z.string()),
  }),
);

export type UpdateUserProfileRequest = z.infer<
  typeof UpdateUserProfileRequestSchema
>;
