import { InventoryItem } from 'api/inventory/schema';
import { IRecentActivity } from './RecentActivity';
import { ResponsePreferencesModel } from 'components/Table/Customize/customize.types';
import { dateToUTCWithoutMS } from 'shared/util/dateToUtcStrWithoutMS';
import { addMonths, startOfDay, subMonths } from 'date-fns';

export const recentActivities: IRecentActivity[] = [
  {
    id: 1,
    address1: 'Akron SD WAN',
    type: 'Broadband',
    description: 'Account # 03352192',
    tag: 'Added',
    service_id: '8361100012101725',
  },
  {
    id: 2,
    address1: 'Akron SD WAN',
    type: 'Managed - Premise Firewall Branch',
    description: 'Account # 03352192',
    tag: 'End dated',
    service_id: 'FGT60FTK22054271',
  },
  {
    id: 3,
    address1: '789 Maple Dr, Gotham',
    type: 'Broadband',
    description: 'Account # 04856265',
    tag: 'Added',
    service_id: '8362101380007840',
  },
  {
    id: 4,
    address1: 'Bowling Green SD WAN',
    type: 'Managed - Premise Firewall Branch',
    description: 'Account # 04856212',
    tag: 'End dated',
    service_id: 'FGT60FTK2109CGNR',
  },
  {
    id: 5,
    address1: 'Cleveland',
    type: 'Managed - Premise Firewall Branch',
    description: 'Account # 04856212',
    tag: 'Added',
    service_id: 'FGT60FTK22048741',
  },
];

export const inventoryItems: InventoryItem[] = [
  {
    id: 1,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '03352192',
    account_name: 'Akron SD WAN',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Broadband',
    vendor: 'Time Warner',
    service_id: '8310002019725',
    status: 'Active',
    completion_date: 'Today',
    end_date: '---',
    btn: null,
    serial_number: 'TW-A202XU0345',
    mac_address: '90:44:20:32:45:76',
    ip_address: '192.168.1.1',
    model_number: 'BXM-5001',
    tag_number: null,
    product_offering: 'Broadband Services',
    host_status: 'OK',
  },
  {
    id: 2,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '04856212',
    account_name: 'Cleveland',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Managed - Premise Firewall Branch',
    vendor: 'SBC',
    service_id: 'FGT60FTK22054271',
    status: 'Active',
    completion_date: '5/14/24',
    end_date: '---',
    btn: null,
    serial_number: 'SNC-A301X0045',
    mac_address: 'A0:B1:C2:D3:E4:F5',
    ip_address: '10.0.0.5',
    model_number: 'SW-16024',
    tag_number: null,
    product_offering: 'Firewall/Managed - Premise Firewall',
    host_status: null,
  },
  {
    id: 3,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '04856265',
    account_name: '789 Maple Dr, Gotham',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Broadband',
    vendor: 'SBC',
    service_id: '8362101380007840',
    status: 'Active',
    completion_date: '5/3/24',
    end_date: '---',
    btn: null,
    serial_number: 'ATT-D033AUM0089',
    mac_address: '34:71:8A:5C:4D:78',
    ip_address: '192.168.1.10',
    model_number: 'BXM-5001',
    tag_number: null,
    product_offering: 'Broadband Services',
    host_status: null,
  },
  {
    id: 4,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '04856212',
    account_name: 'Bowling Green SD WAN',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Managed - Premise Firewall Branch',
    vendor: 'Frontier West',
    service_id: 'FGT60FTK2109CGNR',
    status: 'Inactive',
    completion_date: '8/29/24',
    end_date: '---',
    btn: '(937) 774-1220',
    serial_number: 'FW-B2032X1405',
    mac_address: '12:34:56:78:9A:BC',
    ip_address: '172.16.0.5',
    model_number: 'SW-16024',
    tag_number: 'TBX-10002968',
    product_offering: 'Firewall/Managed - Premise Firewall',
    host_status: null,
  },
  {
    id: 5,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '04856212',
    account_name: 'Fab Shop BB',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Broadband',
    vendor: 'Time Warner',
    service_id: '8310002019725',
    status: 'Active',
    completion_date: 'Today',
    end_date: '---',
    btn: '(937) 774-1220',
    serial_number: 'TW-A202XU0345',
    mac_address: '90:44:20:32:45:76',
    ip_address: '192.168.1.1',
    model_number: 'BXM-5001',
    tag_number: null,
    product_offering: 'Broadband Services',
    host_status: null,
  },
  {
    id: 6,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '03352192',
    account_name: 'Akron SD WAN',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Broadband',
    vendor: 'Time Warner',
    service_id: '8310002019725',
    status: 'Active',
    completion_date: 'Today',
    end_date: '---',
    btn: null,
    serial_number: 'TW-A202XU0345',
    mac_address: '90:44:20:32:45:76',
    ip_address: '192.168.1.1',
    model_number: 'BXM-5001',
    tag_number: null,
    product_offering: 'Broadband Services',
    host_status: 'OK',
  },
  {
    id: 7,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '04856212',
    account_name: 'Cleveland',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Managed - Premise Firewall Branch',
    vendor: 'SBC',
    service_id: 'FGT60FTK22054271',
    status: 'Active',
    completion_date: '5/14/24',
    end_date: '---',
    btn: null,
    serial_number: 'SNC-A301X0045',
    mac_address: 'A0:B1:C2:D3:E4:F5',
    ip_address: '10.0.0.5',
    model_number: 'SW-16024',
    tag_number: null,
    product_offering: 'Firewall/Managed - Premise Firewall',
    host_status: null,
  },
  {
    id: 8,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '04856265',
    account_name: '789 Maple Dr, Gotham',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Broadband',
    vendor: 'SBC',
    service_id: '8362101380007840',
    status: 'Active',
    completion_date: '5/3/24',
    end_date: '---',
    btn: null,
    serial_number: 'ATT-D033AUM0089',
    mac_address: '34:71:8A:5C:4D:78',
    ip_address: '192.168.1.10',
    model_number: 'BXM-5001',
    tag_number: null,
    product_offering: 'Broadband Services',
    host_status: null,
  },
  {
    id: 4,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '04856212',
    account_name: 'Bowling Green SD WAN',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Managed - Premise Firewall Branch',
    vendor: 'Frontier West',
    service_id: 'FGT60FTK2109CGNR',
    status: 'Inactive',
    completion_date: '8/29/24',
    end_date: '---',
    btn: '(937) 774-1220',
    serial_number: 'FW-B2032X1405',
    mac_address: '12:34:56:78:9A:BC',
    ip_address: '172.16.0.5',
    model_number: 'SW-16024',
    tag_number: 'TBX-10002968',
    product_offering: 'Firewall/Managed - Premise Firewall',
    host_status: null,
  },
  {
    id: 9,
    parent_name: 'Valvoline',
    parent_number: '00236378',
    account_number: '04856212',
    account_name: 'Fab Shop BB',
    address_1: '100 Valvoline Way',
    city: 'Lexington',
    state: 'KY',
    zip: '40555',
    service_type: 'Broadband',
    vendor: 'Time Warner',
    service_id: '8310002019725',
    status: 'Active',
    completion_date: 'Today',
    end_date: '---',
    btn: '(937) 774-1220',
    serial_number: 'TW-A202XU0345',
    mac_address: '90:44:20:32:45:76',
    ip_address: '192.168.1.1',
    model_number: 'BXM-5001',
    tag_number: null,
    product_offering: 'Broadband Services',
    host_status: null,
  },
];

export const filterByCompletionDate = [
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 1)),
    label: 'Past month',
  },
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 3)),
    label: 'Past 3 months',
  },
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 12)),
    label: 'Past year',
  },
];

export const filterByEndDate = [
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 1)),
    label: 'Past month',
  },
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 3)),
    label: 'Past 3 months',
  },
  {
    value: dateToUTCWithoutMS(subMonths(startOfDay(new Date()), 12)),
    label: 'Past year',
  },
  {
    value: dateToUTCWithoutMS(addMonths(startOfDay(new Date()), 1)),
    label: 'Next month',
  },
  {
    value: dateToUTCWithoutMS(addMonths(startOfDay(new Date()), 3)),
    label: 'Next 3 months',
  },
  {
    value: dateToUTCWithoutMS(addMonths(startOfDay(new Date()), 12)),
    label: 'Next year',
  },
];

export const serviceTypeOptions = [
  { value: 'First service type', label: 'First service type' },
  { value: 'Second service type', label: 'Second service type' },
];

export const vendorOptions = [
  { value: 'First vendor', label: 'First vendor' },
  { value: 'Second vendor', label: 'Second vendor' },
];

export const statusOptions = [
  { value: 'First status', label: 'First status' },
  { value: 'Second status', label: 'Second status' },
];

export const mockServicePreferences: ResponsePreferencesModel = [
  { field: 'parent_name', enabled: false },
  { field: 'parent_number', enabled: false },
  { field: 'account_number', enabled: true },
  { field: 'account_name', enabled: true },
  { field: 'address', enabled: false },
  { field: 'service_type', enabled: true },
  { field: 'vendor', enabled: true },
  { field: 'service_id', enabled: true },
  { field: 'status', enabled: true },
  { field: 'completion_date', enabled: true },
  { field: 'end_date', enabled: true },
  { field: 'btn', enabled: false },
  { field: 'serial_number', enabled: false },
  { field: 'mac_address', enabled: false },
  { field: 'ip_address', enabled: false },
  { field: 'model_number', enabled: false },
  { field: 'tag_number', enabled: false },
  { field: 'product_offering', enabled: false },
  { field: 'host_status', enabled: false },
  { field: 'actions', enabled: true },
];
