import { deleteUser } from 'api/users/api';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useState } from 'react';
import { useMutation } from 'react-query';

const DeleteAccount = ({
  userId,
  isLoggedInUser = false,
  onSuccessHandler,
  isDisabled = false,
}: {
  userId: string;
  isLoggedInUser?: boolean;
  onSuccessHandler: () => void;
  isDisabled?: boolean;
}) => {
  const [isDeleteAccountVisible, setIsDeleteAccountVisible] =
    useState<boolean>(false);
  const close = () => {
    setIsDeleteAccountVisible(false);
  };

  const deleteUserMutation = useMutation(() => deleteUser({ userId }), {
    onSuccess: () => {
      onSuccessHandler();
      close();
    },
  });

  const onUserDelete = () => {
    deleteUserMutation.mutate();
  };

  const label = `Delete ${isLoggedInUser ? 'my account' : 'user'} `;

  return (
    <>
      <div className="flex w-full flex-col items-start justify-start gap-4">
        <h2 className="text-2xl font-bold text-content-base-default">
          {label}
        </h2>
        <div className="flex flex-col items-start justify-start gap-2">
          <p className="text-base font-bold text-content-base-subdued">
            {isLoggedInUser && isDisabled
              ? 'Since you’re a company administrator, you need to contact a Granite administrator in order to delete your account.'
              : '            This action cannot be undone. Once deleted, all user data will be removed forever.'}
          </p>

          <GraniteButton
            size="large"
            variant="destructive"
            disabled={isLoggedInUser && isDisabled}
            onClick={() => setIsDeleteAccountVisible((prevState) => !prevState)}
          >
            {label}
          </GraniteButton>
        </div>
      </div>
      <Modal
        isVisible={isDeleteAccountVisible}
        close={close}
        className="max-w-[624px]"
      >
        <div className="flex flex-col items-start justify-start gap-8 p-8">
          <div className="flex flex-col items-start justify-start gap-2">
            <h2 className="text-2xl font-bold text-content-base-default">
              {`Are you sure you want to delete ${
                isLoggedInUser ? 'your account' : 'this user'
              }?`}
            </h2>
            <p className="text-base font-bold text-content-base-subdued">
              This action cannot be undone. Once deleted, all user data will be
              removed forever.
            </p>
          </div>
          <div className="flex items-center justify-start gap-5">
            <GraniteButton size="large" variant="secondary" onClick={close}>
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              variant="destructive"
              onClick={onUserDelete}
            >
              {`Yes, delete ${isLoggedInUser ? 'my account' : 'user'}`}
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAccount;
