import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Logo from 'components/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AccessSvg } from '../../assets/images/navmenu/access.svg';
import { ReactComponent as AccessActiveSvg } from '../../assets/images/navmenu/access-active.svg';
import { ReactComponent as Express } from '../../assets/images/navmenu/express.svg';
import { ReactComponent as NocSvg } from '../../assets/images/navmenu/noc.svg';
import { ReactComponent as NocActiveSvg } from '../../assets/images/navmenu/noc-active.svg';
import { ReactComponent as TechSvg } from '../../assets/images/navmenu/tech.svg';
import { ReactComponent as TechActiveSvg } from '../../assets/images/navmenu/tech-active.svg';
import { ReactComponent as MobilitySvg } from '../../assets/images/navmenu/mobility.svg';
import { ReactComponent as InventorySvg } from '../../assets/images/navmenu/inventory.svg';
import { ReactComponent as InventoryActiveSvg } from '../../assets/images/navmenu/inventory-active.svg';
import MenuLink from './MenuLink';
import DropdownMenu from './DropdownMenu';
import UserAvatar from 'components/Avatar';
import getDropdownOptionsByRole from './utils';
import { useAuthUser } from 'hooks/useAuthUser';
import {
  Business,
  Close,
  MenuOutline,
  Notifications as NotificationsIcon,
} from 'react-ionicons';
import Divider from 'components/Divider';
import DropdownMenuOptions from './DropdownMenuOptions';
import CompanyName from './CompanyName';
import { GraniteButton } from '../V2/Button/GraniteButton';
import { G360Pillars, TokenUserRoles } from '../../api/users/schemas/Users';
import { TechActiveDesktopSvg } from 'components/HeaderSvgs/TechActiveDesktopSvg';
import { AccessActiveDesktopSvg } from 'components/HeaderSvgs/AccessActiveDesktopSvg';
import { AccessDesktopSvg } from 'components/HeaderSvgs/AccessDesktopSvg';
import { TechDesktopSvg } from 'components/HeaderSvgs/TechDesktopSvg';
import { NocDesktopSvg } from 'components/HeaderSvgs/NocDesktopSvg';
import { NocActiveDesktopSvg } from 'components/HeaderSvgs/NocActiveDesktopSvg';
import useIsMounted from 'hooks/useIsMounted';
import Notifications from 'components/Notifications/Notifications';
import AnnounceKit from 'announcekit-react';
import { NOTIFICATIONS_POLL_INTERVAL } from 'components/Notifications/utils';
import { useFeatureFlags } from 'feature-flags';
import { useNotifications } from 'hooks/useNotifications';
import { useModal } from 'hooks/useModal';
import FeedbackDialog from 'layouts/DefaultLayout/FeedbackDialog/FeedbackDialog';
import { CatalogModal } from 'components/ProductTour/CatalogModal';
import { CompanyAdminsDialog } from 'screens/Home/CompanyAdminsDialog';
import { useAuth } from 'authContext';

export interface HeaderProps {
  menuOptions: Array<{
    label: string;
    link?: string;
    active?: string;
    dropdownOptions?: Array<{ label: string; onClick: () => void }>;
    onClick?: () => void;
  }>;
}

const Header: React.FC<HeaderProps> = () => {
  const { flags } = useFeatureFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const { name, companyName, roles, hasRoleAssigned, email, group_id } =
    useAuthUser();
  const overlayRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const handleNotificationsToggle = () => {
    setNotificationsOpen(!isNotificationsOpen);
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [indicatorStyle, setIndicatorStyle] = useState<React.CSSProperties>({});
  const itemRefs = useRef<Array<HTMLAnchorElement | null>>([]);

  const { notifications } = useNotifications(
    {
      onlyUnread: true,
      notificationType: 'all',
    },
    {
      // continuously refetch notifications by given interval
      refetchInterval: NOTIFICATIONS_POLL_INTERVAL,
      staleTime: NOTIFICATIONS_POLL_INTERVAL,
      enabled: flags.NOTIFICATIONS_ENABLED,
    },
  );

  useEffect(() => {
    const currentRef = itemRefs.current[activeIndex];
    if (currentRef) {
      const { offsetLeft, clientWidth } = currentRef;
      const fitTransform = activeIndex === 1 ? 15 : 20;
      setIndicatorStyle({
        minWidth: clientWidth + 30 + 'px',
        transform: `translateX(${offsetLeft - fitTransform}px)`,
        transitionDuration: '0.4s',
      });
    }
  }, [activeIndex]);

  useLayoutEffect(() => {
    const pathName = location.pathname.split('/')[1];
    const navigationRoutes = ['access-express', 'tech-express', 'noc-express'];

    const index = navigationRoutes.findIndex((route) => route === pathName) + 1;
    setActiveIndex(index);
  }, [location]);

  const { hasPillarAccess, isQRAdminRole, isProspectRole } = useAuthUser();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const switchCompany = () => {
    navigate('/companies');
  };

  const { open: openFeedbackModal, ...modalProps } = useModal();
  const { open: openCatalogModal, ...catalogModalProps } = useModal();
  const { open: openCompanyAdminsModal, ...companyAdminsProps } = useModal();

  const userDropdownOptions = getDropdownOptionsByRole({
    roles,
    name: name || 'John Doe',
    companyName,
    navigate,
    logout,
    switchCompany,
    openFeedbackModal,
    openCatalogModal,
    openCompanyAdminsModal,
    flags,
  });

  const handleOptionClick = (groupIndex: number, optionIndex: number) => {
    userDropdownOptions[groupIndex].options[optionIndex].onClick();
    handleMobileMenuToggle();
  };

  useEffect(() => {
    const closeOnClickOutside = (ev: MouseEvent) => {
      if (
        isMobileMenuOpen &&
        overlayRef.current &&
        ev.target instanceof Node &&
        !overlayRef?.current?.contains(ev.target) &&
        !toggleButtonRef?.current?.contains(ev.target)
      ) {
        setMobileMenuOpen(false);
      }
    };
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const mobileMenuLinkClass =
    'rounded-md w-full cursor-pointer bg-background-base-surface-2 rounded-md bg-background-base-surface-0 px-4 py-3 xl:px-0 xl:py-0 [&>svg]:fill-white [&>svg]:hover:fill-button-background-secondary-hover ';

  const isSuperAdmin = roles.includes(TokenUserRoles.SUPER_ADMIN);

  return (
    <header
      className={`flex items-center justify-between bg-background-base-surface-2 px-4 py-2 sm:px-8 sm:py-4
       ${isMobileMenuOpen ? 'sticky top-0 z-50' : ''}`}
    >
      <div className="flex items-center justify-between gap-6 bg-background-base-surface-2">
        <div
          className="product-tour-dashboard hover:cursor-pointer"
          onClick={() => navigate('/')}
        >
          <Logo />
        </div>
        {hasRoleAssigned && (
          <div className="hidden md:flex">
            {isSuperAdmin ? (
              <GraniteButton
                variant="ghost"
                className="rounded-[6px] p-3"
                onClick={switchCompany}
              >
                <Business width="20px" height="20px" color={'#F8FAFC'} />
                {companyName}
              </GraniteButton>
            ) : (
              <CompanyName
                name={companyName}
                wrapperClasses="p-3"
                textClasses="text-base"
              />
            )}
          </div>
        )}
      </div>
      <div className="sm:hidden">
        <div
          ref={toggleButtonRef}
          onClick={handleMobileMenuToggle}
          className={`flex cursor-pointer items-center justify-between gap-3 px-4 py-3 ${
            isMobileMenuOpen ? 'rounded-md bg-background-base-surface-0' : ''
          }`}
        >
          {isMobileMenuOpen ? (
            <div className="rotate-90 transform transition duration-300">
              <Close color={'#F8FAFC'} height="20px" width="20px" />
            </div>
          ) : (
            <div className="transform transition">
              <MenuOutline color={'#F8FAFC'} height="20px" width="20px" />
            </div>
          )}
          <span className="font-bold text-content-base-default">Menu</span>
        </div>
      </div>
      {/* Desktop Menu items */}
      <ul className="relative hidden list-none items-center justify-start gap-6 sm:flex">
        {hasPillarAccess(G360Pillars.AccessExpress) && (
          <MenuLink
            to="/access-express"
            ref={(el) => (itemRefs.current[1] = el)}
            classNames={`${activeIndex === 2 ? 'relative -left-4' : ''}`}
            setActive={() => {
              setActiveIndex(1);
            }}
          >
            {({ isActive }) =>
              isActive ? (
                <AccessActiveDesktopSvg />
              ) : (
                <AccessDesktopSvg isMounted={isMounted.current && isActive} />
              )
            }
          </MenuLink>
        )}
        {hasRoleAssigned && !isQRAdminRole && !isProspectRole && (
          <MenuLink
            to="/tech-express"
            ref={(el) => (itemRefs.current[2] = el)}
            activeClassName="ml-0"
            classNames={`${
              activeIndex === 3 ? 'relative -left-6' : ''
            }product-tour-tech-express`}
            setActive={() => {
              setActiveIndex(2);
            }}
          >
            {({ isActive }) =>
              isActive ? (
                <TechActiveDesktopSvg />
              ) : (
                <TechDesktopSvg isMounted={isMounted.current && isActive} />
              )
            }
          </MenuLink>
        )}
        {hasRoleAssigned && !isQRAdminRole && !isProspectRole && (
          <MenuLink
            to="/noc-express"
            ref={(el) => (itemRefs.current[3] = el)}
            classNames={'product-tour-noc-express'}
            setActive={() => {
              setActiveIndex(3);
            }}
          >
            {({ isActive }) =>
              isActive ? (
                <NocActiveDesktopSvg />
              ) : (
                <NocDesktopSvg isMounted={isMounted.current && isActive} />
              )
            }
          </MenuLink>
        )}
        {flags.INVENTORY_ENABLED && !isQRAdminRole && !isProspectRole && (
          <MenuLink
            to="/inventory"
            ref={(el) => (itemRefs.current[4] = el)}
            setActive={() => {
              setActiveIndex(4);
            }}
          >
            {({ isActive }) =>
              isActive ? <InventoryActiveSvg /> : <InventorySvg />
            }
          </MenuLink>
        )}
        {!isQRAdminRole && !isProspectRole && (
          <MenuLink
            to="/mobility"
            ref={(el) => (itemRefs.current[5] = el)}
            classNames="product-tour-mobility"
            setActive={() => {
              setActiveIndex(5);
            }}
          >
            <MobilitySvg className="[&>path]:hover:fill-[#82F0FF]" />
          </MenuLink>
        )}
        <AnnounceKit
          widget="https://announcekit.co/widgets/v2/1HUiRi"
          user={{ id: group_id as string, name: name, email: email }}
          data={{
            member_type: roles[0],
          }}
        />
        {flags.NOTIFICATIONS_ENABLED && (
          <div className="relative cursor-pointer fill-content-base-default">
            <NotificationsIcon
              color={'inherit'}
              width={'24px'}
              height={'24px'}
              onClick={handleNotificationsToggle}
            />
            {notifications && !!notifications?.length && (
              <span className="absolute -right-1 -top-1 flex h-[9px] w-[9px]">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-button-background-destructive-default opacity-75"></span>
                <span className="relative inline-flex h-[9px] w-[9px] rounded-full bg-button-background-destructive-default"></span>
              </span>
            )}
          </div>
        )}
        <DropdownMenu
          label={<UserAvatar name={name || 'John Doe'} />}
          groups={userDropdownOptions}
        />
        {activeIndex !== 0 && (
          <div
            className={`duration-400 absolute top-[6px] flex h-[48px] items-center justify-end rounded-md bg-background-base-surface-0 px-4 ${
              activeIndex === 3 || activeIndex === 2 ? '!pt-[19px]' : ''
            } ${
              activeIndex === 1 ? '!pt-[18px]' : ''
            } pb-4 transition-all  md:flex [&>svg]:fill-white [&>svg]:hover:fill-button-background-secondary-hover`}
            style={indicatorStyle}
          >
            <Express />
          </div>
        )}
      </ul>
      {/* Mobile Menu items*/}
      <div
        className={`fixed inset-0 mt-[65px] w-full ${
          isMobileMenuOpen
            ? 'h-full bg-background-base-overlay opacity-100'
            : 'h-0 opacity-0'
        } z-auto md:hidden`}
      />
      <div
        className={`fixed inset-0 mt-[65px] transition-transform duration-200 ease-in-out ${
          isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } `}
      >
        <div
          ref={overlayRef}
          className={`z-100 ml-auto flex h-full w-[80%] max-w-[390px] flex-col items-start bg-background-base-surface-0 p-4`}
        >
          {hasPillarAccess(G360Pillars.AccessExpress) && (
            <MenuLink
              to="/access-express"
              onClick={handleMobileMenuToggle}
              activeClassName={mobileMenuLinkClass}
            >
              {({ isActive }) =>
                isActive ? <AccessActiveSvg /> : <AccessSvg />
              }
            </MenuLink>
          )}
          {hasRoleAssigned && !isQRAdminRole && !isProspectRole && (
            <MenuLink
              to="/tech-express"
              onClick={handleMobileMenuToggle}
              activeClassName={mobileMenuLinkClass}
            >
              {({ isActive }) => (isActive ? <TechActiveSvg /> : <TechSvg />)}
            </MenuLink>
          )}
          {hasRoleAssigned && !isQRAdminRole && !isProspectRole && (
            <MenuLink
              to="/noc-express"
              onClick={handleMobileMenuToggle}
              activeClassName={mobileMenuLinkClass}
            >
              {({ isActive }) => (isActive ? <NocActiveSvg /> : <NocSvg />)}
            </MenuLink>
          )}
          {flags.INVENTORY_ENABLED && !isQRAdminRole && !isProspectRole && (
            <MenuLink
              to="/inventory"
              onClick={handleMobileMenuToggle}
              activeClassName={mobileMenuLinkClass}
            >
              <InventorySvg />
            </MenuLink>
          )}
          <div className="my-2 w-full">
            <Divider variant="subdued" />
          </div>
          <DropdownMenuOptions
            options={userDropdownOptions}
            handleOptionClick={handleOptionClick}
            className="flex w-full list-none"
          />
        </div>
      </div>
      <Notifications
        isNotificationsOpen={isNotificationsOpen}
        setNotificationsOpen={setNotificationsOpen}
      />
      <FeedbackDialog {...modalProps} />
      <CatalogModal {...catalogModalProps} />
      <CompanyAdminsDialog {...companyAdminsProps} />
    </header>
  );
};

export default Header;
